import { useLottie } from "lottie-react";
import animationData from './lost-connection.json';

const LostConnection = () => {
    const options = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        style: {
            height: "40%",
            width: "50%"
        },
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    const { View } = useLottie(options);

    return <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }}
    >
        {View}
        <br />
        <h3>El sistema ha lanzado una excepción que no pudo ser controlada.</h3>
    </div>
}

export default LostConnection