import { nameCustom, percentValue } from "../../components/Chart/Format/format";
import getContractsStates from "../../services/Contracts/getContractsStates";

const useContractsStates = () => {
  const formatDataTable = (data) => {
    const formattedData = {
      header: [
        {
          name: "Estado",
          options: {
            customBodyRender: nameCustom,
          },
        },
        "Cantidad",

        {
          name: "%",
          options: {
            customBodyRender: percentValue,
          },
        },
      ],
      body: data.map((item) => [
        item.state,
        Number(item.value),
        Number(item.percent),
      ]),
    };

    return formattedData ?? null;
  };

  const convertToChartData = (data) => {
    const chartData = data.map((item) => {
      return {
        name: item.state,
        y: Number(item.value),
        percent: Number(item.percent),
      };
    });

    return chartData ?? [];
  };

  const getStates = async (filter = null) => {
    const response = await getContractsStates(filter);
    const result = {
      chart: [],
      table: {},
    };

    if (response?.status) {
      const states = response.data?.states;
      const total = response.data?.total;

      if (states) {
        result.chart = convertToChartData(states);
        result.table = formatDataTable(states);
        result.table.footer = ["", total, "100%"];
      }
    }

    return result;
  };

  return {
    getStates,
  };
};

export default useContractsStates;
