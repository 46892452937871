import Cookies from "js-cookie";

const getPopupData = async (marker) => {
  const route = "process";
  const endpoint =
    localStorage.getItem("api_" + route) || localStorage.getItem("api");
  const url = endpoint + "markerPopUp";
  const instance = localStorage.getItem("instance");

  try {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tkreact_" + instance),
      },
      body: JSON.stringify({
        id: 37,
        marker: marker,
      }),
    };

    const response = await fetch(url, config);
    const json = await response.json();
    return json ?? undefined;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default getPopupData;
