import { useFormikContext } from 'formik'
import JoditEditor from 'jodit-react'
import { useMemo, useRef } from 'react'

const buttons = [
    'source',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'eraser',
    'copyformat',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'align',
    'undo',
    'redo',
    '|',
    'selectall',
    'cut',
    'copy',
    'paste',
    '|',
    'table',
    'link',
    'symbols',
    '|',
    'left',
    'center',
    'right',
    'justify',
    '|',
    'hr',
    'fullsize'
]

function RFT(props) {
    const editor = useRef(null);
    const { label, name, ...rest } = props
    const { values, setFieldValue } = useFormikContext()

    const handleContentChange = (value) => {
        setFieldValue(name, value)
    }

    const config = {
        readonly: false,
        toolbar: true,
        toolbarAdaptive: false,
        toolbarButtonSize: "big",
        showCharsCounter: true,
        showWordsCounter: true,
        showXPathInStatusbar: false,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        height: '28em',
        removeButtons: ['about', 'find'],
        buttons: buttons,
        language: "es",
        debugLanguage: false,
        i18n: 'es'
    }

    return useMemo(() => (
        <div>
            <label htmlFor={name}>{label}</label>
            <JoditEditor
                {...rest}
                ref={editor}
                config={config}
                tabIndex={1}
                showPoweredBy={false}
                value={values[name]}
                onChange={handleContentChange}
            />
            <br />
        </div>
    ), [])

}

export default RFT
