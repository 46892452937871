import { useEffect, useState } from "react"
import { useAppContext } from "../layout"
import useMetrics from "../../../hooks/Tramites/metrics/metrics"
import { getFilterValues } from "../utils/state"

const useMetricsLogic = () => {

    const store = useAppContext()
    const [metricsOpt, setMetricsOpt] = useState(null)
    const [attrOpt, setAttrOpt] = useState(null)
    const [loading, setLoading] = useState(false)

    const [selectedMap, setSelectedMap] = useState(null)
    const { getHeatMapData } = useMetrics()

    const [metricas, setMetricas] = useState(null)
    const [metrics, setMetrics] = useState({
        metric: '',
        attribute: ''
    })

    const [graphs, setGraphs] = useState({
        sum_col: "",
        percent_col: ""
    })

    const filterMetrics = async (data) => {

        setLoading(true)
        if (store.selected) {
            const result = getFilterValues(store)

            if (result) {
                if (store.open) {
                    store.handleClose()
                }

                let filtro = { filter: result }
                if (data) {
                    filtro = { ...filtro, ...data }
                } else {
                    filtro = {
                        metricId: metrics.metric,
                        attributeId: metrics.attribute,
                        filter: result
                    }
                }

                const response = await getHeatMapData(filtro)

                if (response) {
                    const highestValue = response?.years?.reduce((maxValue, item) => {
                        const val = parseInt(item.value)
                        return Math.max(maxValue, val)
                    }, 0)

                    setMetrics(prevState => ({ ...prevState, year: highestValue ?? 0 }))

                    const foundYear = response.maps?.find(choiceYear => choiceYear.name === highestValue.toString())
                    setSelectedMap(foundYear ?? null)
                }

                setMetricas(response)
            }
        }

        setLoading(false)
    }

    const handleChangeYear = (event) => {
        const { value } = event.target

        setMetricas(prevState => ({
            ...prevState,
            selectyear: value ?? ''
        }))

        const foundYear = metricas.maps.find(choiceYear => choiceYear.name === value)
        setSelectedMap(foundYear ?? null)
    }

    const handleChange = async (event) => {

        const { value, name } = event.target
        const verify = name === "metric" ? "attribute" : "metric"

        setMetrics(prevState => ({
            ...prevState,
            [name]: value ?? null
        }))

        if (metrics[verify]) {
            const data = {
                [name + "Id"]: value,
                [verify + "Id"]: metrics[verify]
            }

            filterMetrics(data)
        }
    }

    useEffect(() => {
        if (store.metricsInfo) {
            const metricVisualize = store.metricsInfo

            setMetricsOpt(metricVisualize.metrics ?? [])
            setAttrOpt(metricVisualize.attributes ?? [])

            setGraphs({
                ...graphs,
                sum_col: metricVisualize.sum_col,
                percent_col: metricVisualize.percent_col
            })
        }
    }, [store.metricsInfo, store.selected])

    return {
        metricas,
        graphs,
        metrics,
        metricsOpt,
        attrOpt,
        loading,
        selectedMap,
        handleChangeYear,
        handleChange,
        filterMetrics
    }
}

export default useMetricsLogic
