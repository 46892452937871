import { useEffect, useState } from "react"
import getContractsFilter from "../../services/Contracts/getContractsFIlter"

function convertStatesToSMU(data) {
    const smuStates = {
        id: "states",
        name: "Estados",
        type: "smm",
        items: data.states ?? []
    }

    data.fields.unshift(smuStates)
    delete data.states
    return data.fields
}

const useContractsFilter = () => {

    const [showConf, setShowConf] = useState({})
    const [dataFilter, setDataFilter] = useState([])

    useEffect(() => {

        getContractsFilter().then(result => {
            if (result.status) {
                const data = result.data
                const show = {
                    contractors: data.contractors ?? 'F'
                }

                setShowConf(show)

                const filter = convertStatesToSMU(data ?? {})
                setDataFilter(filter)
            }
        }).catch(error => {
            console.log(error);
        })
    }, [])

    return {
        showConf,
        dataFilter
    }
}

export default useContractsFilter
