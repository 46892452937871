import statesFilter from "../../services/Tramites/states"


const useTramites = () => {
    const getTramitesInfo = async (filter) => {

        const response = await statesFilter(filter)

        if (response?.status) {
            const datos = response.data

            if (datos) {

                const state_day = []
                const state_total = []

                if (datos.state_day) {
                    datos.state_day.forEach(element => {
                        const info = {
                            name: element.state,
                            y: element.days * 1
                        }
                        state_day.push(info)
                    })
                }

                if (datos.state_total) {
                    datos.state_total.forEach(element => {
                        const info = {
                            name: element.state,
                            y: element.total,
                            percent: element.percent,
                            orden: element.order
                        }
                        state_total.push(info)
                    })
                }

                return {
                    state_day: state_day,
                    state_total: state_total
                }
            }
        }

        return null
    }

    return { getTramitesInfo }
}

export default useTramites
