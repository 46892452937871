import { Field } from "formik"
import '../style/styles.css'
import AsteriskRequired from "./Required/RequiredField"

function Input(props) {
    const { label, name, required, max_length, pattern, message_pattern, ...rest } = props

    const validateInput = (value) => {
        let errorMessage = ''

        const valuetrim = value?.trim() ?? ''

        if (max_length && valuetrim.length > max_length) {
            errorMessage = `El atributo ${label} no debe tener más de ${max_length} caracteres`
        }

        if (pattern && value) {
            const regex = new RegExp(pattern)
            if (!regex.test(value)) {
                errorMessage = message_pattern || `El atributo ${label} no cumple el patrón requerido`
            }
        }

        return errorMessage
    }

    return (
        <div>
            <label htmlFor={name}>
                {label}
                {required && (<AsteriskRequired />)}
            </label>
            <Field
                className="campos"
                style={{ backgroundColor: "white" }}
                id={name}
                validate={validateInput}
                name={name}
                {...rest}
            />
            <br />
        </div>
    )
}

export default Input
