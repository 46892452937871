import {
    Box,
    Drawer,
    FormControlLabel,
    FormGroup,
    Input,
    Radio,
    TextField
} from "@mui/material"

const MenuColumn = (props) => {

    const {
        open,
        handleToggle,
        manera,
        columsOptions,
        filterOptions,
        setFilterOptions

    } = props

    const handleChange = (event) => {

        const newValue = event.target.value
        let value = ""

        if (newValue !== filterOptions.column) {
            value = newValue
        }

        setFilterOptions({
            ...filterOptions,
            column: value
        })
    }

    const handleChangeOrden = (event) => {
        setFilterOptions({
            ...filterOptions,
            orden: event.target.value
        })
    }

    const handleKeyDown = (event) => {
        const invalidCharacters = ['-', '+', 'e', '`', '.']

        if (invalidCharacters.includes(event.key)) {
            event.preventDefault()
        }
    }

    const handleChangeItems = (event) => {
        setFilterOptions({
            ...filterOptions,
            items: event.target.value
        })
    }

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleToggle}
        >
            <Box
                role="presentation"
                width={"25em"}
                style={{ padding: "20px", width: "22em" }}
                sx={{ width: 250 }}
            >

                <h6>Cantidad de items</h6>
                <Input
                    placeholder="Todos"
                    type="number"
                    autoComplete="off"
                    value={filterOptions.items ?? ''}
                    inputProps={{
                        min: 0
                    }}
                    onKeyDown={handleKeyDown}
                    onChange={handleChangeItems}
                    fullWidth
                />

                <h6>Ordenar por</h6>
                <FormGroup>
                    {
                        columsOptions.map(option => (
                            option && (
                                <FormControlLabel
                                    key={option.value}
                                    control={
                                        <Radio
                                            checked={filterOptions.column === option.value}
                                            onChange={handleChange}
                                            name={option.label}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label} />
                            )
                        ))
                    }
                </FormGroup>

                <h6>De manera</h6>
                <FormGroup>
                    {
                        manera.map(option => (
                            <FormControlLabel
                                key={option.value}
                                control={
                                    <Radio
                                        checked={filterOptions.orden === option.value}
                                        onChange={handleChangeOrden}
                                        name={option.label}
                                        value={option.value}
                                    />
                                }
                                label={option.label} />
                        ))
                    }
                </FormGroup>
            </Box>
        </Drawer>
    )
}

export default MenuColumn
