import { useLocation } from 'react-router-dom'
import QRSteps from './QRSteps'
import QrCode from './componentsQR/QR/QrCode'

const QR = () => {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const q = queryParams.get("q")

    if (q) {
        return (<QrCode value={q} />)
    }

    return (
        <QRSteps />
    )
}

export default QR
