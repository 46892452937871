import { useEffect, useState } from "react"
import { Menu, Sidebar, MenuItem, useProSidebar } from "react-pro-sidebar"

import { useSidebarContext } from "./sidebarContext"

import { useLocation, useNavigate } from "react-router-dom"
import { tokens } from "./theme"
import { useTheme, Box, Typography, IconButton } from "@mui/material"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import {
    ArrowBack,
    AutoGraph,
    FilterList,
    LocationOn,
    PivotTableChart,
    QuestionAnswer
} from "@mui/icons-material"
import { useAppContext } from "../../pages/Tramites/layout"

const EQUIVALENCES = {
    "": "Estado",
    "attributes": "Atributos",
    "map": "Mapa",
    "pivotte": "Tabla dinámica",
    "metrics": "Métricas",
    "answers": "Respuesta"
}

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return (
        <MenuItem
            active={selected === title}
            style={
                selected === title
                    ? { color: colors.redAccent[500] }
                    : { color: colors.grey[100] }}
            onClick={() => {
                if (title !== 'Filtrar') {
                    setSelected(title)
                }

                to()
            }}
            icon={icon}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    )
}

const MyProSidebar = ({ filter }) => {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const route = queryParams.get('route')
    const store = useAppContext()

    const currentUrl = window.location.href
    const urlLink = new URL(currentUrl)

    const section = (urlLink.pathname).slice(1) ?? 'Estado'

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [selected, setSelected] = useState(EQUIVALENCES[section] || '')
    const { sidebarRTL, sidebarImage } = useSidebarContext()
    const { collapseSidebar, collapsed } = useProSidebar()
    const navigate = useNavigate()

    const bgTransparent = "transparent !important"

    const handleClick = (url) => {
        window.location.replace(url)
    }

    const redirigir = (url) => {
        return navigate(url)
    }

    const itemsToShow = [
        {
            title: "Regresar",
            to: () => handleClick(localStorage.getItem("back")),
            icon: <ArrowBack titleAccess="Regresar" />,
            show: true
        },
        {
            title: "Filtrar",
            to: () => filter(),
            icon: <FilterList titleAccess="Filtrar" />,
            show: true
        },
        {
            title: "Estado",
            to: () => redirigir("/?route=" + route),
            icon: <HomeOutlinedIcon titleAccess="Estado" />,
            show: true
        },
        {
            title: "Atributos",
            to: () => redirigir("/attributes?route=" + route),
            icon: <AutoGraph titleAccess="Atributos" />,
            show: true
        },
        {
            title: "Mapa",
            to: () => redirigir("/map?route=" + route),
            icon: <LocationOn titleAccess="Mapa" />,
            show: store?.type?.georeference === "T"
        },
        {
            title: "Tabla dinámica",
            to: () => redirigir("/pivotte?route=" + route),
            icon: <PivotTableChart titleAccess="Tabla dinámica" />,
            show: true
        },
        {
            title: "Métricas",
            to: () => redirigir("/metrics?route=" + route),
            icon: <TrendingUpIcon titleAccess="Métricas" />,
            show: true
        },
        {
            title: store?.type?.answers?.label ?? '',
            to: () => redirigir("/answers?route=" + route),
            icon: <QuestionAnswer titleAccess={store?.type?.answers?.label ?? ''} />,
            show: store?.type?.answers?.value === "T"
        }
    ]

    const itemsNav = itemsToShow?.filter((item) => item.show).map((item) => (
        <Item
            key={item.title}
            title={item.title}
            to={item.to}
            icon={item.icon}
            selected={selected}
            setSelected={setSelected}
        />
    ))

    useEffect(() => {
        if (!collapsed) {
            collapseSidebar()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box
            sx={{
                position: "sticky",
                display: "flex",
                height: "100vh",
                top: 0,
                bottom: 0,
                zIndex: 800,
                "& .sidebar": {
                    border: "none"
                },
                "& .menu-icon": {
                    backgroundColor: bgTransparent
                },
                "& .menu-item": {
                    backgroundColor: bgTransparent
                },
                "& .menu-anchor": {
                    color: "inherit !important",
                    backgroundColor: bgTransparent
                },
                "& .menu-item:hover": {
                    color: `${colors.blueAccent[500]} !important`,
                    backgroundColor: bgTransparent
                },
                "& .menu-item.active": {
                    color: `${colors.greenAccent[500]} !important`,
                    backgroundColor: bgTransparent
                }
            }}
        >
            <Sidebar
                breakPoint="md"
                rtl={sidebarRTL}
                backgroundColor={colors.primary[400]}
                image={sidebarImage}
            >
                <Menu iconshape="square">
                    <MenuItem
                        icon={
                            collapsed && (
                                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
                            )
                        }
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100]
                        }}
                    >
                        {!collapsed && (
                            <Box
                                display="fixed"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography variant="h3" color={colors.grey[100]}>
                                </Typography>
                                <IconButton
                                    onClick={
                                        () => collapseSidebar()
                                    }
                                >
                                    <CloseOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    <Box paddingLeft={collapsed ? undefined : "10%"}>{itemsNav}</Box>
                </Menu>
            </Sidebar>
        </Box>
    )
}

export default MyProSidebar
