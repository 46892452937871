import Cookies from "js-cookie";

const statesFilter = async ({ start_date, end_date, states, fields }) => {
  const route = "process";
  const endpoint =
    localStorage.getItem("api_" + route) || localStorage.getItem("api");
  const url = endpoint + "states";
  const instance = localStorage.getItem("instance");
  const id = localStorage.getItem("id");

  let header = {
    id: id,
    filter: {
      start_date: start_date,
      end_date: end_date,
      states: states,
      fields: fields,
    },
  };

  try {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tkreact_" + instance),
      },
      body: JSON.stringify(header),
    };

    const response = await fetch(url, config);
    const json = await response.json();
    return json ?? undefined;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default statesFilter;
