import { Stack } from "@mui/joy"

const ChartContainer = (props) => {

    return (
        <Stack direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            {props.children}
        </Stack>
    )
}

export default ChartContainer
