import { getAuthEndpoint, getEndpoint, getToken } from './credentials'

const adicionarMensaje = async (datos) => {

    const endpoint = getEndpoint() + "message"
    const me = getAuthEndpoint()

    try {
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken(),
                "Permission": me
            },
            body: JSON.stringify(datos)
        }

        const response = await fetch(endpoint, config)
        const json = await response.json()
        return json ?? undefined

    } catch (error) {
        console.log(error)
        return null
    }
}

export default adicionarMensaje
