import jwt_decode from "jwt-decode"

const decipher = (token) => {
    const decoded = jwt_decode(token, { header: true })
    return decoded
}

export const verify = (dict, keyRequired) => {
    const keys = Object.keys(dict)
    const areAllElementsPresent = keyRequired?.every(elem => keys.includes(elem))
    return areAllElementsPresent
}

export default decipher