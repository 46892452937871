import React, { useState } from 'react';
import { Grid, Stepper, Step, StepLabel, Stack, Button } from '@mui/material'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/es'
import QRForm from './QRForm'
import { generateUniqueCode } from '../../utils/keyUnique'
import QrCode from './componentsQR/QR/QrCode'
import ContainerJSON from './componentsQR/ContainerJSON'
import ContainerButtons from './componentsQR/ContainerButtons'
import { validateJSON } from './utils/jsonValidator'
import { BUTTONCONTAINERSTYLE, CONTAINERSTEP, JSONEDITORSTYLE } from './StyleQr/StyleQR'

const QRSteps = () => {

    const [activeStep, setActiveStep] = useState(0)
    const [step2Data, setStep2Data] = useState(null)
    const [isValid, setIsValid] = useState(false)
    const [jsonData, setJsonData] = useState({})

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1)
    }

    const steps = [
        'Editar JSON',
        'Formulario',
        'Generación del QR'
    ]

    const handleJsonChange = (value) => {
        try {
            JSON.parse(value.json)
            const ok = validateJSON(value.json)
            setIsValid(ok)
            setJsonData(value.jsObject)
        } catch (error) {
            setIsValid(false)
        }
    }

    const handleForm = (values) => {
        setStep2Data(values)
        setActiveStep((prevStep) => prevStep + 1)
    }

    const submitButton = <div style={BUTTONCONTAINERSTYLE.containerButtons}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
            Atrás
        </Button>

        <Button
            variant="contained"
            color="primary"
            type="submit"
        >
            Siguiente
        </Button>
    </div>

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <ContainerJSON>
                        <JSONInput
                            id="a_unique_id"
                            locale={locale}
                            height="80vh"
                            width="100%"
                            onChange={handleJsonChange}
                            placeholder={jsonData ?? ''}
                            style={JSONEDITORSTYLE}
                        />
                    </ContainerJSON>
                )
            case 1:
                return (
                    <div>
                        <QRForm
                            campos={jsonData.campos}
                            submitButton={submitButton}
                            handleSubmit={handleForm}
                            definedValues={step2Data ?? {}}
                        />
                    </div>
                )

            case 2:
                return (
                    <div>
                        <QrCode value={JSON.stringify(step2Data) ?? null} />
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <Grid style={CONTAINERSTEP.container}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, _index) => (
                    <Step key={"step" + generateUniqueCode()}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Stack>
                {activeStep === steps.length ? (
                    <div>
                        <p>¡Formulario completado!</p>
                    </div>
                ) : (
                    <div style={{ marginTop: '2em' }}>

                        {getStepContent(activeStep)}

                        {
                            activeStep !== 1 && (
                                <ContainerButtons>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        size='small'
                                    >
                                        Atrás
                                    </Button>

                                    {

                                        activeStep !== steps.length - 1 && (
                                            <Button
                                                disabled={isValid === false}
                                                variant="contained"
                                                color="primary"
                                                size='small'
                                                onClick={handleNext}
                                            >
                                                {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                                            </Button>
                                        )}
                                </ContainerButtons>
                            )
                        }
                    </div>
                )}
            </Stack>
        </Grid>
    )
}

export default QRSteps
