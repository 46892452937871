const cellPropsStyle = {
    whiteSpace: "nowrap",
    position: "sticky",
    left: "0",
    zIndex: 100,
    align: "center",
    textAlign: "center",
}

const headerPropsStyle = {
    whiteSpace: "nowrap",
    align: 'center',
    justifyContent: "center"
}

function getFilterField(tableState, index) {

    let filter = tableState !== undefined && tableState !== null ? [tableState.index] : null

    if (tableState !== undefined && tableState !== null) {

        const valor = tableState[index.toString()]

        if (valor === []) {
            filter = null
        } else {
            filter = valor
        }
    }

    return filter
}

function getColumnState(columnState, index, defaultBool = true) {
    let displayfilter = defaultBool

    if (columnState !== undefined && columnState !== null) {
        const display = columnState[index]

        if (display !== 'true' && defaultBool) {
            displayfilter = false
        }
    }

    return displayfilter
}

export function headerConf(atributos, statusTable) {
    let encabezadoTabla = []
    const tableState = statusTable.filter
    const columnsState = statusTable.column

    atributos.forEach((element, index) => {

        const visualize = 'display' in element ? element.display : true

        const filter = getFilterField(tableState, index)
        const displayfilter = getColumnState(columnsState, index, visualize)

        const cabeza = {
            name: element.name,
            label: 'label' in element ? element.label.toUpperCase() : element.name.toUpperCase(),
            options: {
                setCellProps: () => ({ style: element.style ? element.style : cellPropsStyle }),
                setCellHeaderProps: () => ({ style: headerPropsStyle }),
                filter: visualize,
                sort: visualize,
                viewColumns: visualize,
                display: displayfilter,
                filterList: filter,
                customBodyRender: element.bodyRender ? element.bodyRender : null
            },
        }

        encabezadoTabla.push(cabeza)
    })

    return encabezadoTabla
}