import jwt_decode from "jwt-decode";

function isKeyExists(obj, key) {
    return obj[key] === undefined ? false : true
}

const jwt_verification = async (token) => {

    const decodedHeader = jwt_decode(token, { header: true })
    const decode = jwt_decode(token)

    if (isKeyExists(decodedHeader, "typ") && decodedHeader["typ"] === "JWT") {
        if (isKeyExists(decode, "aud") &&
            isKeyExists(decode, "jti") &&
            isKeyExists(decode, "iss") &&
            isKeyExists(decode, "nbf") &&
            isKeyExists(decode, "exp")) {
            return true
        }
    }

    return false
}

export default jwt_verification