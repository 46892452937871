import React, { useState, useEffect } from "react";
import LostConnection from "../LostConnection/LostConnection";

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const handleErrors = (error, info) => {
      setMessage("Error: ", info, error);
      setHasError(true);
    };
    window.addEventListener("error", handleErrors);
    return () => {
      window.removeEventListener("error", handleErrors);
    };
  }, []);

  if (hasError) {
    return <LostConnection />;
  }

  return children;
}

export default ErrorBoundary;
