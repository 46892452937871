import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material"
import { generateUniqueCode } from "../../utils/keyUnique"

const styleSelect = {
    display: 'block',
    width: 'auto',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: "24px",
    fontFamily: "Source Sans Pro,sans-serif",
    fontSize: '0.8rem',
    minHeight: '12px',
    lineHeight: '1.5em',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    fontWeight: 200
}

const SelectValue = ({ label, options, value, onChange, name = null, ...rest }) => {

    return (
        <FormControl fullWidth style={{ marginTop: "1em" }}>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={label}
                name={name ?? label}
                onChange={onChange}
                {...rest}
            >
                {
                    options.map((option) => {
                        return (
                            <MenuItem
                                key={(option.key || option.value) ?? generateUniqueCode()}
                                value={option.value ?? option.name}
                                style={styleSelect}
                            >
                                {option.name}
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}

export default SelectValue
