import { useEffect, useRef, useState } from "react"
import { useAppContext } from "../layout"
import { localPosition, verifyCoords } from "../../../utils/Coordinates"
import useMarkers from "../../../hooks/Tramites/markers"
import { useNavigate } from "react-router-dom"
import { getFilterValues } from "../utils/state"
import alertify from "alertifyjs"
import EsLabels from "../../../components/Table/EsLabels"

const useMapTramitesLogic = () => {
    const actPos = localPosition()
    const position = actPos ?? [4.142, -73.626]

    const [center, setCenter] = useState(position)
    const { getCoordinates, getPopupMessage, getConfTable, getTableData } = useMarkers()
    const [markers, setMarkers] = useState(null)
    const [popupData, setPopupData] = useState(null)
    const [columns, setColumns] = useState(null)
    const [data, setData] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [coordUbicated, setCoordUbicated] = useState(null)
    const [reubicated, setReubicated] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useNavigate()
    const store = useAppContext()

    const tableRef = useRef(null)

    const filtrarCoordenadas = async () => {
        setLoading(true)
        await filtrar()
        const names = columns?.map((objeto) => objeto.name)
        await getDataTable(names ?? [])
        setLoading(false)
    };

    const filtrar = async () => {
        if (store.selected) {

            const resultado = getFilterValues(store)

            if (resultado) {
                if (store.open) {
                    store.handleClose()
                }

                const marcas = await getCoordinates(resultado)
                setMarkers(marcas ?? [])
            }
        }
    }

    const getDataTable = async (cols) => {
        if (store.selected) {
            const filter = getFilterValues(store)
            const datos = await getTableData(cols, { filter: filter })
            setData(datos ?? [])
        }
    }

    const getConfigTable = async () => {
        if (store.selected) {
            const datos = await getConfTable()
            setColumns(datos ?? [])
            const names = datos?.map((objeto) => objeto.name)
            getDataTable(names ?? [])
        }
    }

    const handlePopupClick = async (marker) => {
        setPopupData(null)
        const message = await getPopupMessage(marker)

        if (message) {
            setPopupData(message)
        }
    }

    const handleChangeCenter = (coordinates) => {
        const result = verifyCoords(coordinates[0], coordinates[1])

        if (result) {
            setCenter(coordinates)
        } else {
            const errorMsg = 'Las coordenadas proporcionadas no son ubicables'
            alertify.error(errorMsg)
        }
    };

    const recenter = (map, coord) => {
        if (reubicated) {
            map.setView([coord[0], coord[1]], 20)
            setReubicated(false)
        }
    }

    const getCoordinatesById = (markerid) => {
        const result = markers?.find((item) => item.id === markerid)
        setCoordUbicated(markerid)
        return result?.geocode ?? []
    }

    const options = {
        expandableRowsOnClick: false,
        responsive: "standard",
        tableBodyHeight: "estandar",
        tableBodyMaxHeight: "estandar",
        search: false,
        download: false,
        filter: false,
        print: false,
        pagination: true,
        selectableRows: "none",
        viewColumns: true,
        textLabels: EsLabels,
        pointerOnHover: true,
        serverSide: true,
        count: markers?.length ?? 0,
        onTableChange: async (action) => {

            const events = ['sort', "changeRowsPerPage", "changePage"]

            if (events.includes(action)) {
                const info = tableRef.current?.state
                const orderColumn = info.sortOrder
                const { rowsPerPage, page } = info
                //const rowsPerPage = info.rowsPerPage
                //const page = info.page

                const offset = (page) * rowsPerPage

                if (store.selected) {
                    const filter = getFilterValues(store)

                    const names = columns?.map(objeto => objeto.name)
                    const datos = await getTableData(names ?? [], {
                        filter: filter,
                        offset: offset,
                        limit: rowsPerPage,
                        orientation: orderColumn.direction,
                        order: orderColumn.name
                    })

                    setData(datos ?? [])
                }
            }
        },
        setRowProps: (row) => {

            const id = row[0].props?.children
            return {
                style: {
                    backgroundColor: id === selectedRow ? "#e4f2fe" : null,
                    cursor: 'pointer'
                }
            }
        },
        onRowClick: (rowData) => {

            const names = columns?.map(objeto => objeto.name)
            const valor = names?.indexOf("id")

            if (valor >= 0) {
                const id = rowData[valor].props?.children

                if (id) {
                    const result = getCoordinatesById(id)
                    setReubicated(true)

                    if (result.length) {
                        handleChangeCenter(result)
                        setSelectedRow(id)
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (store.type) {
            const geo = store.type.georeference

            if (geo === 'T') {
                if (store.selected) {
                    filtrar()
                    getConfigTable()
                }
            } else {
                history('/')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.selected, store.type])

    return {
        center,
        markers,
        popupData,
        columns,
        data,
        coordUbicated,
        loading,
        options,
        tableRef,
        filtrarCoordenadas,
        handlePopupClick,
        recenter
    }
}

export default useMapTramitesLogic
