import decipher from "./Utils/JWT"

const getVisorData = async () => {

    const data = localStorage.getItem('data')
    const { id, token, endpoint, jwt } = decipher(data)

    const url = `${endpoint}/diagram/${id}/${token}`

    try {
        const config = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + jwt,
            }
        }

        const response = await fetch(url, config)
        const json = await response.json()

        return json

    } catch (error) {
        console.log(error)
    }
}

export default getVisorData