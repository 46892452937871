import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import defaultConfig from './Config/config'
import ChartContainerResponsive from './ChartContainerResponsive'

require("highcharts/modules/accessibility")(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const getOptions = (data, categories, title) => {

    const total = data.reduce((sum, point) => sum + point, 0)

    const options = {
        chart: {
            type: 'column'
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        const percentage = total !== 0 ? ((this.y / total) * 100).toFixed(2) : 0
                        return `${Highcharts.numberFormat(this.y, 0, ',', '.')} (${percentage}%)`
                    }
                }
            }
        },
        tooltip: {
            valueSuffix: '',
            formatter: function () {
                const decimals = (this.y % 1 !== 0) ? 2 : 0
                const formattedNumber = Highcharts.numberFormat(this.y, decimals, ',', '.')
                return `<span style="font-size: 10px">${this.key}</span><br/><span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${formattedNumber}</b><br/>`
            }
        },
        title: {
            text: title
        },
        yAxis: {
            title: {
                text: null
            }
        },
        xAxis: {
            categories: categories
        },
        series: [
            {
                name: "Cantidad",
                data: data,
                colorByPoint: true,
                showInLegend: true
            }
        ],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 700
                },
                chartOptions: {
                    legend: {
                        enabled: false
                    }
                }
            }]
        }
    }

    return Object.assign({}, options, defaultConfig)
}

const BarChartBasic = ({ data, title = "grafico", ...props }) => {

    const valores = []
    const encabezado = []

    data.forEach(element => {
        valores.push(parseFloat(element.y))
        encabezado.push(element.name)
    })

    return (
        <ChartContainerResponsive>
            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions(valores, encabezado, title)}
            />
            <br />
            {props.children}
        </ChartContainerResponsive>
    )
}

export default BarChartBasic
