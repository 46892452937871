import { Fragment } from "react"
import SelectValue from "../../../components/Select/Select"
import BarColumnChart from "../../../components/Chart/BarColumn"
import BasicTable from "../../../components/Table/BasicTable"
import Loading from "../../../components/Loading/Loading"
import FilterContract from "../FilterContract"
import useCamposLogic from "./useCamposLogic"
import NotData from "../../../components/Chart/NoData/NotData"

const Campos = () => {

    const {
        fieldSelect,
        fieldData,
        fieldOptions,
        loading,
        handleChange,
        filter,
        getNameFromId
    } = useCamposLogic()

    const len = fieldData?.chart?.length > 0

    if (loading || !fieldData?.chart) {
        return <Loading />
    }

    return (
        <Fragment>

            <FilterContract filter={filter} />

            <SelectValue
                label="Campos"
                options={fieldOptions ?? []}
                value={fieldSelect}
                onChange={handleChange}
                style={{ marginBottom: "16px" }}
            />

            {
                !len && (
                    <NotData />
                )
            }

            {
                len && (
                    <div>
                        <BarColumnChart
                            title={getNameFromId(fieldSelect)}
                            data={fieldData?.chart ?? []}
                            viewTable={false}
                            columsOptions={[
                                {
                                    label: "Item",
                                    value: "name"
                                },
                                {
                                    label: "Valor",
                                    value: "value"
                                },
                                {
                                    label: "Cantidad",
                                    value: "y"
                                }
                            ]}
                            defaultType="pie"
                        />

                        <BasicTable
                            columns={fieldData?.table.header}
                            data={fieldData?.table.body}
                            Footer={fieldData?.table.footer}
                        />
                    </div>
                )
            }
        </Fragment>
    )
}

export default Campos
