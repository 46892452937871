import { useState } from "react"

const usePivotTableFormState = () => {
    const [selectedOptions, setSelectedOptions] = useState({
        name: "",
        filas: [],
        columnas: [],
        numericas: []
    })

    const handleSelectChange = (selected, selectName) => {
        setSelectedOptions((prevState) => ({
            ...prevState,
            [selectName]: selected
        }))
    }

    const verifyFormData = () => {
        return !(
            selectedOptions.columnas.length &&
            selectedOptions.filas.length &&
            selectedOptions.name.trim()
        )
    }

    return {
        selectedOptions,
        handleSelectChange,
        verifyFormData
    }
}

export default usePivotTableFormState
