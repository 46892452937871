import { Button, Checkbox, Input, TextField, TextareaAutosize } from '@mui/material'
import React, { useRef, useState } from 'react'
import Select from 'react-select'
import SignatureCanvas from 'react-signature-canvas'
import 'react-responsive-modal/styles.css'
import Modal from 'react-responsive-modal'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers'

const LikertScaleQuestions = ({ data, isMissingColumn = false }) => {
    const { levels, items } = data
    const border = { border: "1px solid #CDCDCD", padding: "8px", fontSize: "12px" }

    const filteredItems = isMissingColumn ? items.slice(0, 3) : items
    const filteredLevels = isMissingColumn ? levels.slice(0, 3) : levels

    return (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
                <tr>
                    <th style={border}>Pregunta</th>
                    {filteredLevels.map((level) => (
                        <th key={level.id} style={border}>
                            {level.name}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {filteredItems.map((item) => (
                    <tr key={item.id}>
                        <td style={border}>
                            {item.name}
                        </td>
                        {filteredLevels.map((level) => (
                            <td key={level.id} style={border}>
                                <input type="radio" name={`respuesta-${item.id}`} value={level.id} />
                            </td>
                        ))}
                    </tr>
                ))}

                {isMissingColumn && (
                    <tr>
                        <td>
                            ...
                        </td>
                    </tr>
                )}

            </tbody>
        </table>
    )
}


function calculateMargin(options) {
    if (options.length) {
        if (options.length > 7) {
            return 18
        } else {
            return options.length * 2.5
        }
    }
    return 2.5
}

const SelectItem = ({ fieldType, options, multiple = false, isMissingColumn = false }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const selectStyles = {
        control: (provided) => ({
            ...provided,
            marginBottom: isMenuOpen ? calculateMargin(options) + "em" : "3px"
        })
    }

    const renderOptions = options.map((option) => (
        <label key={option.value} style={{ fontWeight: 'normal', fontSize: "12px" }}>
            {multiple && <input type={fieldType === "select" ? "checkbox" : "radio"} style={{ marginRight: "5px" }} />}
            {!multiple && <input type="radio" name="group" style={{ marginRight: "5px" }} />}
            {option.label}
        </label >
    ))

    if (isMissingColumn && fieldType !== "select") {
        return (
            <div>
                {renderOptions.slice(0, 2)}
                <span style={{ marginLeft: "5px" }}>...</span>
            </div>
        )
    }

    else {
        if (fieldType === "radio") {
            return (
                <div>
                    {options.map((option) => (
                        <label key={option.value} style={{ fontWeight: 'normal', fontSize: "12px" }}>
                            <input type="radio" name="radioField" value={option.value} />
                            {option.label}
                        </label>
                    ))}
                </div>
            )
        }

        if ((fieldType === "select" || fieldType === "") && !multiple) {
            return (
                <Select
                    options={options}
                    placeholder={multiple ? "Seleccione opciones" : "Seleccione una opción"}
                    styles={selectStyles}
                    onMenuOpen={() => setIsMenuOpen(true)}
                    onMenuClose={() => setIsMenuOpen(false)}
                    isMulti={multiple}
                />
            )
        }
    }

    return <div>{renderOptions}</div>
}

const FirmaDigital = () => {
    const [signatureData, setSignatureData] = useState(null)
    const signatureRef = useRef(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const saveSignature = () => {
        const data = signatureRef.current.toDataURL()
        setSignatureData(data)
        closeModal()
    }

    return (
        <div>
            <Modal
                open={isModalOpen}
                onClose={closeModal}
                center>
                <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{
                        style: {
                            border: '1px dotted #000000',
                            marginTop: "3em",
                            borderRadius: "8px",
                            width: "30em"
                        },
                        className: 'signature-canvas'
                    }}
                />
                <br />
                <Button onClick={saveSignature}>Guardar firma</Button>
            </Modal>

            {
                !signatureData && (
                    <div
                        onClick={openModal}
                        style={{
                            width: "100%",
                            border: "2px dotted black",
                            borderRadius: "8px",
                            fontSize: "20px",
                            textAlign: "center",
                            padding: "2em"
                        }}>
                        Presiona aquí para firmar
                    </div>
                )
            }

            {signatureData && (
                <div style={{ width: "100%" }} onClick={openModal}>
                    <img src={signatureData} alt="Signature" />
                </div>
            )}
        </div>
    )
}

const FieldComponent = ({ category, options, likert, classType, isMissingColumn }) => {

    const componentMap = {
        string: <Input type="text" placeholder='Escriba aquí...' fullWidth />,
        text: <TextareaAutosize placeholder='Escriba aquí...' minRows={5} />,
        time: (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    label=""
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    onChange={() => null}
                />
            </LocalizationProvider>
        ),
        date: (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label=""
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    onChange={() => null}
                    inputFormat="YYYY-MM-DD"
                />
            </LocalizationProvider>
        ),
        float: <Input type="number" placeholder='0' fullWidth />,
        smu: <SelectItem fieldType={classType} options={options} multiple={false} isMissingColumn={isMissingColumn} />,
        smm: <SelectItem fieldType={classType} options={options} multiple={true} isMissingColumn={isMissingColumn} />,
        esc: <LikertScaleQuestions data={likert} isMissingColumn={isMissingColumn} />,
        attach: <input type="file" disabled style={{ width: "100%" }} />,
        firma: <FirmaDigital />,
        label: <div />,
        check: <Checkbox />,
        usuario: <Input type='text' placeholder='Campo de usuario' fullWidth />,
        tercero: <Input type="text" placeholder="Campo de tipo tercero" fullWidth />,
        email: <Input type='email' placeholder='example@example.com' fullWidth />,
        default: <p>Error: Tipo de campo no definido</p>
    }

    const renderField = () => {
        const component = componentMap[category] || componentMap.default
        return <div>{component}</div>
    }

    return renderField()
}

export default FieldComponent
