import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Loading from '../../../components/Loading/Loading'
import { useFormikContext } from 'formik'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Stack } from '@mui/material'

const DynamicOrder = (props) => {

    const { setFieldValue } = useFormikContext()
    const { initialItems, name } = props

    const [items, setItems] = useState(null)

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const newItems = Array.from(items)
        const [removed] = newItems.splice(result.source.index, 1)
        newItems.splice(result.destination.index, 0, removed)

        const updatedItems = newItems.map((item, index) => ({
            label: item.label,
            value: item.value,
            order: index + 1
        }))

        setFieldValue(name, updatedItems)
        setItems(updatedItems)
    }

    useEffect(() => {
        const itemsI = initialItems.map((item, index) => ({
            label: item.label,
            value: item.value,
            order: index + 1
        }))

        setFieldValue(name, itemsI)
        setItems([...initialItems])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialItems, name])

    if (!initialItems) {
        return (<Loading />)
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="items">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            backgroundColor: "#eff4fb",
                            padding: "5px",
                            borderRadius: "10px",
                            border: "1px dashed #acc0da",
                            position: "relative"
                        }}
                    >
                        {items?.map((item, index) => (
                            <Draggable key={item.value} draggableId={item.label} index={index}>
                                {(given, snapshot) => (
                                    <div>

                                        {snapshot.isDragging && (
                                            <div
                                                style={{
                                                    backgroundColor: "transparent",
                                                    border: "1px dashed gray",
                                                    borderRadius: "10px",
                                                    position: "absolute",
                                                    margin: "8px",
                                                    width: given.draggableProps.style.width,
                                                    height: given.draggableProps.style.height,
                                                    pointerEvents: "none"
                                                }}
                                            />
                                        )}

                                        <div
                                            {...given.draggableProps}
                                            {...given.dragHandleProps}
                                            ref={given.innerRef}
                                            style={{
                                                userSelect: 'none',
                                                padding: '16px',
                                                margin: '8px',
                                                backgroundColor: snapshot.isDragging ? '#3f9fe0' : '#ffffff',
                                                color: snapshot.isDragging ? 'white' : 'black',
                                                border: '1px solid #acc0da',
                                                borderRadius: '8px',
                                                ...given.draggableProps.style
                                            }}
                                        >
                                            <Stack direction="row">
                                                <DragIndicatorIcon />
                                                {item.label}
                                            </Stack>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default DynamicOrder
