import { useNavigate, } from "react-router-dom"
import useMensajeForm from "../../../hooks/Mensajes/useMensajeForm"

import Loading from "../../../components/Loading/Loading"
import MessagesForm from "./messagesForm"

const MessagesCreate = () => {

    const history = useNavigate()
    const { createMensaje } = useMensajeForm()

    const initialValues = {
        name: '',
        code: '',
        content: '',
        active: 'T'
    }

    const onSubmit = async (fields) => {

        fields.name = fields.name.trim()
        fields.code = fields.code.trim()
        fields.content = fields.content.trim()

        let messageid = null

        await createMensaje(fields).then(result => {
            messageid = result
        }).catch((_err => {
            messageid = null
        }))

        if (messageid !== null && messageid !== undefined) {
            history('/update/' + messageid)
        }
    }

    if (initialValues === null) {
        return <Loading />
    }

    return (
        <MessagesForm initialValues={initialValues} onSubmit={onSubmit} />
    )
}

export default MessagesCreate
