export const manera = [
    {
        value: "asc",
        label: "Ascendente"
    },
    {
        value: "desc",
        label: "Descendente"
    }
]
