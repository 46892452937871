import React, { useState } from "react"
import { IconButton, Tooltip } from "@mui/material"
import { DeleteForeverRounded } from "@mui/icons-material";

const ButtonDelete = (props) => {

    const { onClick, title } = props
    const [isHovered, setIsHovered] = useState(false)

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    return (
        <Tooltip title={title ?? ""}>
            <IconButton
                style={{
                    width: "2em",
                    height: "2em",
                    color: "white",
                    backgroundColor: isHovered ? "#C62828" : "#F44336"
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
            >
                <DeleteForeverRounded />
            </IconButton>
        </Tooltip>
    )
}

export default ButtonDelete
