export const columsContratorOptions = [
    {
        value: "name",
        label: "Contratista"
    },
    {
        value: "y",
        label: "Contratos"
    },
    {
        value: "value",
        label: "Valor"
    }
]
