import React, { useState } from 'react'
import 'dayjs/locale/es'

import { TextField } from '@mui/material'
import { LocalizationProvider, esES, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import dayjs from 'dayjs'

const DatePickerContract = ({
    label = null,
    defaultValue = null,
    onChange = () => { }
}) => {

    const [value, setValue] = useState(defaultValue)
    const [open, setOpen] = useState(false)

    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="es"
            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <DatePicker
                defaultValue={dayjs(defaultValue)}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                inputFormat="YYYY-MM-DD"
                views={['year', 'month', 'day']}
                value={value}
                onChange={(newValue) => {
                    const formatValue = newValue?.format('YYYY-MM-DD')
                    setValue(newValue)
                    onChange(formatValue)
                }}
                renderInput={(params) => <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        placeholder: label
                    }}
                    autoComplete='off'
                    onClick={() => { setOpen(true) }}
                    fullWidth
                />}
            />
        </LocalizationProvider >
    )
}

export default DatePickerContract
