import getTabulateArea from "../../services/Contracts/tabulateArea/getTabulateArea";

const useTabulateArea = () => {

    function createTree(data) {
        const tree = []
        const map = {}

        data.forEach(obj => {
            obj.children = []
            map[obj.id] = obj
        });

        data.forEach(obj => {
            if (obj.parent === 0) {
                tree.push(obj)
            } else {
                const parentObj = map[obj.parent]
                if (parentObj) {
                    parentObj.children.push(obj)
                }
            }
        })

        return tree
    }

    function calculateValueRanges(data) {
        const contractsValues = data.map(item => item.contracts)
        const totalValues = data.map(item => item.total)

        const contractsRange = [Math.min(...contractsValues), Math.max(...contractsValues)]
        const totalRange = [Math.min(...totalValues), Math.max(...totalValues)]

        return {
            contracts: contractsRange,
            total: totalRange
        }
    }

    const getTabArea = async (filter) => {

        const response = await getTabulateArea(filter)

        if (response.status) {
            const data = response?.data
            const tree = createTree(data)
            const range = calculateValueRanges(data)

            return {
                tree: tree,
                range: range
            }
        }

        return null
    }

    return {
        getTabArea
    }
}

export default useTabulateArea
