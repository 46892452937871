export function setFetchValues(selected, campos, estados) {

    selected.forEach(valor => {
        if (valor.items.length > 0) {
            if (valor.field_id !== "states") {
                const fields = []

                valor.items.forEach(element => {
                    fields.push(element.value * 1)
                })

                const field = { field_id: valor.field_id * 1, items: fields }
                campos.push(field)

            } else {
                if (valor.field_id === "states") {
                    valor.items.forEach(element => {
                        estados.push(element.value * 1)
                    })
                }
            }
        }
    })
}

export function getFieldsSelected(selected) {
    const campos = []

    selected.forEach(valor => {
        if (valor.items.length > 0 && valor.field_id !== "states") {
            const fields = []

            valor.items.forEach(element => {
                fields.push(element.value * 1)
            })

            const field = { field_id: valor.field_id * 1, items: fields }
            campos.push(field)
        }
    })

    return campos
}
