import { createContext, useContext, useMemo } from "react";
import Mensajes from "./mensajes";

const AppContext = createContext({
    status: {},
    columnsState: [],
    stateSite: false,
})

const MessageContext = () => {

    let status = null
    let columnsState = null
    let stateSite = false

    return (
        <AppContext.Provider value={useMemo(() => ({ status, columnsState, stateSite }), [columnsState, status, stateSite])}>
            <Mensajes />
        </AppContext.Provider>
    )
}

export default MessageContext

export function useAppContext() {
    return useContext(AppContext)
}