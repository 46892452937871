import Cookies from "js-cookie";

const getIpsValidas = async () => {
  const route = "adm_securitygroup";
  const endpoint =
    localStorage.getItem("api_" + route) || localStorage.getItem("api");
  const url = endpoint + "rules";
  const app = localStorage.getItem("application");
  const instance = localStorage.getItem("instance");

  try {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tkreact_" + instance),
        application: app,
      },
    };

    const response = await fetch(url, config);
    const json = await response.json();
    return json ?? null;
  } catch (error) {
    throw new Error("Se ha producido un error durante el fetch");
  }
};

export default getIpsValidas;
