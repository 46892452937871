import styled from 'styled-components'

const ContainerButtons = styled.div`
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    @media (max-width: 325px) {
    overflow-x: auto;
    overflow-y: auto;
    width: calc(100% + ((300px - 100vw) * 1));
    padding: "0.5em";
  };
`;

export default ContainerButtons
