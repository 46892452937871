import styled from 'styled-components'

const ContainerJSON = styled.div`
  height: auto;
  margin: 0 auto;
  width: 90%;
  padding: 1em;
  @media (max-width: 1000px) {
    width: 100%;
    height: auto;
    border: none;
  };
  @media (max-width: 325px) {
    overflow-x: auto;
    overflow-y: auto;
    width: calc(100% + ((300px - 100vw) * 1));
    padding: "0.5em";
  };
`;

export default ContainerJSON
