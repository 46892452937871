import { Input } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import LabelForm from './labelForm';

const DynamicForm = (props) => {
    const {
        selectedOptions,
        handleSelectChange,
        fields,
        numerics,
        name
    } = props

    const [opcionesFilas, setOpcionesFilas] = useState(fields)
    const [opcionesColumnas, setOpcionesColumnas] = useState(fields)

    useEffect(() => {
        const opcionesFilasRestantes = fields.filter(
            (opcion) =>
                !selectedOptions.columnas.some(
                    (selected) => selected.value === opcion.value
                )
        )
        setOpcionesFilas(opcionesFilasRestantes)
    }, [selectedOptions.columnas, fields])

    useEffect(() => {
        const opcionesColumnasRestantes = fields.filter(
            (opcion) =>
                !selectedOptions.filas.some(
                    (selected) => selected.value === opcion.value
                )
        )
        setOpcionesColumnas(opcionesColumnasRestantes)
    }, [selectedOptions.filas, fields])

    return (
        <div>

            <LabelForm name="Nombre" required={true} />
            <Input
                type="text"
                style={{ marginBottom: "20px", width: "100%" }}
                value={name}
                placeholder='Ingresa nombre para la tabla dinámica'
                onChange={(digited) => handleSelectChange(digited.target.value, 'name')}
            />
            <br />

            <LabelForm name="Filas" required={true} />
            <Select
                closeMenuOnSelect={false}
                options={opcionesFilas}
                value={selectedOptions.filas}
                onChange={(selected) => handleSelectChange(selected, 'filas')}
                placeholder="Seleccione"
                isMulti
            />

            <br />

            <LabelForm name="Columnas" required={true} />
            <Select
                closeMenuOnSelect={false}
                options={opcionesColumnas}
                value={selectedOptions.columnas}
                placeholder="Seleccione"
                onChange={(selected) => handleSelectChange(selected, 'columnas')}
                isMulti
            />

            <br />

            <LabelForm name="Campos numéricos" />
            <Select
                closeMenuOnSelect={false}
                options={numerics}
                value={selectedOptions.numericas}
                placeholder="Seleccione"
                onChange={(selected) => handleSelectChange(selected, 'numericas')}
                isMulti
            />
        </div>
    )
}

export default DynamicForm
