import { Tooltip, styled } from "@mui/material"

const StyledAsterisk = styled("span")({
    color: "red",
    fontSize: "20px",
    marginLeft: "3px"
})

function AsteriskRequired({ tooltipTitle }) {
    return (
        <Tooltip title={tooltipTitle ?? "Requerido"} placement="top" arrow>
            <StyledAsterisk>*</StyledAsterisk>
        </Tooltip>
    )
}

export default AsteriskRequired
