import Cookies from "js-cookie";

const getLayout = async (secureid = null, securemd = null) => {
  const route = "layout";
  const endpoint =
    localStorage.getItem("api_" + route) || localStorage.getItem("api");
  const instance = localStorage.getItem("instance");
  const id = secureid ?? localStorage.getItem("id");
  const md = securemd ?? localStorage.getItem("md") ?? "ec";
  const url = `${endpoint}layout/${id}/${md}`;

  try {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tkreact_" + instance),
      },
    };

    const response = await fetch(url, config);
    return await response.json();
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default getLayout;
