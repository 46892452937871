import { Fragment } from "react"
import Loading from "../../../components/Loading/Loading"
import { convertMoneyFormat } from "../../../components/Chart/Format/format"
import BasicExpansibleTable from "../../../components/Table/Expansible/BasicExpansibleTable"
import { IconButton } from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import FilterContract from "../FilterContract"
import useAreaLogic from "./useAreaLogic"
import * as XLSX from 'xlsx'

const ExpandButton = ({ row }) => (
    <IconButton
        onClick={row.getToggleExpandedHandler()}
        style={{ cursor: "pointer" }}
        size="small"
    >
        {row.getIsExpanded() ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
)

const NotChild = () => (
    <IconButton
        size="small"
        style={{ cursor: "default" }}
    >
        <KeyboardArrowRightOutlinedIcon />
    </IconButton>
)

const MoneyCell = ({ value }) => <div style={{ textAlign: "right", fontSize: "1em" }}>${convertMoneyFormat(value)}</div>

const ContractCell = ({ value }) => <div style={{ textAlign: "right", fontSize: "1em" }}>{value}</div>

const renderCell = ({ row, getValue }) => {
    const paddingLeft = `${row.depth * 2.5}rem`

    return (
        <div style={{ paddingLeft, fontSize: "1em" }}>
            {row.getCanExpand() ? <ExpandButton row={row} /> : <NotChild />}
            {getValue()}
        </div>
    )
}

const renderTotalCell = (props) => {
    return <MoneyCell value={props.getValue()} />
}

const renderContractCell = (props) => {
    return <ContractCell value={props.getValue()} />
}

const Area = () => {

    const {
        table,
        stateData,
        loading,
        generateBackgroundColor,
        filter
    } = useAreaLogic()

    const flattenData = (node, depth = 0, result = []) => {
        if (node) {
            result.push({
                'Área': '-'.repeat(depth) + node.area,
                'Contratos': node.contracts || 0,
                'Total': node.total || 0
            })

            for (const child of node.children) {
                flattenData(child, depth + 1, result)
            }
        }

        return result
    }

    const generateXLSX = () => {
        const flattenedData = flattenData(stateData?.tree[0])

        if (flattenedData.length) {
            const ws = XLSX.utils.json_to_sheet(flattenedData, {
                header: ['Área', 'Contratos', 'Total']
            })

            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, 'DataSheet')

            const blob = new Blob([XLSX.write(wb, { bookType: 'xlsx', type: 'array' })], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })

            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'data.xlsx'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
        }
    }

    const defaultColumns = [
        table.createDataColumn("area", {
            id: "Área",
            header: <span>Área</span>,
            cell: renderCell
        }),
        table.createDataColumn("contracts", {
            id: "Contratos",
            cell: renderContractCell
        }),
        table.createDataColumn("total", {
            id: "Total",
            cell: renderTotalCell
        })
    ]

    if (loading) {
        return <Loading />
    }

    return (
        <Fragment>
            <FilterContract filter={filter} />

            {
                stateData?.tree && (
                    <div id="tableAreas">
                        <BasicExpansibleTable
                            title="Contratos por Área"
                            table={table}
                            defaultColumns={defaultColumns}
                            defaultData={stateData?.tree}
                            generateBackgroundColor={generateBackgroundColor}
                            downloadXLSX={generateXLSX}
                        />
                    </div>
                )
            }

        </Fragment>
    )

}

export default Area
