import FormikControl from "../formularios/componentes/FormikControl"

export const componentMap = {
    string: (campo) => (
        <div>
            <FormikControl
                label={campo.nombre}
                name={campo.alias}
                control="input"
                autoComplete="off"
                required={campo.validacion?.toUpperCase() === "R"}
                style={{ width: "100%" }}
                max_length={campo.max_length}
                pattern={campo.pattern}
                message_pattern={campo.mensaje_pattern}
            />
            <br />
        </div>
    ),
    smu: (campo) => (
        <div>
            <FormikControl
                label={campo.nombre}
                name={campo.alias}
                control="selectsearch"
                options={campo.items}
                required={campo.validacion?.toUpperCase() === "R"}
                placeholder="Seleccione"
                style={{ width: "100%" }}
            />
            <br />
        </div>
    ),
    date: (campo) => (
        <div>
            <FormikControl
                control="date"
                label={campo.nombre}
                name={campo.alias}
                required={campo.validacion?.toUpperCase() === "R"}
                min={campo.fecha_minima ?? undefined}
                max={campo.fecha_maxima ?? undefined}
                style={{ width: "100%", overflow: "hidden" }}
            />
            <br />
        </div>
    ),
    text: (campo) => (
        <div>
            <FormikControl
                control="textarea"
                label={campo.nombre}
                name={campo.alias}
                required={campo.validacion?.toUpperCase() === "R"}
                placeholder="Escribe aquí..."
                style={{ width: "100%" }}
            />
            <br />
        </div>
    ),
    float: (campo) => (
        <div>
            <FormikControl
                control="float"
                label={campo.nombre}
                name={campo.alias}
                min={campo.valor_minimo}
                max={campo.valor_maximo}
                required={campo.validacion?.toUpperCase() === "R"}
                decimalPlaces={campo.decimales ?? undefined}
                style={{ width: "100%" }}
                max_length={campo.max_length}
                pattern={campo.pattern}
                message_pattern={campo.mensaje_pattern}
            />
            <br />
        </div>
    )
}
