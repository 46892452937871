import styled from 'styled-components'

const BoxQR = styled.div`
    max-height: auto;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 325px) {
        overflow: visible;
        width: calc(100% + ((300px - 100vw) * 1));
    };
`;

export default BoxQR
