import moment from "moment";

function sumarDias(fecha, dias) {
    try {

        const day = moment(fecha).add(dias, "d")
        fecha = new Date(day)

        return fecha;
    } catch (error) {
        return false
    }
}

function validarFechaEnRango(fechaValidarMs) {

    let current = new Date()
    current = current.setMinutes(0, 0, 0)

    const fechaFinMs = sumarDias(current, 30)

    return fechaValidarMs > current && fechaValidarMs <= fechaFinMs;
}

const date_verification = (date) => {

    if (JSON.stringify(date["$d"]) !== "null") {
        if (validarFechaEnRango(new Date(date["$d"]))) {
            return true
        }
    } else {
        return false
    }
}

export default date_verification