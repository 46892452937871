import { useEffect, useState } from "react"
import { useAppContext } from "../layout"
import { errorDialog } from "../../../components/Alert/Alert"
import { checkStartEnd, checkStates, transformData } from "../DynamicTable/hook/utils/validateFilter"
import useTabulateContractor from "../../../hooks/Contracts/useTabulateContractors"
import { useNavigate } from "react-router-dom"

const useContractorLogic = () => {

    const { getContractors } = useTabulateContractor()

    const [statesData, setStatesData] = useState(null)
    const [loading, setLoading] = useState(false)

    const history = useNavigate()
    const store = useAppContext()

    const filter = async (values = null) => {
        setLoading(true)
        if (values || store.filterValues) {

            const statuscheckstartend = checkStartEnd(values || store.filterValues)

            if (!checkStates(values || store.filterValues)) {
                errorDialog("Debe seleccionar por lo menos un estado")
            } else if (statuscheckstartend !== 'true') {
                errorDialog(statuscheckstartend)
            } else {
                const dataFilter = transformData(values || store.filterValues)
                const states = await getContractors(dataFilter.filter)
                setStatesData({ ...states })

                if (store.open) {
                    store.handleClose()
                }
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (store.showSection) {
            const show = store.showSection.contractors

            if (show === 'T' && show) {
                if (store.filterValues && store.fields?.length) {
                    filter()
                }
            } else if (show === 'F') {
                history('/')
            }
        }
    }, [store.fields, store.showSection])

    return {
        statesData,
        loading,
        filter
    }
}

export default useContractorLogic
