import { useEffect, useState } from "react"
import useDynamicTable from "../../../../hooks/Tramites/dynamicTable.js/dynamicTable"
import { useAppContext } from "../../layout"
import { getFilterValues } from "../../utils/state"
import alertify from "alertifyjs"
import { confirmDialog, errorDialog, successDialog } from "../../../../components/Alert/Alert"

const usePivotTableLogic = () => {

    const { getDynamicData, getDynamicConf, getFields, deleteDynamic } = useDynamicTable()

    const [data, setData] = useState(null)
    const [table, setTable] = useState('')
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    const [state, setState] = useState(null)

    const store = useAppContext()

    const [fieldOptions, setFieldOptions] = useState({
        id: null,
        numerics: [],
        fields: [],
        vals: []
    })

    const getFieldsDynamic = async () => {
        const response = await getFields()
        setFieldOptions(response)
    }

    function getTableById(valor) {
        return options?.find(elemento => elemento.value === valor)
    }

    function convertToDictionary(arr) {
        const dictionary = {}

        arr.forEach(subArray => {
            subArray.forEach(item => {
                dictionary[item] = {}
            })
        })

        return dictionary
    }

    const filterData = async (tableId) => {
        if (store.selected) {
            const filtro = getFilterValues(store)

            if (filtro) {
                if (store.open) {
                    store.handleClose()
                }

                const info = await getDynamicData(tableId ?? table, filtro)
                setData(info ?? [])
            }
        } else {
            setData([])
        }
    }

    const filter = async () => {
        setLoading(true)
        await filterData()
        setLoading(false)
    }

    const handleChange = async (event) => {

        setLoading(true)
        const id = event.target.value
        setTable(id ?? '')

        if (options && id) {
            const tableById = getTableById(id)

            if (tableById) {
                setState({
                    id: id,
                    cols: tableById.columns,
                    rows: tableById.headers,
                    vals: tableById.numerics,
                    valueFilter: convertToDictionary([tableById.columns, tableById.headers, tableById.numerics])
                })

                await filterData(id)
            } else {
                alertify.error("No se ha encontrado la tabla")
            }
        }

        setLoading(false)
    }

    const getTables = async (tableId) => {
        const optionsTable = await getDynamicConf()

        if (optionsTable.length) {
            setOptions(optionsTable)
            const defaultOpt = optionsTable[0]
            const key = defaultOpt.key ?? null
            const id = tableId || key

            setTable(id)
            const tableById = optionsTable?.find(elemento => elemento.value === id)

            if (tableById) {
                setState({
                    ...state,
                    id: id,
                    cols: tableById.columns,
                    rows: tableById.headers,
                    vals: tableById.numerics,
                    valueFilter: convertToDictionary([tableById.columns, tableById.headers, tableById.numerics])
                })
            }

            filterData(id)
        } else {
            setOptions([])
            setState(null)
            setTable('')
            setData([])
        }
    }

    const saveConfTable = async (id) => {
        setLoading(true)
        await getTables(id)
        setLoading(false)
    }

    const deleteTable = async () => {
        if (state) {
            confirmDialog(
                "Eliminar",
                "¿Desea eliminar esta tabla dinámica?"
            ).then(async (result) => {
                if (result.value) {
                    setLoading(true)
                    const tableId = state.id
                    const response = await deleteDynamic(tableId)
                    await getTables()

                    if (response) {
                        successDialog("Se ha eliminado correctamente")
                    } else {
                        errorDialog("Hemos tenido un problema al intentar eliminar la tabla")
                    }

                    setLoading(false)
                }
            })
        }
    }

    const changeState = (estado) => {
        setState(estado)
    }

    const initialConfig = async () => {
        await getTables()
        await getFieldsDynamic()
    }

    useEffect(() => {
        initialConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.selected])

    return {
        data,
        loading,
        fieldOptions,
        options,
        table,
        state,
        filter,
        handleChange,
        saveConfTable,
        deleteTable,
        changeState
    }
}

export default usePivotTableLogic
