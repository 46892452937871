import { CircleOutlined, PolylineOutlined, RectangleOutlined } from "@mui/icons-material"

const optionsMode = [
    {
        key: 'mode-rect',
        icon: <RectangleOutlined />,
        tooltip: 'Modo: Rectángulo',
        modeVisor: 'rect'
    },
    {
        key: 'mode-circle',
        icon: <CircleOutlined />,
        tooltip: 'Modo: Círculo',
        modeVisor: 'circle'
    },
    {
        key: 'mode-poly',
        icon: <PolylineOutlined />,
        tooltip: 'Modo: Polígono',
        modeVisor: 'poly'
    }
]

export default optionsMode
