import { Grid } from "@mui/joy"
import React, { useEffect, useRef, useState } from "react"
import visorData from "../../hooks/Visor/visor"
import Loading from "../../components/Loading/Loading"
import { ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import CreateArea from "./Form/CreateArea"
import UpdateArea from "./Form/UpdateArea"
import theme from "./themeTable"
import NavbarVisor from "./NavbarVisor"
import jwt_decode from "jwt-decode"
import EsLabels from "../../components/Table/EsLabels"
import Redirect from "../../hooks/redirect";
import deleteArea from "../../services/Visor/deleteArea"
import { confirmDialog, errorDialog, successDialog } from "../../components/Alert/Alert"
import { IconButton } from "@mui/material"
import { Delete } from "@mui/icons-material"

const columns = [
    {
        name: "Id",
        options: { display: false, filter: false }
    },
    {
        name: "Áreas"
    },
    {
        name: "Acciones",
        options: { display: false, filter: false }
    },
    {
        name: "Shape",
        options: { display: false }
    },
    {
        name: "Url",
        options: { display: false }
    },
    {
        name: "Target",
        options: { display: false }
    }
]

const Visor = () => {
    const [URL, setUrl] = useState(null)
    const [areas, setAreas] = useState(null)

    const { getImageVisor, getCustomAreas } = visorData()
    const [areaSelected, setAreaSelected] = useState(null)
    const [reload, setReload] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [page, setPage] = useState(0)
    const [selectedTableRow, setSelectedTableRow] = useState(null)
    const tableRef = useRef()
    const containerRef = useRef(null)

    const reloadAreaData = (_parametros, _newId) => {
        setSelectedTableRow(null)
        setReload(!reload)
        setAreaSelected('')
    }

    const newArea = () => {
        setAreaSelected('')
        setSelectedRows([])
    }

    const comeback = () => {
        const token = localStorage.getItem('data')
        const decoded = jwt_decode(token, { header: true })

        if (decoded.back) {
            window.location.replace(decoded.back)
        }
    }

    function pageConf(tableState, idsToDelete) {
        if (tableState) {
            const rowsPerPage = tableState?.rowsPerPage
            const totalRows = tableState?.data.length - idsToDelete?.length
            const totalPages = Math.ceil(totalRows / rowsPerPage)
            const tablePage = (tableState.page > (totalPages - 1) ? (totalPages - 1) : tableState.page)

            const result = tablePage >= 0 ? tablePage : 0
            setPage(result)
        }
    }

    const handleRowDelete = (rowsDeleted) => {
        const idsToDelete = rowsDeleted.data.map(d => areas[d.index])
        const deleteArray = idsToDelete?.map(obj => obj.id)

        confirmDialog(
            "Eliminar área",
            "¿Desea eliminar las áreas seleccionadas?"
        ).then(async (data) => {
            if (data.value) {
                const tableState = tableRef.current?.state
                pageConf(tableState, idsToDelete)

                deleteArea(deleteArray).then(response => {
                    if (response) {

                        reloadAreaData()
                        setSelectedRows([])

                        if (response.status === true) {
                            successDialog(response.message)
                        } else {
                            errorDialog(response.message)
                        }
                    }
                })
            }
        })
    }

    const randomAreas = () => {
        setLoading(true)
        getCustomAreas().then(response => {
            if (response) {
                setAreas(response?.areas)
                setUrl(response?.url)
                setSelectedRows([])
            }
        }).finally(() => setLoading(false))
    }

    const customButtonDelete = (selectedRows, _displayData, _setSelectedRows, _onRowsDelete) => {

        return (
            <div>
                <IconButton key="delete-btn"
                    onClick={() => handleRowDelete(selectedRows)}
                ><Delete /></IconButton>
            </div>
        )
    }

    const handleKeyDown = event => {
        if (event.key === 'Escape') {
            setAreaSelected(null)
            setSelectedTableRow('')
        }
    }

    useEffect(() => {
        containerRef.current?.focus()
    }, [])

    useEffect(() => {
        async function getImage() {
            await getImageVisor().then(response => {
                if (response) {
                    setAreas(response?.areas)
                    setUrl(response?.url)
                }
            }).finally(() => setLoading(false))
        }
        getImage()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    if (!URL || loading) {
        return <Loading />
    }

    return (
        <div ref={containerRef} tabIndex={-1} onKeyDown={handleKeyDown}  >
            <NavbarVisor
                newArea={() => newArea()}
                comeback={() => comeback()}
                randomAreas={() => randomAreas()}
                totalAreas={areas?.length}
            />

            <Grid container spacing={2}>
                <Grid xs={12} md={8}>
                    {
                        areaSelected ?
                            <UpdateArea
                                URLIMAGE={URL}
                                selectedArea={areaSelected}
                                reload={reloadAreaData}
                            /> : <CreateArea
                                URLIMAGE={URL}
                                reload={reloadAreaData}
                            />
                    }
                </Grid>

                <Grid xs={12} md={4}>
                    <ThemeProvider theme={theme}>
                        <MUIDataTable
                            data={
                                areas?.map(
                                    ({ id, name, coords, shape, url, target }) =>
                                        [id, name, coords, shape, url, target])
                            }
                            columns={columns}
                            ref={tableRef}
                            options={{
                                expandableRowsOnClick: false,
                                responsive: "standard",
                                tableBodyHeight: "auto",
                                tableBodyMaxHeight: "auto",
                                search: false,
                                download: false,
                                filter: true,
                                print: false,
                                pagination: true,
                                rowsPerPageOptions: [],
                                selectableRows: "multiple",
                                viewColumns: false,
                                textLabels: EsLabels,
                                pointerOnHover: true,
                                rowsSelected: selectedRows,
                                selectableRowsOnClick: false,
                                page: page,
                                onRowsDelete: handleRowDelete,
                                setRowProps: (row, _rowIndex) => {
                                    const id = row[0]
                                    return {
                                        style: {
                                            backgroundColor: id === selectedTableRow ? "#e4f2fe" : null,
                                            cursor: 'pointer'
                                        }
                                    }
                                },
                                onRowClick: (rowData, _rowMeta) => {
                                    setSelectedTableRow(rowData[0] ?? null)
                                    setAreaSelected(rowData)

                                    const tableState = tableRef.current?.state

                                    if (tableState) {
                                        const tablePage = tableState.page
                                        setPage(tablePage)
                                    }
                                },
                                customToolbarSelect: (selectedRows, _displayData, _setSelectedRows) => customButtonDelete(selectedRows),
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
            <Redirect />
        </div>
    )
}

export default Visor
