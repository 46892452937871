import { Button, Grid, Tooltip } from "@mui/material"
import DatePickerComp from "../DatePicker/DataPickerComp"

export const FullCardBody = ({ children }) => {

    let style = { marginTop: 2, marginBottom: 2 }

    return (
        <Grid container spacing={1} style={style}>
            <Grid item xs={12} md={12}>
                {children}
            </Grid>
        </Grid>
    )
}

const CardTitle = (props) => {
    return (
        <Tooltip title={props.tooltip}>
            <Button>{props.title}</Button>
        </Tooltip>
    )
}

FullCardBody.Title = CardTitle
FullCardBody.Calendar = DatePickerComp

export default FullCardBody