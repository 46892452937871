import { useField } from "formik";
import Select from "react-select";
import AsteriskRequired from "./Required/RequiredField";

function SelectField({ label, name, options, required = null, ...rest }) {
    const [field] = useField(name)

    const selectOptions = options.map((option) => ({
        value: option.value || option.id,
        label: option.key || option.nombre
    }))

    return (
        <div>
            <label htmlFor={name}>
                {label}
                {required && (<AsteriskRequired />)}
            </label>
            <Select
                id={name}
                name={name}
                options={selectOptions}
                value={selectOptions.find((option) => option.value === field.value)}
                onChange={(option) => field.onChange(name)(option.value)}
                onBlur={field.onBlur(name)}
                {...rest}
            />
        </div>
    )
}

export default SelectField
