import React, { useEffect, useRef, useState } from "react"
import { Grid } from "@mui/material"

import Loading from "../../components/Loading/Loading"

import Redirect from "../../hooks/redirect"
import eliminarMensaje from "../../services/Mensajes/eliminarMensaje"
import { useNavigate } from "react-router-dom"
import { confirmDialog, errorDialog, successDialog } from "../../components/Alert/Alert"
import { useAppContext } from "./MessageContext"
import useGetMessages from "../../hooks/Mensajes/useGetMessages"
import header from "./header"
import { TableConfigMensaje } from "./actions"
import MuiTable from "../../components/Table/MuiTable"
import { messageButtons } from "./buttonsTable"

export default function Mensajes() {

    const { getMessages } = useGetMessages()

    const [data, setData] = useState(null)

    const [loading, setLoading] = useState(false)
    const [estado, setEstado] = useState(false)
    const [searchValue, setSearchValue] = useState(null)
    const [page, setPage] = useState(0)
    const tableRef = useRef()

    const [statusTable, setStatusTable] = useState({
        filter: null,
        column: null
    })

    const navigate = useNavigate()
    const store = useAppContext()

    const handleLoading = ((valor) => { setLoading(valor) })
    const resetData = () => { setData(null) }

    function pageConf(tableState) {
        if (tableState) {
            const rowsPerPage = tableState?.rowsPerPage
            const totalRows = tableState?.data.length - 1
            const totalPages = Math.ceil(totalRows / rowsPerPage)
            const page = (tableState.page > (totalPages - 1) ? (totalPages - 1) : tableState.page)

            const result = page >= 0 ? page : 0
            setPage(result)
        }
    }

    const eliminar = (id) => {

        const tableState = tableRef.current?.state

        confirmDialog('Eliminar', 'Este proceso no es reversible ¿Desea continuar?')
            .then((result) => {
                if (result.value) {

                    if (tableState) {
                        pageConf(tableState)
                    }

                    handleLoading(true)
                    resetData()
                    deleteMensaje(id, setLoading, setEstado, estado, handleLoading)

                    setStatusTable({
                        ...statusTable,
                        filter: store.status,
                        column: store.columnsState
                    })
                }
            })
    }

    const editar = (datos) => {
        navigate('/update/' + datos.fldmensaje_uuid)
    }

    const navegar = () => {
        navigate("/create")
    }

    const headerButtons = messageButtons(navegar)

    const handleSearch = (value) => {
        setSearchValue(value)

        setStatusTable({
            ...statusTable,
            filter: store.status,
            column: store.columnsState
        })
    }

    const handleFilter = (action, tableState) => {

        const columns = {}

        tableState.columns.forEach((element, index) => {
            columns[index.toString()] = element.display
        })

        store.columnsState = columns
        store.filterData = tableState.filterData

        if (tableState.filterList.length > 0) {
            tableState.filterList.forEach((v, index) => {
                tableState.filterList[index] = v.filter(elemento => tableState.filterData[index].includes(elemento))
            })

            if (JSON.stringify(store.status) !== JSON.stringify(tableState.filterList)) {
                store.status = tableState.filterList

                setStatusTable({
                    ...statusTable,
                    filter: store.status,
                    column: store.columnsState
                })
            }
        }
    }

    let encabezadoTabla = TableConfigMensaje(header, statusTable, editar, eliminar, 0)

    useEffect(() => {
        setTimeout(() => {
            getMessages().then(result => {
                setData(result)
            })
        }, 100)
    }, [estado])

    const options = {
        expandableRowsOnClick: true,
        responsive: 'simple',
        searchPlaceholder: "Buscar",
        tableBodyHeight: "estandar",
        tableBodyMaxHeight: "estandar",
        filterType: "dropdown",
        download: false,
        filter: true,
        print: false,
        selectableRows: "none",
        onSearchChange: handleSearch,
        onTableChange: handleFilter,
        searchText: searchValue,
        page: page,
        pagination: data !== null && data.length > 0 ? true : false,
        customToolbar: headerButtons,
    }

    if (loading === true || data === null) {
        return (
            <Loading />
        )
    }

    return (
        <div>
            <Grid style={{ marginLeft: 1, marginRight: 1, marginTop: '4em' }}>
                <MuiTable
                    reference={tableRef}
                    title="Mensajes"
                    header={encabezadoTabla}
                    data={data === null || loading === true ? [] : data}
                    options={options}
                />
                <Redirect></Redirect>
            </Grid>
        </div>
    )
}

function deleteMensaje(id, setLoading, setEstado, estado, handleLoading) {
    eliminarMensaje(id).then((response) => {
        setLoading(true)

        if (response.status === true) {
            setEstado(!estado)
            handleLoading(false)
            successDialog("Elemento eliminado correctamente")
        } else {
            errorDialog(response.message)
        }
    }).catch(error => {
        errorDialog(error)
        handleLoading(false)
    })
}