import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip';
import AddTask from '@mui/icons-material/AddTask'

export function messageButtons(navegar) {
    return () => (
        <Tooltip disableFocusListener title="Añadir Mensaje">
            <IconButton onClick={() => navegar()}>
                <AddTask />
            </IconButton>
        </Tooltip>
    )
}