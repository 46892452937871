import { Field } from "formik"

function Select(props) {
    const { label, name, options, ...rest } = props

    return (
        <div>
            <label htmlFor={name}>{label}</label>
            <Field as='select' id={name} name={name} {...rest}>
                {options.map(option => {

                    const value = option.value || option.id
                    const key = option.key || option.nombre

                    return (
                        <option key={value} value={value}>
                            {key}
                        </option>
                    )
                })}
            </Field>
            <br />
        </div>
    )
}

export default Select
