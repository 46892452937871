import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import defaultConfig from './Config/config'
import ChartContainerResponsive from './ChartContainerResponsive'

require("highcharts/modules/heatmap")(Highcharts)
require("highcharts/modules/accessibility")(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const getOptions = (data, xAxisCategories, yAxisCategories, title) => {
    const options = {
        chart: {
            type: 'heatmap',
            marginTop: 40,
            marginBottom: 80,
            plotBorderWidth: 1
        },
        title: {
            text: title,
            style: {
                fontSize: '18px'
            }
        },
        xAxis: {
            categories: xAxisCategories
        },
        yAxis: {
            categories: yAxisCategories,
            title: null,
            reversed: true
        },
        colorAxis: {
            min: 0,
            stops: [
                [0, '#fa6f2e'],
                [0.25, '#fec200'],
                [0.5, '#FFFf33'],
                [0.75, '#a9f972'],
                [1, '#94ee2a']
            ]
        },
        tooltip: {
            formatter: function () {
                const xElement = this.series.xAxis.categories[this.point.x]
                const yElement = this.series.yAxis.categories[this.point.y]
                return `<b>${this.point.value}</b><br/>${xElement} - ${yElement}`;
            }
        },
        series: [
            {
                name: 'heatmap',
                borderWidth: 1,
                data: data,
                dataLabels: {
                    enabled: true,
                    color: '#FFFFFF',
                    style: {
                        fontSize: '10px'
                    },
                    formatter: function () {
                        const value = this.point.value
                        return (Number.isInteger(value) ? value.toString() : value.toFixed(2)) + "%"
                    }
                }
            }
        ]
    }

    const config = Object.assign({}, options, defaultConfig)
    return config ?? {}
}

const HeatmapComp = ({ data, xAxisCategories, yAxisCategories, title = 'Heatmap' }) => {
    return (
        <ChartContainerResponsive>
            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions(data, xAxisCategories, yAxisCategories, title)}
            />
        </ChartContainerResponsive>
    )
}

export default HeatmapComp
