import { Icon } from "leaflet"

export const customIcon = new Icon({
    iconUrl: "https://res.cloudinary.com/donkx1clf/image/upload/v1684418850/maps-and-flags_ygmyib.png",
    iconSize: [30, 30]
})

export const specialIcon = new Icon({
    iconUrl: "https://res.cloudinary.com/donkx1clf/image/upload/v1684174022/placeholder_vjiwhp.png",
    iconSize: [38, 38]
})

export const mapStyle = {
    height: "60vh",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "0rem !important"
}

export const maxBounds = [  // África
    [-40.3655, -27.0703], // Suroeste
    [38.5482, 63.9844], // Noreste
    // América
    [83.5938, -178.3984], // Noreste
    [-56.9449, -139.5703], // Suroeste
    // Asia
    [77.9159, 4.4141], // Noreste
    [-11.8674, 73.2813], // Suroeste
    // Europa
    [72.5808, -27.0703], // Noreste
    [30.2970, -25.4883], // Suroeste
    // Oceanía
    [-47.7541, 108.8086], // Suroeste
    [-8.5810, 179.1211] // Noreste
]
