export const getToken = () => {
  if (
    process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV === "development"
  ) {
    const instance = localStorage.getItem("instance");
    const token = localStorage.getItem("tkreact_" + instance);
    return token ? token : null;
  }

  return null;
};

export const getEndpoint = () => {
  const prodEndpoint =
    "https://l1dei07x11.execute-api.us-east-1.amazonaws.com/prod/";

  if (process.env.NODE_ENV === "development") {
    return "http://10.0.0.160:5000/";
  } else {
    if (process.env.NODE_ENV === "production") {
      return prodEndpoint;
    }
  }

  return "";
};

export const getAuthEndpoint = () => {
  const defaultEndpoint = "https://sgi.almeraim.com/sgi/api/v4/me";

  if (process.env.NODE_ENV === "development") {
    return "http://10.0.0.160/sgi/api/v4/me";
  } else {
    if (process.env.NODE_ENV === "production") {
      const route = localStorage.getItem("route");
      const api = route ? localStorage.getItem("api_" + route) : null;
      return api ? api + "me" : defaultEndpoint;
    }
  }

  return "";
};
