import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

function MoveNewRowBox() {
    return (<div style={{
        width: "100%",
        border: "2px dotted #FFC107",
        padding: "18px",
        borderRadius: "8px",
        textAlign: "center",
        fontSize: "20px",
        backgroundColor: "#FFE57F",
        color: "black"
    }}>
        Mover a nueva fila <br />
        <AddCircleOutlineIcon />
    </div>)
}

export default MoveNewRowBox
