import React from 'react'
import { Tooltip } from '@mui/material'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import styled from 'styled-components'

const HelpWarningIcon = styled(HelpOutlineRoundedIcon)`
  color: #f44336;
  font-size: 24px;
  font-weight: bold;
`;

const CustomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TooltipWarning = ({ label, message }) => {
    return (
        <Tooltip
            title={message}
            placement='right'
            arrow>
            <CustomContainer>
                <span style={{ marginRight: '4px', fontWeight: "bold" }}>{label}</span>
                <HelpWarningIcon />
            </CustomContainer>
        </Tooltip>
    )
}

export default TooltipWarning
