function parseIP(ipAddress) {
    const parts = ipAddress.split('.')
    if (parts.length === 4) {
        return { type: 'ipv4', parts: parts.map(part => parseInt(part)) }
    } else {
        return { type: 'ipv6', parts: ipAddress.split(':') }
    }
}

function isIpInRangeIPv4(ipParts, cidrParts, maskBits) {
    const mask = 0xFFFFFFFF << (32 - maskBits)
    const ipNumber = (ipParts[0] << 24) +
        (ipParts[1] << 16) +
        (ipParts[2] << 8) +
        ipParts[3]
    const cidrIpNumber = (cidrParts[0] << 24) +
        (cidrParts[1] << 16) +
        (cidrParts[2] << 8) +
        cidrParts[3]
    return (ipNumber & mask) === (cidrIpNumber & mask)
}

function isIpInRangeIPv6(ipParts, cidrParts, maskBits) {
    for (let i = 0; i < Math.min(ipParts.length, cidrParts.length); i++) {
        const mask = (0xFFFF >> maskBits) << maskBits
        if ((ipParts[i] & mask) !== (cidrParts[i] & mask)) {
            return false
        }
    }
    return true
}

export function isIpInRange(ipAddress, cidrRange) {
    const [cidrIp, cidrPrefix] = cidrRange.split('/')
    const ip = parseIP(ipAddress)
    const cidr = parseIP(cidrIp)

    if (ip.type === cidr.type) {
        if (ip.type === 'ipv4') {
            const maskBits = 32 - parseInt(cidrPrefix)
            return isIpInRangeIPv4(ip.parts, cidr.parts, maskBits)
        } else {
            const maskBits = Math.min(4, Math.max(0, 4 - Math.ceil(parseInt(cidrPrefix) / 16)))
            return isIpInRangeIPv6(ip.parts.map(part => parseInt(part, 16)), cidr.parts.map(part => parseInt(part, 16)), maskBits);
        }
    }

    return false
}
