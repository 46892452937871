import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import defaultConfig from './Config/config'
import ChartContainerResponsive from './ChartContainerResponsive'

require("highcharts/modules/accessibility")(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const categorias = (data) => {
    const categories = []

    data.forEach(element => {
        categories.push(element.name)
    })

    return categories
}

const getOptions = (data, title, type) => {

    const total = data.reduce((sum, point) => sum + point.y, 0)

    const copia = [...data]
    let final = null

    if (type === "pie") {
        const newArray = copia.map((innerArray) => {
            const copy = [...innerArray]

            const temp = copy[1]
            copy[1] = copy[2]
            copy[2] = temp

            return copy
        })

        final = newArray
    } else {
        final = copia
    }

    const filteredData = final?.filter(item => {
        const roundedValue = parseFloat(item.y?.toFixed(2) ?? null)
        return roundedValue !== 0 && item.y !== null
    })

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: type
        },
        title: {
            text: title
        },
        xAxis: {
            categories: categorias(filteredData)
        },
        yAxis: {
            title: null
        },
        plotOptions: {
            column: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        const formattedY = this.y !== null && this.y % 1 !== 0 ? this.y.toFixed(2) : this.y;
                        const percentage = total !== 0 ? ((this.y / total) * 100).toFixed(2) : 0;
                        return `${formattedY} (${percentage}%)`;
                    }
                }
            }
        },
        tooltip: {
            pointFormatter: function () {
                const formattedNumber = Highcharts.numberFormat(this.y, this.y % 1 !== 0 ? 2 : 0, ',', '.')
                const formattedPercentage = Highcharts.numberFormat(this.percentage, this.percentage % 1 !== 0 ? 2 : 0)

                const columnMsg = ` <b>${formattedNumber}</b>`
                const pieMsg = `<b>${formattedPercentage}%</b>`

                const value = type === "column" ? columnMsg : pieMsg
                return `<span style="color:${this.color}">\u25CF</span>${value}<br/>`
            }
        },
        series: [
            {
                name: null,
                colorByPoint: true,
                showInLegend: type === "pie",
                data: filteredData
            }
        ]
    }

    return Object.assign({}, options, defaultConfig)
}

const BarChartComp = ({ data, title = "grafico" }) => {

    return (
        <ChartContainerResponsive>
            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions(data, title, 'column')}
            />
        </ChartContainerResponsive>
    )
}

export default BarChartComp
