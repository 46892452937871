import getMensajes from "../../services/Mensajes/getMensajes"

const useGetMessages = () => {
    const getMessages = async () => {
        try {
            const response = await getMensajes()

            if (response.data.length > 0) {
                const datosTabla = []
                let datos = []

                datos = response.data

                datos.forEach(element => {
                    const dato = [
                        element.id,
                        element.name,
                        element.code,
                        element.active === "T" ? "Activa" : "Inactiva"]
                    datosTabla.push(dato)
                })

                return datosTabla

            } else {
                return []
            }

        } catch (error) {
            return []
        }
    }

    return { getMessages }
}

export default useGetMessages