import { useState } from 'react'
import { useAppContext } from '../layout'
import useGetAttributes from '../../../hooks/Tramites/attributes'
import { getFilterValues } from '../utils/state'

const useAttributesLogic = () => {
    const store = useAppContext()
    const { getAttributes } = useGetAttributes()

    const [attribute, setAttributes] = useState('')
    const [year, setYear] = useState('')
    const [data, setData] = useState({ type: null, data: null, total_values: null })
    const [loading, setLoading] = useState(false)

    const processData = (id_attribute, type, filter) => {
        setLoading(true)

        getAttributes(id_attribute, type, filter)
            .then((result) => {
                setData({
                    type: result ? type : 'no-data',
                    data: result ? result.data : [],
                    total_values: result?.total_values ? result.total_values : 0
                })
            })
            .finally(() => setLoading(false))
    }

    const handleChangeYear = (event) => {
        setYear(event.target.value)

        const id_attribute = attribute.split('*')

        if (store.selected && id_attribute) {
            const filter = getFilterValues(store) ?? {}
            filter.year = event.target.value
            processData(id_attribute[0], id_attribute[1], filter)
        }
    }

    const handleChange = (event) => {
        setYear('')
        setAttributes(event.target.value)

        const id_attribute = event.target.value.split('*')

        if (store.selected && id_attribute.length) {
            const filter = getFilterValues(store)
            processData(id_attribute[0], id_attribute[1], filter)
        }
    }

    const filtrar = () => {
        setYear('')

        if (store.selected) {
            const filter = getFilterValues(store)

            if (filter) {
                if (store.open) {
                    store.handleClose()
                }

                const id_attribute = attribute?.split('*')
                processData(id_attribute[0], id_attribute[1], filter)
            }
        }
    }

    return {
        attribute,
        handleChange,
        year,
        handleChangeYear,
        data,
        loading,
        filtrar
    }
}

export default useAttributesLogic
