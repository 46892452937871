import alertify from "alertifyjs";
import { confirmDialog } from "../../../components/Alert/Alert";
import useGetRules from "../../../hooks/Rules/useGetRules";
import eliminarRule from "../../../services/Reglas/eliminarRule";
import { useAppContext } from "../RulesContext";
import { useEffect, useState } from "react";
import { isIpInRange } from "../../../utils/IpValidate";

function deleteRule(id, setLoading, setEstado, estado, handleLoading) {
  eliminarRule(id)
    .then((response) => {
      setLoading(true);
      if (response.statusCode === 200) {
        setEstado(!estado);
        handleLoading(false);
        alertify.success(response.message);
      } else {
        alertify.error(response.message);
      }
    })
    .catch((error) => {
      alertify.error(error);
    });
}

const useIpsTable = () => {
  const { getRules } = useGetRules();

  const [title] = useState(localStorage.getItem("application"));
  const [data, setData] = useState(null);
  const [datos, setDatos] = useState(null);
  const [loading, setLoading] = useState(false);
  const [estado, setEstado] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOption, setModalOption] = useState("ip");
  const [searchValue, setSearchValue] = useState(null);

  const [error, setError] = useState(null);

  const [statusTable, setStatusTable] = useState({
    filter: null,
    column: null,
  });

  const store = useAppContext();

  const handleOpen = (modalOpt) => {
    setModalOption(modalOpt);
    setDatos(null);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleLoading = (valor) => {
    setLoading(valor);
  };

  const resetData = () => {
    setData(null);
  };

  const eliminar = (id) => {
    let saveStatus = Object.assign({}, store.status);
    setStatusTable({
      ...statusTable,
      filter: saveStatus,
      column: store.columnsState,
    });

    confirmDialog(
      "Eliminar",
      "Este proceso no es reversible ¿Desea continuar?"
    ).then((result) => {
      if (result.value) {
        handleLoading(true);
        resetData();
        deleteRule(id, setLoading, setEstado, estado, handleLoading);
      }
    });
  };

  const editar = (values) => {
    setModalOption("updateip");
    setDatos(values);
    setOpen(true);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleChangeTable = (_action, tableState) => {
    const columns = {};

    tableState.columns.forEach((element, index) => {
      columns[index.toString()] = element.display;
    });

    store.columnsState = columns;

    store.filterData = tableState.filterData;

    if (tableState.filterList.length > 0) {
      tableState.filterList.forEach((v, index) => {
        tableState.filterList[index] = v.filter((elemento) =>
          tableState.filterData[index].includes(elemento)
        );
      });

      if (
        JSON.stringify(store.status) !== JSON.stringify(tableState.filterList)
      ) {
        store.status = tableState.filterList;

        setStatusTable({
          ...statusTable,
          filter: store.status,
          column: store.columnsState,
        });
      }
    }
  };

  const changeState = () => {
    setEstado(!estado);
  };

  const isEnableIp = () => {
    const localip = localStorage.getItem("ip") ?? null;

    if (localip) {
      return data?.some((subarray) => isIpInRange(localip, subarray[0]));
    }

    return false;
  };

  useEffect(() => {
    setTimeout(() => {
      getRules().then((result) => {
        if (result === null) {
          setError("Hemos tenido problemas para obtener la data");
        }
        setData(result);
      });
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  return {
    title,
    data,
    datos,
    loading,
    open,
    modalOption,
    searchValue,
    error,
    statusTable,
    handleOpen,
    handleClose,
    handleLoading,
    resetData,
    eliminar,
    editar,
    handleSearch,
    handleChangeTable,
    changeState,
    isEnableIp,
  };
};

export default useIpsTable;
