import { decimalFormatInteger, nameCustom } from '../Format/format'

export const columns = [
    {
        name: "Nombre",
        options: {
            customBodyRender: nameCustom
        }
    },
    {
        name: "Cantidad",
        options: {
            customBodyRender: decimalFormatInteger
        }
    },
    "%"
]
