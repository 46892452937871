import { AddBox } from "@mui/icons-material"
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import AddTask from '@mui/icons-material/AddTask'

export function ruleButtons(handleOpen, statusMassive = false) {
    return () => (
        <>
            <Tooltip disableFocusListener title="Añadir regla">
                <IconButton onClick={() => handleOpen("ip")}>
                    <AddTask />
                </IconButton>
            </Tooltip>

            {
                statusMassive && (
                    <Tooltip disableFocusListener title="Añadir regla masivas">
                        <IconButton onClick={() => handleOpen("ipmasivas")}>
                            <AddBox />
                        </IconButton>
                    </Tooltip>
                )
            }
        </>
    )
}
