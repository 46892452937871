import { errorDialog, successDialog, warningDialog } from "../../components/Alert/Alert"
import getLayout from "../../services/Layout/Layout"
import saveLayout from "../../services/Layout/SaveLayout"

const useLayout = () => {

    const calculateMissingFields = (data) => {
        const { json, fields } = data

        const existingFields = json.disposition?.flatMap((d) => d.fields)
        const missingFields = fields
            .filter((field) => !existingFields?.includes(field.id))
            .map((field) => field.id)

        const missingDict = {
            col: "missing",
            fields: missingFields ?? []
        }

        if (json.disposition) {
            json.disposition?.push(missingDict)
        } else {
            json.disposition = []
            json.disposition?.push(missingDict)
        }

        return data
    }

    const getSurveyData = async (secureid = null, securemd = null) => {
        const response = await getLayout(secureid, securemd)

        if (response?.status) {

            const missing = calculateMissingFields(response.data)
            const disposition = missing?.json?.disposition
            const options = missing?.fields

            const updatedData = options?.map(item => {
                if (item.type === 'smu' || item.type === "smm") {
                    const updatedItems = item.items.map(option => ({
                        value: option.id,
                        label: option.name
                    }))

                    return {
                        ...item,
                        items: updatedItems
                    }
                }

                return item
            })

            const modifiedData = updatedData?.map((item) => {
                const { class: classType, ...rest } = item
                return { ...rest, classType }
            })

            return {
                disposition: disposition,
                options: modifiedData
            }
        }

        return null
    }

    const saveSurvey = async (data, secureid = null, securemd = null) => {

        let warningShown = false

        const filteredList = data?.filter((item) => {
            if (item.col !== 'missing' && item.fields.length === 0 && !warningShown) {
                warningShown = true
            }
            return item.col !== 'missing' && item.fields.length > 0
        }).map((item) => ({
            ...item,
            col: item.fields.length.toString()
        }))

        const survey = {
            json: { disposition: filteredList }
        }

        const response = await saveLayout(survey, secureid, securemd)

        if (warningShown) {
            warningDialog("Las filas sin elementos no se almacenan. Así que no las veras en el producto final.")
        }

        if (response?.status) {
            successDialog("Se ha guardado exitosamente la disposición.")

            const message = localStorage.getItem("msg") ?? undefined
            if (message) {
                let msg = { funcion: 'recargar' }
                window.opener.postMessage(msg, message)
            }
        } else {
            errorDialog("Hemos tenido problemas para guardar la disposición.")
        }
    }

    return { getSurveyData, saveSurvey }
}

export default useLayout
