import styled from 'styled-components'
import QRCode from 'qrcode.react'

const StyledQRCode = styled(QRCode)`
  padding: 2px;
  width: 30em;
  height: auto;
  @media (max-width: 550px) {
    width: 20em;
  };
  @media (max-width: 325px) {
    width: 15em;
  };
`;

export default StyledQRCode
