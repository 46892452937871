import React, { useState, createContext, useContext, useMemo } from "react"
import { ProSidebarProvider } from "react-pro-sidebar"
import MyProSidebar from "./MyProSidebar"

const SidebarContext = createContext({})

export const MyProSidebarProvider = ({ children, filter }) => {
    const [sidebarRTL, setSidebarRTL] = useState(false)
    const [sidebarBackgroundColor, setSidebarBackgroundColor] = useState(undefined)
    const [sidebarImage, setSidebarImage] = useState(undefined)

    const sidebarContextValue = useMemo(() => ({
        sidebarBackgroundColor,
        setSidebarBackgroundColor,
        sidebarImage,
        setSidebarImage,
        sidebarRTL,
        setSidebarRTL
    }), [sidebarBackgroundColor, setSidebarBackgroundColor, sidebarImage, setSidebarImage, sidebarRTL, setSidebarRTL]);

    return (
        <ProSidebarProvider>
            <SidebarContext.Provider
                value={sidebarContextValue}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: sidebarRTL ? "row-reverse" : "row"
                    }}
                >
                    <MyProSidebar filter={filter} />
                    {children}
                </div>
            </SidebarContext.Provider>
        </ProSidebarProvider>
    )
}

export const useSidebarContext = () => useContext(SidebarContext)
