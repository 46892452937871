import { getAuthEndpoint, getEndpoint, getToken } from './credentials'

const getMensajes = async () => {

    const endpoint = getEndpoint() + "messages"
    const me = getAuthEndpoint()

    try {
        const config = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken(),
                "Permission": me
            }
        }

        const response = await fetch(endpoint, config)
        const json = await response.json()
        return json ?? undefined
    } catch (error) {
        throw Error('Se ha producido un error durante el fetch')
    }
}

export default getMensajes
