import { Fragment } from "react";
import FilterContract from "../FilterContract";
import BasicTable from "../../../components/Table/BasicTable";
import BarColumnChart from "../../../components/Chart/BarColumn";
import Loading from "../../../components/Loading/Loading";
import useContractLogic from "./useContractsLogic";
import NotData from "../../../components/Chart/NoData/NotData";
import ErrorArchivo from "../../../components/404/ErrorArchivo";
import animationData from "../../../components/404/Nodata.json";
import { Alert } from "@mui/material";

function isEmptyDict(obj) {
  if (obj) {
    return Object.keys(obj).length === 0;
  }

  return true;
}

const Contracts = () => {
  const { final, statesData, loading, filter } = useContractLogic();

  if (loading || !statesData.table) {
    if (final) {
      return <Loading />;
    } else {
      return (
        <div align="center">
          <ErrorArchivo animationData={animationData} />
          <Alert
            variant="filled"
            align="center"
            sx={{ width: 300 }}
            severity="error"
          >
            No se encontró información
          </Alert>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <FilterContract filter={filter} />

      {isEmptyDict(statesData?.table) && (
        <div align="center">
          <ErrorArchivo animationData={animationData} />
          <Alert
            variant="filled"
            align="center"
            sx={{ width: 300 }}
            severity="error"
          >
            No se encontró información
          </Alert>
        </div>
      )}

      {!isEmptyDict(statesData?.table) && (
        <div>
          <BarColumnChart
            title="Contratos por estado"
            data={statesData.chart}
            viewTable={false}
            columsOptions={[
              {
                label: "Estado",
                value: "name",
              },
              {
                label: "Cantidad",
                value: "y",
              },
              {
                label: "%",
                value: "percent",
              },
            ]}
            defaultType="pie"
          />

          <BasicTable
            columns={statesData.table.header}
            data={statesData.table.body}
            Footer={statesData.table.footer}
          />
        </div>
      )}
    </Fragment>
  );
};

export default Contracts;
