import { useState } from "react"
import Select from 'react-select'

const SelectSMM = ({
    options,
    onChange,
    value,
    placeholder = null
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleMenuOpen = () => {
        setIsOpen(true)
    }

    const handleMenuClose = () => {
        setIsOpen(false)
    }

    const handleOnChange = (selectedOptions) => {
        const isAllSelected = selectedOptions.some((option) => option.value === "all");
        const updatedValue = isAllSelected ? options.filter((option) => option.value !== "all") : selectedOptions
        onChange(updatedValue)
    }

    const optionsWithSelectAll = [
        { value: "all", label: "Todos" },
        ...options
    ]

    return (
        <Select
            options={optionsWithSelectAll}
            isMulti
            onChange={handleOnChange}
            value={value}
            menuIsOpen={isOpen}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            closeMenuOnSelect={false}
            placeholder={placeholder}
        />
    )
}

export default SelectSMM
