import getVisorData from "../../services/Visor/Visor"
import customAreas from "../../services/Visor/customArea"

function VisorData() {

    const getInfoAreas = (areas) => {

        const areasFinals = []

        areas?.forEach(element => {
            let coords = element?.coordinates.split(",")

            coords = coords?.map(parseFloat)

            const id = element?.id
            const name = element?.name
            const url = element?.url
            const target = element?.target
            const shape = element?.shape

            const result = {
                id: id,
                name: name,
                url: url,
                target: target,
                coords: coords,
                shape: shape
            }

            areasFinals.push(result)
        })

        return areasFinals
    }


    const getImageVisor = async () => {
        try {
            const response = await getVisorData()

            if (response.data) {
                let areasFinals = []
                if (response.data.areas) {
                    const areas = response.data.areas
                    areasFinals = getInfoAreas(areas)
                }
                return { url: response.data?.url, areas: areasFinals }
            } else {
                return response
            }
        } catch (error) {
            console.log(error)
        }
    }


    const getCustomAreas = async () => {
        try {
            const response = await customAreas()

            if (response.data) {
                let areasFinals = []
                if (response.data.areas) {
                    const areas = response.data.areas
                    areasFinals = getInfoAreas(areas)
                }
                return { url: response.data?.url, areas: areasFinals }
            } else {
                return response
            }
        } catch (error) {
            console.log(error)
        }
    }

    return { getImageVisor, getCustomAreas }
}

export default VisorData