import { LayersControl, MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet"
import MarkerClusterGroup from "react-leaflet-markercluster"
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import { customIcon, mapStyle, maxBounds, specialIcon } from "./data/map"
import { useEffect, useRef, useState, } from "react"
import { FullscreenControl } from "react-leaflet-fullscreen"
import 'leaflet.heat/dist/leaflet-heat.js'
import "react-leaflet-fullscreen/dist/styles.css"
import L from "leaflet"
import Control from 'react-leaflet-custom-control'
import { Button, Tooltip } from "@mui/material"
import HeatPumpIcon from '@mui/icons-material/HeatPump';

const MapLeaf = (props) => {

    const {
        center,
        mapRef,
        markers,
        handlePopupClick,
        coordUbicated,
        popupData,
        recenter
    } = props

    const heatLayerRef = useRef(null)
    const [isHeatmapEnabled, setIsHeatmapEnabled] = useState(false)


    const renderPopup = (popupData) => {
        if (popupData) {

            if (popupData?.html === true) {
                return <div dangerouslySetInnerHTML={{
                    __html: popupData.data
                }} />
            }

            else if (popupData?.html === false) {
                return <span>{popupData.data ?? ""}</span>
            }
        }

        return <div dangerouslySetInnerHTML={{
            __html: '<span>Cargando ...</span>'
        }} />
    }

    const RecenterAutomatically = ({ coord }) => {
        const map = useMap()
        useEffect(() => {
            recenter(map, coord)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [coord])
        return null
    }

    const markersRender = (markers) => {
        return markers?.map(marker => (
            <Marker
                key={marker.id}
                position={marker.geocode}
                icon={marker.id === coordUbicated ? specialIcon : customIcon}
                eventHandlers={{
                    click: () => {
                        handlePopupClick(marker.id)
                    }
                }}
            >
                <Popup
                    closeOnClick={false}
                    open={true}
                >
                    <div style={{ maxWidth: "auto", minWidth: "30em" }}>
                        {renderPopup(popupData)}
                    </div>
                </Popup>
            </Marker>
        ))
    }

    const fullscreenControlOptions = {
        title: 'Pantalla completa',
        titleCancel: 'Salir de pantalla completa',
        forceSeparateButton: true
    }

    const getConcurrenceMarker = async (markers) => {

        const groupedData = await markers?.reduce((result, item) => {
            const [lat, lng] = item.geocode;
            const key = `${lat},${lng}`

            if (result[key]) {
                result[key].count++
            } else {
                result[key] = {
                    geocode: [lat, lng],
                    count: 1
                }
            }

            return result
        }, {})

        return Object.values(groupedData).map(({ geocode, count }) => [...geocode, count])
    }

    const addHeatLayer = async () => {
        if (markers) {
            const result = await getConcurrenceMarker(markers)
            if (mapRef.current && result) {
                const map = mapRef.current

                if (heatLayerRef.current) {
                    removeHeatLayer()
                }

                const heatLayer = L.heatLayer(result, {
                    radius: 25,
                    blur: 30,
                    maxZoom: 5,
                    willReadFrequently: true,
                }).addTo(map)

                heatLayerRef.current = heatLayer
            }
        }
    };

    const removeHeatLayer = () => {
        if (heatLayerRef.current) {
            const map = mapRef.current
            map.removeLayer(heatLayerRef.current)
            heatLayerRef.current = null
        }
    }

    const handleHeatmapToggle = () => {
        setIsHeatmapEnabled(!isHeatmapEnabled)
    }

    useEffect(() => {
        if (mapRef.current) {
            if (isHeatmapEnabled) {
                addHeatLayer();
            } else {
                removeHeatLayer();
            }

            return () => { removeHeatLayer() }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHeatmapEnabled])

    return (
        <MapContainer
            center={center}
            zoom={5}
            minZoom={4}
            maxBounds={maxBounds}
            style={mapStyle}
            whenCreated={mapInstance => mapRef.current = mapInstance}
        >

            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                noWrap={true}
            />

            <Control prepend position='topright'>
                <Tooltip placement="left" title="Heatmap">
                    <Button
                        color={isHeatmapEnabled ? "primary" : "inherit"}
                        onClick={() => handleHeatmapToggle()}
                        variant="contained"
                    >
                        <HeatPumpIcon />
                    </Button>
                </Tooltip>
            </Control>

            <LayersControl position="topright">
                <LayersControl.Overlay name="Coordenadas" checked>
                    <MarkerClusterGroup>
                        {markersRender(markers)}
                    </MarkerClusterGroup>
                </LayersControl.Overlay>
            </LayersControl>

            <FullscreenControl
                {...fullscreenControlOptions}
            />
            <RecenterAutomatically coord={center} />
        </MapContainer>
    )
}

export default MapLeaf
