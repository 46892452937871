import React, { useRef, useState } from "react"
import html2canvas from 'html2canvas'

import {
    useTableInstance,
    useFilters,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel
} from "@tanstack/react-table"
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

const BasicExpansibleTable = ({
    table,
    defaultData,
    defaultColumns,
    generateBackgroundColor,
    downloadXLSX,
    title = ""
}) => {

    const [data] = useState([...defaultData])
    const [columns] = useState([...defaultColumns])
    const [expanded, setExpanded] = useState(true)
    const [sorting, setSorting] = useState([])
    const [columnVisibility, setColumnVisibility] = useState({})
    const tableRef = useRef(null)

    const instance = useTableInstance(table, {
        data,
        columns,
        useFilters,
        state: {
            expanded: expanded,
            sorting: sorting,
            columnVisibility: columnVisibility
        },
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        onExpandedChange: setExpanded,
        getSubRows: (row) => row.children,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        onColumnVisibilityChange: setColumnVisibility
    })

    const handleDownload = () => {
        if (tableRef.current) {
            html2canvas(tableRef.current).then((canvas) => {
                const imgData = canvas.toDataURL('image/png')
                const link = document.createElement('a')
                link.href = imgData
                link.download = 'tabla.png'
                link.click()
            })
        }
    }

    return (
        <div>
            <Paper>
                <Toolbar sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 }
                }}>

                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h5"
                        id="tableTitle"
                        component="span"
                    >
                        {title}
                    </Typography>

                    <Tooltip title="Expandir">
                        <IconButton onClick={instance.getToggleAllRowsExpandedHandler()} size="large">
                            {instance.getIsAllRowsExpanded() ?
                                <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />
                            }
                        </IconButton>
                    </Tooltip>

                    {
                        data?.length ? (
                            <Tooltip title="Descargar PNG">
                                <IconButton onClick={handleDownload} size="large">
                                    <CloudDownloadIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null
                    }

                    {
                        data?.length ? (
                            <Tooltip title="Descargar XLSX">
                                <IconButton onClick={downloadXLSX} size="large">
                                    <FileDownloadIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null
                    }

                </Toolbar>

                <TableContainer>
                    <Table id="tableAreas" ref={tableRef}>
                        <TableHead>
                            {instance.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <TableCell align="center" key={header.id} colSpan={header.colSpan}>
                                            <Button
                                                style={{ color: "black", width: "100%" }}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                <span
                                                    style={{ fontSize: "14px" }}>
                                                    {header.isPlaceholder ? null : header.renderHeader()}
                                                </span>
                                                <b>{header.column.getCanFilter()}</b>

                                                {header.column.getCanSort() ? (
                                                    <>
                                                        {{
                                                            asc: <span> <ArrowUpwardIcon /> </span>,
                                                            desc: <span> <ArrowDownwardIcon /> </span>
                                                        }[header.column.getIsSorted()] ?? ""}
                                                    </>
                                                ) : null}
                                            </Button>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {instance.getRowModel().rows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.getVisibleCells().map((cell, index) => (
                                        <TableCell
                                            style={generateBackgroundColor(cell.getValue(), index)}
                                            key={cell.id}>
                                            {cell.renderCell()}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default BasicExpansibleTable
