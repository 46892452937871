import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const useQueryParamValidation = (paramsToCheck) => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const areParamsValid = paramsToCheck.every((param) =>
        queryParams.has(param)
    )

    const redirectToHomePage = () => {
        window.location.replace("/")
    }

    useEffect(() => {
        if (!areParamsValid) {
            localStorage.setItem("route", "*")
            redirectToHomePage()
        }
    }, [areParamsValid])

    return areParamsValid
}

export default useQueryParamValidation
