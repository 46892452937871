import React, { Fragment, useState } from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import { Tabs } from '@mui/material'


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    )
}

const TabAntd = ({ tabs }) => {

    const [value, setValue] = useState(0)

    const handleChange = (_event, newValue) => {
        setValue(newValue)
    }

    const filteredTabs = tabs.filter((tab) => tab.status !== false)

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="metrics"
                        variant="fullWidth"
                    >
                        {
                            filteredTabs.map((tab) => (
                                <Tab
                                    key={tab.key}
                                    label={tab.label}
                                    style={{
                                        backgroundColor: "white"
                                    }}
                                />
                            ))
                        }
                    </Tabs>
                </Box>

                {
                    filteredTabs.map((tab) => (
                        <CustomTabPanel key={tab.key} value={value} index={tab.key}>
                            {tab.content}
                        </CustomTabPanel>
                    ))
                }
            </Box>
        </Fragment>
    )
}

export default TabAntd
