import { Sort } from "@mui/icons-material"
import SpeedDialChart from "../../SpeedDial/SpeedDialChart"
import ColumnHorizontal from "./ColumnHorizontal"
import { useEffect, useState } from "react"
import { SpeedDialAction } from "@mui/material"
import MenuColumn from "../Menu/MenuColumn"
import { manera } from "../Menu/optionsOrder"
import { handleSortNonZero } from "../Sort/sortColumnFilter"
import colorChart from "../../../utils/chartColor"

const HorizontalBar = ({
    data,
    title = '',
    tooltip = null,
    yAxisTitle = null,
    legend = false,
    nameSerie = null,
    columsOptions = [],
    ...props
}) => {

    const [open, setOpen] = useState(false)
    const [dataChart, setDataChart] = useState({
        categories: [],
        data: []
    })

    const [filterOptions, setFilterOptions] = useState({
        orden: 'asc',
        column: '',
        items: '15'
    })

    const handleToggle = () => {
        setOpen(!open)
    }

    useEffect(() => {

        let info = []

        handleSortNonZero(data, filterOptions).forEach(element => {
            info.push(element)
        })

        if (info?.length) {
            const categories = info.map(item => item.name)
            const series = [
                {
                    name: nameSerie,
                    data: info,
                    colorByPoint: true,
                    colors: colorChart
                }
            ]

            setDataChart({
                categories: categories,
                data: series
            })
        }

    }, [filterOptions])

    return (
        <div>
            <MenuColumn
                open={open}
                handleToggle={handleToggle}
                manera={manera}
                columsOptions={columsOptions}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
            />

            <ColumnHorizontal
                data={dataChart}
                title={title}
                tooltip={tooltip}
                yAxisTitle={yAxisTitle}
                legend={legend}
                {...props}
            >

                <div style={{
                    position: "absolute",
                    left: "100%"
                }}>
                    <SpeedDialChart>
                        <SpeedDialAction
                            key="order_by"
                            icon={<Sort />}
                            tooltipTitle={"Ordenar"}
                            onClick={handleToggle}
                        />
                    </SpeedDialChart>
                </div>
            </ColumnHorizontal>
        </div>
    )
}

export default HorizontalBar
