import { Button, Grid, Stack, Step, StepLabel, Stepper } from '@mui/material'
import DynamicForm from './dynamicForm'
import { useState } from 'react'
import { Form, Formik } from 'formik'
import FormikControl from '../formularios/componentes/FormikControl'

const DynamicStepsForm = (props) => {
    const { fields, numerics, closeModal, saveTableConf, usePivotTableFormLogic } = props

    const {
        selectedOptions,
        handleSelectChange,
        verifyFormData,
        onSubmit
    } = usePivotTableFormLogic(closeModal, saveTableConf)

    const [activeStep, setActiveStep] = useState(0)

    const steps = [
        'Campos de la tabla',
        'Especifique el orden para las filas',
        'Especifique el orden para las columnas',
        'Especifique el orden para los campos numéricos'
    ]

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <DynamicForm
                        selectedOptions={selectedOptions}
                        handleSelectChange={handleSelectChange}
                        numerics={numerics}
                        fields={fields}
                        name={selectedOptions.name ?? ""}
                    />
                )
            case 1:
                return (
                    <FormikControl
                        key="rows"
                        name="rows"
                        control="dynamicOrder"
                        initialItems={selectedOptions.filas ?? []}
                    />
                )
            case 2:
                return (
                    <FormikControl
                        key="cols"
                        name="cols"
                        control="dynamicOrder"
                        initialItems={selectedOptions.columnas ?? []}
                    />
                )
            case 3:
                return (
                    <FormikControl
                        keys="numerics"
                        name="numericas"
                        control="dynamicOrder"
                        initialItems={selectedOptions.numericas ?? []}
                    />
                )
            default:
                return null
        }
    }

    return (
        <Grid style={{
            marginTop: "3em",
            padding: "15px",
            maxWidth: "40em"
        }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, _index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Stack>
                {activeStep === steps.length ? (
                    <div>
                        <p>¡Formulario completado!</p>
                    </div>
                ) : (
                    <div style={{ marginTop: "2em" }}>

                        <Formik
                            initialValues={{ cols: {}, rows: {} }}
                            enableReinitialize={true}
                            validateOnBlur={false}
                            validateOnChange={false}
                            onSubmit={onSubmit}
                        >
                            {({ _errors, _touched, isSubmitting, _values, _formik }) => {
                                return (
                                    <div>
                                        <Form>
                                            {getStepContent(activeStep)}

                                            <div style={{ marginTop: "2em", display: "flex", justifyContent: "space-between" }}>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                >
                                                    Atrás
                                                </Button>

                                                {
                                                    activeStep === (steps?.length - 1) && (
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            disabled={isSubmitting}
                                                            style={{
                                                                backgroundColor: "#008F39"
                                                            }}
                                                        >
                                                            Grabar
                                                        </Button>
                                                    )
                                                }

                                                {
                                                    activeStep < steps?.length - 1 && (
                                                        <Button
                                                            disabled={verifyFormData()}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleNext}>
                                                            {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                                                        </Button>
                                                    )
                                                }
                                            </div>
                                        </Form>
                                    </div>
                                )
                            }}
                        </Formik>
                    </div>
                )}
            </Stack>
        </Grid>
    )
}

export default DynamicStepsForm
