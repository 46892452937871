import React from 'react'
import { MultiSelect } from './ExtendedMultiSelect'

export default function AnimatedMulti({ field_id, options, name, selected, setSelected, required = [] }) {

    const items = []

    if (options !== null && options !== undefined) {
        const objectArray = Object.entries(options);

        objectArray.forEach(([_key, valor]) => {
            items.push({ value: valor.value, label: valor.label })
        })
    }

    return (
        <>
            <div style={{ marginTop: "6px" }}>
                {name}
                {required.includes(name) && <span style={{ color: "red", fontSize: "16px" }} >*</span>}
            </div>
            <MultiSelect field_id={field_id} options={items} value={selected} onChange={setSelected} />
        </>
    )
}
