import { useEffect, useState } from "react"
import { generateUniqueCode } from "../../../utils/keyUnique"
import useLayout from "../../../hooks/Layout/useLayout"
import { useLocation } from "react-router-dom"
import useQueryParamValidation from "../../../hooks/QueryParams/useQueryParamValidation"
import alertify from "alertifyjs"

const useSurveyLogic = () => {

    const { getSurveyData, saveSurvey } = useLayout()

    const [columns, setColumns] = useState(null)
    const [invalidDestination, setInvalidDestination] = useState(false)
    const [status, setStatus] = useState(false)
    const [isDragging, setIsDragging] = useState(false)
    const [optionsSurvey, setOptionsSurvey] = useState(null)

    const areParamsValid = useQueryParamValidation(['id', 'md'])

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const handleDragEnd = (result) => {

        setInvalidDestination(false)
        setIsDragging(false)

        if (!result.destination) {
            const lastItem = columns[columns.length - 1]
            const isLastFieldNotEmpty = lastItem.fields.length > 0

            if (isLastFieldNotEmpty) {
                const draggableId = result.draggableId.split('-')[1]

                if (draggableId) {
                    addRow(draggableId)
                    setStatus(true)
                }
            }

            return
        }

        const { source, destination } = result

        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return
        }

        const sourceDroppableId = source.droppableId
        const sourceColumnIndex = columns.findIndex(
            (column) =>
                sourceDroppableId === `column-${columns.indexOf(column)}` ||
                (sourceDroppableId === "missing-column" && column.col === "missing")
        )

        const destinationDroppableId = destination.droppableId
        const destinationColumnIndex = columns.findIndex(
            (column) =>
                destinationDroppableId === `column-${columns.indexOf(column)}` ||
                (destinationDroppableId === "missing-column" && column.col === "missing")
        )

        /**
       * prevent esc type dragging row with element
       */

        const fieldesc = columns[sourceColumnIndex].fields[source.index]
        const fieldQuestion = optionsSurvey.find((q) => q.id === fieldesc)

        if (fieldQuestion.type === "esc" &&
            columns[destinationColumnIndex].fields.length > 0 &&
            columns[destinationColumnIndex].col !== "missing"
        ) {
            alertify.error("Las preguntas de tipo Likert Scale deben colocarse en una fila independiente.")
            return
        }

        /**
         * end
         */

        const field = columns[sourceColumnIndex].fields.splice(source.index, 1)[0]
        columns[destinationColumnIndex].fields.splice(destination.index, 0, field)
        setColumns([...columns])

        if (status === false) {
            setStatus(true)
        }
    }

    const handleDragUpdate = (update) => {
        const { destination } = update
        setInvalidDestination(!destination)
    }

    const handleDragStart = () => {
        setIsDragging(true)
    }

    const addRow = (draggableId = null) => {
        const name = "new" + generateUniqueCode()
        const newRow = { col: name, fields: [] }

        if (draggableId) {
            const sourceColumnIndex = columns.findIndex((column) => column.fields.includes(draggableId))

            if (sourceColumnIndex !== -1) {
                const sourceColumn = columns[sourceColumnIndex]
                const fieldIndex = sourceColumn.fields.findIndex((field) => field === draggableId)

                if (fieldIndex !== -1) {
                    const field = sourceColumn.fields.splice(fieldIndex, 1)[0]
                    newRow.fields.push(field)
                }
            }
        }

        const newColumns = [...columns, newRow]
        setColumns(newColumns)
        return name
    }

    const deleteRow = (index) => {
        const newArray = [...columns]

        if (newArray[index].col === 'missing') {
            return
        }

        newArray.find((item) => item.col === 'missing').fields.push(...newArray[index].fields)
        newArray.splice(index, 1)
        setColumns(newArray)
        setStatus(true)
    }

    const saveLayout = () => {
        if (columns) {
            setStatus(false)
            const secureid = queryParams.get("id")
            const securemd = queryParams.get("md")
            saveSurvey(columns, secureid, securemd)
        }
    }

    const getQuestionLikertById = (id) => {
        const question = optionsSurvey?.find((q) => q.id === id)
        return question ? {
            levels: question.levels,
            items: question.items
        } : null
    }

    const moveColumnPosition = (indexColumn, direction) => {
        setColumns((prevColumns) => {
            const filteredColumns = prevColumns.filter(column => column.col !== "missing");
            const newColumns = [...filteredColumns]

            if (indexColumn >= 0 && indexColumn < newColumns.length) {
                const columnToMove = newColumns[indexColumn]
                const newPosition = direction === "up" ? indexColumn - 1 : indexColumn + 1

                newColumns.splice(indexColumn, 1)
                newColumns.splice(newPosition, 0, columnToMove)
            }

            setStatus(true)
            return [...newColumns, ...prevColumns.filter(column => column.col === "missing")]
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {

        document.title = "Disposición de campos"

        if (areParamsValid) {
            const secureid = queryParams.get("id")
            const securemd = queryParams.get("md")

            const response = await getSurveyData(secureid, securemd)

            if (response) {
                setColumns(response.disposition)
                setOptionsSurvey(response.options)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areParamsValid])


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (status) {
                event.preventDefault()
                event.returnValue = ""
            }
        }

        window.addEventListener("beforeunload", handleBeforeUnload)

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [status])

    return {
        columns,
        invalidDestination,
        status,
        optionsSurvey,
        isDragging,
        handleDragEnd,
        handleDragUpdate,
        saveLayout,
        addRow,
        deleteRow,
        getQuestionLikertById,
        moveColumnPosition,
        handleDragStart
    }
}

export default useSurveyLogic
