import React, { Fragment, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import esLocale from '@fullcalendar/core/locales/es'
import events from './Events'
import { Modal } from 'antd'

const CalendarComponent = () => {

    const calendarRef = React.useRef(null)
    const [visible, setVisible] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)

    const eventDidMount = (info) => {
        const eventElement = info.el
        eventElement.style.backgroundColor = '#ffcc00'
        eventElement.style.borderRadius = '6px'
        eventElement.style.marginBotton = '3px'
        eventElement.style.marginTop = '3px'
        eventElement.style.marginLeft = '4px'
        eventElement.style.border = '2px solid transparent'
        eventElement.style.height = '50%'
        eventElement.style.fontSize = '12px'
        eventElement.style.whiteSpace = 'normal'
        eventElement.style.wordWrap = 'break-word'
    }

    const renderDayHeader = (info) => {
        const day = info?.text.charAt(0).toUpperCase() + info.text.slice(1)

        return (
            <div>
                {day}
            </div>
        )
    }

    const formatTimeSlotLabel = (arg) => {
        const { date } = arg
        return date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })
    }

    const handleEventClick = (arg) => {
        const eventSelect = arg.event
        setSelectedEvent(eventSelect)
        setVisible(true)
    }

    const closeModal = () => {
        setVisible(false)
    }

    return (
        <Fragment>
            <div style={{ position: "relative" }}>


                <Modal
                    open={visible}
                    title={selectedEvent?.title}
                    onCancel={closeModal}
                    footer={null}
                    width={1600}
                    zIndex={10000}
                >
                    <p>Fecha de inicio: {selectedEvent?.startStr}</p>
                    <p>Fecha de fin: {selectedEvent?.endStr}</p>
                    {/* Agrega más detalles del evento aquí */}
                </Modal>

                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                    height={"80vh"}
                    initialView="dayGridMonth"
                    weekends={true}
                    events={events}
                    locales={[esLocale]}
                    locale="es"
                    ref={calendarRef}
                    eventDidMount={eventDidMount}
                    dayHeaderContent={renderDayHeader}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                    }}
                    views={{
                        timeGridWeek: {
                            type: 'timeGridWeek',
                            duration: { weeks: 1 },
                            slotDuration: '00:30:00',
                            slotLabelInterval: { hours: 1 },
                            scrollTime: '06:00:00',
                            slotMinTime: '06:00:00',
                            slotMaxTime: '20:00:00'
                        },
                        timeGridDay: {
                            type: 'timeGridDay',
                            slotDuration: '00:30:00',
                            slotLabelInterval: { hours: 1 },
                            scrollTime: '06:00:00',
                            slotMinTime: '06:00:00',
                            slotMaxTime: '20:00:00'
                        }
                    }}
                    slotLabelFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        omitZeroMinute: false,
                        meridiem: 'short'
                    }}
                    slotLabelContent={formatTimeSlotLabel}
                    dayMaxEvents={3}
                    eventTextColor="#ffffff"
                    eventClick={handleEventClick}
                />
            </div>
        </Fragment>
    )
}

export default CalendarComponent
