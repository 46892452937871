import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import defaultConfig from '../Config/config'
import ChartContainerResponsive from '../ChartContainerResponsive'

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const getDefaultTooltip = () => {
    return {
        formatter: function () {
            return `<span style="color:${this.series.color}">\u25CF</span> <b>${this.x}</b><br/><b>${this.series.name}:</b> ${this.y}`
        }
    }
}

const getOptions = (
    series,
    categories,
    title,
    customTooltip = undefined,
    yAxisTitle = undefined,
    legend = false
) => {

    const height = ((650 / 15) * categories.length)

    const options = {
        chart: {
            type: 'bar',
            height: height > 120 ? height : 120
        },
        title: {
            text: title
        },
        yAxis: {
            title: {
                text: yAxisTitle || ''
            }
        },
        xAxis: {
            categories: categories
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            },
            bar: {
                pointPadding: 0,
                dataLabels: {
                    enabled: true,
                    align: 'right',
                    color: 'black',
                    x: 25
                }
            }
        },
        tooltip: customTooltip || getDefaultTooltip(),
        series: series,
        legend: {
            enabled: legend
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 1400
                },
                chartOptions: {
                    legend: {
                        enabled: false
                    }
                }
            }]
        }
    }

    return Object.assign({}, options, defaultConfig)
};

const ColumnHorizontal = ({
    data,
    title = '',
    tooltip = null,
    yAxisTitle = null,
    legend = false,
    ...props
}) => {

    return (
        <ChartContainerResponsive>
            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions(data?.data, data?.categories, title, tooltip, yAxisTitle, legend)}
            />
            <br />
            {props.children}
        </ChartContainerResponsive>
    )
}

export default ColumnHorizontal
