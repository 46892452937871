import { Fragment } from "react"
import { useAppContext } from "../layout"
import SelectValue from "../../../components/Select/Select"
import { Stack } from "@mui/material"
import HeatmapComp from "../../../components/Chart/heatMap"
import BasicTable from "../../../components/Table/BasicTable"
import BarColumnChart from "../../../components/Chart/BarColumn"
import NotData from "../../../components/Chart/NoData/NotData"
import Filtro from "../filter"
import Loading from "../../../components/Loading/Loading"
import useMetricsLogic from "./useMetricsLogic"
import LineChartBasic from "../../../components/Chart/LineChartBasic"
import Highcharts from 'highcharts'
import TabAntd from "../../../components/Tab/TabAntd"
import Description from "../../../components/Chart/Description/Description"

function checkAllValuesAreZero(data) {
    return data?.every(item => Math.round(item?.y * 100) / 100 === 0)
}

function GeneralMetric(props) {

    const data = props.general

    if (!data) {
        return (
            <NotData
                message={"En este momento no hay información para visualizar."}
            />
        )
    }

    return (<div>
        <BasicTable
            data={data?.body ?? []}
            columns={data?.header ?? []}
            nameTable="general"
            Footer={data?.footer}
        />

        <br />

        {
            !checkAllValuesAreZero(data?.chart) && (
                <BarColumnChart
                    title="Promedio (días)"
                    data={data?.chart ?? []}
                    viewTable={false} columsOptions={[
                        { label: "Item", value: "name" },
                        { label: "Cantidad", value: "count" },
                        { label: "Promedio (días)", value: "y" },
                        { label: "Porcentaje cumplimiento", value: "percent_compliance" },
                        props.graphs?.sum_col === 'T' ? { label: "Suma", value: "sum" } : null]}
                />
            )
        }
    </div>)
}

function ComplianceMetric(props) {

    const customTooltip = {
        formatter: function () {
            const decimals = (this.y % 1 !== 0) ? 2 : 0
            const formattedNumber = Highcharts.numberFormat(this.y, decimals, ',', '.')
            const { color, series: { name }, point: { cantidad, total_cumplen, total_incumplen, category } } = this

            const totalCumplenColor = 'green'
            const totalIncumplenColor = 'red'

            return `
                <span style="color:${color}">\u25CF</span>
                <span style="font-size: 10px">${category}</span><br/>
                <span style="color:${color}">${name}:</span> <b>${formattedNumber}%</b><br/>
                Cantidad: ${cantidad}<br/>
                Cumplidos: <span style="color:${totalCumplenColor}"> ${total_cumplen}</span><br />
                Incumplidos: <span style="color:${totalIncumplenColor}"> ${total_incumplen}</span>
            `
        }
    }

    const { lineChart, years, selectyear } = props.metricas
    const selectedMap = props?.selectedMap

    if (!selectyear && !years?.length) {
        return (
            <NotData
                message={"En este momento no hay información para visualizar."}
            />
        )
    }

    return (<div>
        <LineChartBasic
            data={lineChart ?? []}
            title="Porcentaje"
            tooltip={customTooltip}
        />

        <div style={{ width: '20%' }}>
            <SelectValue
                label="Año"
                options={years ?? []}
                name="year" value={selectyear ?? ''}
                onChange={props.handleChangeYear} />
        </div>
        <br />

        {selectedMap && (
            <HeatmapComp
                key={selectedMap.name}
                data={selectedMap.data}
                xAxisCategories={selectedMap.xAxis}
                yAxisCategories={selectedMap.yAxis}
                title={selectedMap.name ?? 'heatmap'} />
        )}
    </div>)
}

const Metrics = () => {

    const {
        metricas,
        graphs,
        metrics,
        metricsOpt,
        attrOpt,
        loading,
        selectedMap,
        handleChangeYear,
        handleChange,
        filterMetrics
    } = useMetricsLogic()

    const store = useAppContext()

    if (loading) {
        return <Loading />
    }

    return (
        <Fragment>
            {
                store.selected && (
                    <Filtro filtrar={() => filterMetrics()} />
                )
            }

            <div>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <SelectValue
                        label="Métrica"
                        options={metricsOpt ?? []}
                        value={metrics.metric}
                        name="metric"
                        onChange={handleChange}
                    />

                    <SelectValue
                        label="Atributo"
                        options={attrOpt ?? []}
                        value={metrics.attribute}
                        name="attribute"
                        onChange={handleChange}
                    />
                </Stack>

                <br />

                {
                    (!metricas) && (
                        <Description message="Selecciona una métrica y un atributo, por favor." />
                    )
                }

                {
                    (metricas) && (
                        <TabAntd tabs={[
                            {
                                key: 0,
                                label: 'General',
                                content: (
                                    <GeneralMetric
                                        general={metricas.general}
                                        graphs={graphs}
                                    />
                                )
                            },
                            {
                                key: 1,
                                label: 'Cumplimiento',
                                status: graphs?.percent_col === "T",
                                content: (
                                    <ComplianceMetric
                                        selectedMap={selectedMap}
                                        metricas={metricas}
                                        handleChangeYear={handleChangeYear}
                                    />
                                )
                            }
                        ]} />
                    )
                }
            </div>
        </Fragment >
    )
}

export default Metrics
