const ContainerDisposition = ({ children }) => (
    <div style={{
        border: "2px dotted black",
        padding: "18px",
        paddingBottom: "10em",
        marginBottom: "20px",
        borderRadius: "8px",
        height: "90vh",
        overflow: "auto"
    }}>
        <div style={{
            textAlign: "center",
            marginBottom: "5px",
            fontSize: "20px"
        }}>Disposición</div>

        {children}
    </div>
)

export default ContainerDisposition
