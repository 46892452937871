import { useEffect, useRef } from "react";
import { Alert, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { Stack } from "@mui/joy";
import { visorSchema } from "../Schema/schema";
import FormikControl from "../../formularios/componentes/FormikControl";
import ButtonForm from "../../../components/Button/ButtonForm";

const VisorForm = ({ initialValues, onSubmit, urlImage }) => {
  const firstNameRef = useRef(null);
  const targetOptions = [
    { key: "Misma ventana", value: "W" },
    { key: "Ventana emergente ", value: "P" },
  ];
  const coordinatesArray = initialValues.coordinates
    ? initialValues.coordinates.split(",").map(Number)
    : [];
  const shape = initialValues.shape;

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, [firstNameRef]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={visorSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, _setFieldValue, values }) => {
        return (
          <Form>
            <FormikControl
              name="coordinates"
              control="visor"
              urlImage={urlImage}
              coordenates={coordinatesArray}
              shape={shape}
            />{" "}
            <br />
            <FormikControl
              label="Nombre"
              name="name"
              control="input"
              style={{ width: "40em", marginRight: "1em" }}
            />{" "}
            <br />
            <Grid container>
              <Stack direction="row" spacing={2}>
                <FormikControl
                  label="URL"
                  name="url"
                  style={{ width: "60em", marginRight: "1em" }}
                  control="input"
                />

                <FormikControl
                  control="select"
                  label="Target"
                  name="target"
                  style={{ width: "20em", marginRight: "1em" }}
                  options={targetOptions}
                />
              </Stack>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              justify="center"
              style={{ marginTop: "2px" }}
            >
              <Grid item xs={12} sm={12} md={12}>
                {["coordinates", "name", "url", "target"].some(
                  (field) => errors[field] && touched[field]
                ) && (
                  <Alert
                    severity="error"
                    style={{ color: "#5F2120", backgroundColor: "#FDEDED" }}
                  >
                    {["coordinates", "name", "url", "target"]
                      .map((field) => errors[field])
                      .join(" ")}
                  </Alert>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <ButtonForm
                    type="submit"
                    label="Grabar"
                    disabled={
                      isSubmitting ||
                      !Object.keys(values).some(
                        (key) => values[key] !== initialValues[key]
                      )
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VisorForm;
