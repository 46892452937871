import Loading from '../../../components/Loading/Loading'
import React, { Fragment } from "react"

import { useAppContext } from "../layout"
import Filtro from "../filter"
import BarColumnChart from '../../../components/Chart/BarColumn'
import { columnChart, columnsPie } from './tableConf'
import { procesosFilter, promedioFilter } from './confBarFilter'
import useTramitesLogic from './useTramitesLogic'
import TabAntd from '../../../components/Tab/TabAntd'

const Tramites = () => {

    const {
        dataChart,
        dataPieChart,
        loading,
        filtrar
    } = useTramitesLogic()

    const store = useAppContext()

    if (loading || !store.filtro || !store.selected) {
        return <Loading />
    }

    return (
        <Fragment>
            <Filtro filtrar={filtrar} />
            <div>
                <TabAntd
                    tabs={[
                        {
                            key: 0,
                            label: 'Tiempo promedio en cada estado (días)',
                            content: (
                                <div>
                                    {
                                        dataChart !== null && (
                                            <BarColumnChart
                                                title="Tiempo promedio en cada estado (días)"
                                                orden={["name", "y"]}
                                                data={dataChart}
                                                columnas={columnChart}
                                                columsOptions={promedioFilter}
                                            />
                                        )
                                    }
                                </div>
                            )
                        },
                        {
                            key: 1,
                            label: 'Procesos por estado',
                            content: (
                                <div>
                                    {
                                        dataPieChart !== null && (
                                            <BarColumnChart
                                                title="Procesos por estado"
                                                orden={["name", "y", "percent", "orden"]}
                                                data={dataPieChart}
                                                columnas={columnsPie}
                                                columsOptions={procesosFilter}
                                            />
                                        )
                                    }
                                </div>
                            )
                        }
                    ]}
                />
            </div>
        </Fragment>
    )
}

export default Tramites
