import BarChartBasic from "./BarChartBasic"
import BarSplineChart from "./BarSplineChart/BarSplineChart"
import Description from "./Description/Description"
import NotData from "./NoData/NotData"
import SMM from "./SMMChart/SMM"
import TagCloud from "./TagCloud"

function ChartControl(props) {
    const { control, title, data, total = 0, ...rest } = props
    const titulo = title || 'Grafica'

    switch (control) {
        case 'string': return <TagCloud data={data} title={titulo} {...rest} />
        case 'float': return <BarSplineChart data={data} title={titulo} total={total} {...rest} />
        case 'smu': return <SMM data={data} title={titulo} {...rest} />
        case 'smm': return <SMM data={data} title={titulo} {...rest} />
        case 'text': return <TagCloud data={data} title={titulo} {...rest} />
        case 'column': return <BarChartBasic data={data} title={titulo} {...rest} />
        case 'no-data': return <NotData />
        default: return <Description />
    }
}

export default ChartControl
