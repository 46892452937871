import { useEffect, useState } from "react"
import useTabulateArea from "../../../hooks/Contracts/useTabulateArea"
import { useAppContext } from "../layout"
import { createTable } from "@tanstack/react-table"
import chroma from "chroma-js"
import { checkStartEnd, checkStates, transformData } from "../DynamicTable/hook/utils/validateFilter"
import { errorDialog } from "../../../components/Alert/Alert"

const useAreaLogic = () => {

    const {
        getTabArea
    } = useTabulateArea()

    const [loading, setLoading] = useState(false)
    const [stateData, setStatesData] = useState(null)
    const store = useAppContext()

    const table = createTable()

    const generateBackgroundColor = (value, index) => {
        const rangeColor = ["#f8746d", "#fa9172", "#fbae78", "#fad58e", "#f7e683", "#dbe081", "#9fcf7e", "#68bf7b"]
        const chromaScale = chroma.scale(rangeColor).domain(stateData.range.contracts)

        const chromaScaleTotal = chroma.scale(rangeColor).domain(stateData.range.total)

        if (index === 1) {
            return isNaN(value) ? {} : {
                backgroundColor: chromaScale(value).hex(),
                color: "black",
                borderColor: "#eef2f6"
            }
        } else if (index === 2) {
            return isNaN(value) ? {} : {
                backgroundColor: chromaScaleTotal(value).hex(),
                color: "black"
            }
        } else {
            return {
                backgroundColor: "#fEfEfE",
                color: "black"
            }
        }
    }

    const filter = async (values = null) => {
        setLoading(true)
        if (values || store.filterValues) {

            const statuscheckstartend = checkStartEnd(values || store.filterValues)

            if (!checkStates(values || store.filterValues)) {
                errorDialog("Debe seleccionar por lo menos un estado")
            } else if (statuscheckstartend !== 'true') {
                errorDialog(statuscheckstartend)
            } else {
                const dataFilter = transformData(values || store.filterValues)
                const states = await getTabArea(dataFilter.filter)
                setStatesData({ ...states })

                if (store.open) {
                    store.handleClose()
                }
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (store.filterValues && store?.fields.length) {
            filter()
        }
    }, [store.fields])

    return {
        table,
        stateData,
        loading,
        generateBackgroundColor,
        filter
    }
}

export default useAreaLogic
