import { ColorModeContext, useMode } from "../../components/Sidebar/theme"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { MyProSidebarProvider } from "../../components/Sidebar/sidebarContext"
import { Route, Routes } from "react-router-dom"

import { deepmerge } from '@mui/utils'
import {
    experimental_extendTheme as extendMuiTheme,
    shouldSkipGeneratingVar as muiShouldSkipGeneratingVar
} from '@mui/material/styles';
import colors from '@mui/joy/colors'
import {
    extendTheme as extendJoyTheme,
    CssVarsProvider,
    shouldSkipGeneratingVar as joyShouldSkipGeneratingVar
} from '@mui/joy/styles'

import Redirect from "../../hooks/redirect"
import { createContext, useContext, useMemo, useState } from "react"
import NotFound from "../../components/404/404"
import FilterValues from "../../hooks/Tramites/filter"
import Topbar from "../../components/Sidebar/TopBar"
import dayjs from "dayjs"
import { validaDateInitial } from "../../modules/date"
import Tramites from "./Main/Tramites"
import Pivottable from "./DynamicTable/PivotTable"
import Metrics from "./Metrics/metrics"
import AttributesSection from "./Attributes/AttributesSection"
import MapTramitesSection from "./Map/MapTramitesSection"
import AnswersSection from "./Answers/Answer"
import CalendarComponent from "./Calendario.js/calendar"

const { unstable_sxConfig: muiSxConfig, ...muiTheme } = extendMuiTheme({
    cssVarPrefix: 'joy',
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: colors.blue[500]
                },
                grey: colors.grey,
                error: {
                    main: colors.red[500]
                },
                info: {
                    main: colors.purple[500]
                },
                success: {
                    main: colors.green[500]
                },
                warning: {
                    main: colors.yellow[200]
                },
                common: {
                    white: '#FFF',
                    black: '#09090D'
                },
                divider: colors.grey[200],
                text: {
                    primary: colors.grey[800],
                    secondary: colors.grey[600]
                }
            }
        },
        dark: {
            palette: {
                primary: {
                    main: colors.blue[600]
                },
                grey: colors.grey,
                error: {
                    main: colors.red[600]
                },
                info: {
                    main: colors.purple[600]
                },
                success: {
                    main: colors.green[600]
                },
                warning: {
                    main: colors.yellow[300]
                },
                common: {
                    white: '#FFF',
                    black: '#09090D'
                },
                divider: colors.grey[800],
                text: {
                    primary: colors.grey[100],
                    secondary: colors.grey[300]
                }
            }
        }
    }
});

const { unstable_sxConfig: joySxConfig, ...joyTheme } = extendJoyTheme()
const mergedTheme = (deepmerge(muiTheme, joyTheme))

mergedTheme.unstable_sxConfig = {
    ...muiSxConfig,
    ...joySxConfig
};

const AppContext = createContext({
    filtro: [],
    selected: [],
    startDate: null,
    setStartDate: () => { },
    endDate: null,
    setEndDate: () => { },
    open: false,
    handleOpen: () => { },
    handleClose: () => { },
    type: null
})

const Layout = () => {

    const [theme, colorMode] = useMode()

    const currentTime = new Date()
    const startDefault = dayjs(currentTime.getFullYear().toString() + "-01-01").format("YYYY-MM-DD")
    const endDefault = dayjs(currentTime).format("YYYY-MM-DD")

    const [startDate, setStartDate] = useState(startDefault)
    const [endDate, setEndDate] = useState(endDefault)

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)

        const statusDateValidate = validaDateInitial(startDate, endDate)

        if (!statusDateValidate) {
            setStartDate(startDefault)
            setEndDate(endDefault)
        }
    }

    let {
        filtro,
        selected,
        attributes,
        years,
        type,
        metricsInfo
    } = FilterValues()

    return (
        <AppContext.Provider
            value={useMemo(() => ({
                filtro,
                selected,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
                attributes,
                open,
                years,
                type,
                metricsInfo,
                handleOpen,
                handleClose
            }),
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [filtro, selected, startDate, endDate, attributes, open, years, type, metricsInfo])}>
            <CssVarsProvider
                theme={mergedTheme}
                shouldSkipGeneratingVar={(keys) =>
                    muiShouldSkipGeneratingVar(keys) || joyShouldSkipGeneratingVar(keys)
                }
            >
                <ColorModeContext.Provider value={colorMode}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <MyProSidebarProvider filter={handleOpen} >
                            <div style={{ height: "100%", width: "100%", marginRight: 20, marginLeft: 20, paddingTop: 20, paddingBottom: 20 }}>
                                <main>
                                    <Topbar />
                                    <Routes>
                                        <Route path="/" element={<Tramites />} />
                                        <Route path="/map" element={<MapTramitesSection />} />
                                        <Route path="/pivotte" element={<Pivottable />} />
                                        <Route path="/metrics" element={<Metrics />} />
                                        <Route path="/attributes" element={<AttributesSection />} />
                                        <Route path="/answers" element={<AnswersSection />} />
                                        <Route path="/calendar" element={<CalendarComponent />} />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </main>
                            </div>
                        </MyProSidebarProvider>
                    </ThemeProvider>
                </ColorModeContext.Provider>
                <Redirect />
            </CssVarsProvider>
        </AppContext.Provider >
    )
}

export default Layout

export function useAppContext() {
    return useContext(AppContext)
}
