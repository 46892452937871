export function validateJSON(value) {
    const json = JSON.parse(value)

    if (!isValidJsonStructure(json)) {
        return false
    }

    for (const campo of json.campos) {
        if (!isValidCampo(campo)) {
            return false
        }

        if (campo.tipo === 'smu' && !isValidSmuCampo(campo)) {
            return false
        }
    }

    return true
}

function isValidJsonStructure(json) {
    return json?.campos && json.campos.length
}

function isValidCampo(campo) {

    const fieldNames = ["tipo", "nombre", "alias"]

    for (const name of fieldNames) {
        if (!campo[name] || campo[name] === "") {
            return false
        }
    }

    return true
}

function isValidSmuCampo(campo) {
    return campo.items && Array.isArray(campo.items) && campo.items.length
}
