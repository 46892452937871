import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Loading from "../../components/Loading/Loading"
import DraggableAction from "./DraggableAction"
import TooltipWarning from "../../components/Tooltip/TooltipWarning"
import Redirect from "../../hooks/redirect"

import { Grid, IconButton, Stack, Tooltip } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import QuestionItem from "./QuestionItem"
import MoveNewRowBox from "./MoveNewRowBox"
import ContainerDisposition from "./ContainerDiposition"
import useSurveyLogic from "./hooks/useSurveyLogic"
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import SaveIcon from '@mui/icons-material/Save'

const Survey = () => {

    const {
        columns,
        invalidDestination,
        status,
        optionsSurvey,
        isDragging,
        handleDragEnd,
        handleDragUpdate,
        saveLayout,
        addRow,
        deleteRow,
        getQuestionLikertById,
        moveColumnPosition,
        handleDragStart
    } = useSurveyLogic()

    const checkForEscType = (column, options) => {
        let statusEsc = false

        column?.fields?.some((field) => {
            const question = options?.find((q) => q.id === field)
            if (question?.type === "esc") {
                statusEsc = true
                return true
            }
            return false
        })

        return statusEsc
    }

    const renderColumn = (column, columnIndex, direction, isMissingColumn) => {

        const filterColumn = columns?.filter(col => col.col !== "missing")
        const lastCol = filterColumn[filterColumn?.length - 1] ?? null
        const firstCol = filterColumn[0] ?? null

        const statusEsc = !isMissingColumn ? checkForEscType(column, optionsSurvey) : false

        return (
            <Droppable
                key={columnIndex}
                droppableId={`column-${columnIndex}`}
                direction={direction}
                isDropDisabled={statusEsc}
            >
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={blockSurveyStyle(isMissingColumn, statusEsc)}
                    >
                        {
                            !isMissingColumn && (
                                <Tooltip title={"Eliminar fila"}>
                                    <IconButton onClick={() => deleteRow(columnIndex)} >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                        }

                        {column?.fields?.map((field, fieldIndex) => {
                            const question = optionsSurvey?.find((q) => q.id === field)
                            const likert = getQuestionLikertById(field)

                            return (
                                <Draggable key={field} draggableId={`field-${field}`} index={fieldIndex}>
                                    {(given, snapshot) => (
                                        <QuestionItem
                                            question={{ ...question, likert }}
                                            provided={given}
                                            snapshot={snapshot}
                                            isMissingColumn={isMissingColumn}
                                            isDragging={isDragging}
                                        />
                                    )}
                                </Draggable>
                            )
                        })}

                        {
                            (!isMissingColumn && !isDragging) && (
                                <Stack direction="column" spacing={2} style={{ position: 'sticky', top: 0, right: 0 }}>
                                    {
                                        (JSON.stringify(firstCol) !== JSON.stringify(column)) && (
                                            <Tooltip title="Subir fila">
                                                <IconButton onClick={() => moveColumnPosition(columnIndex, "up")}>
                                                    <ArrowUpward style={{ color: '#4527A0' }} />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }

                                    {
                                        JSON.stringify(lastCol) !== JSON.stringify(column) && (
                                            <Tooltip title="Bajar fila">
                                                <IconButton onClick={() => moveColumnPosition(columnIndex, "down")}>
                                                    <ArrowDownward style={{ color: '#4527A0' }} />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }
                                </Stack>
                            )
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        )
    }

    const actions = [
        { icon: <AddIcon />, name: 'Nueva Fila', onClick: () => addRow() },
        { icon: <SaveIcon />, name: 'Guardar', onClick: () => saveLayout(), disabled: !status }
    ]

    if (!columns) {
        return (<div><Loading /><Redirect /></div>)
    }

    return (
        <div>
            <DraggableAction actions={actions}></DraggableAction>
            <DragDropContext
                onDragEnd={handleDragEnd}
                onDragUpdate={handleDragUpdate}
                onBeforeDragStart={handleDragStart}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TooltipWarning
                            label={"Opciones"}
                            message={"Los campo sin ubicar saldrán al final"}
                        />

                        <br />

                        {columns.map((column, columnIndex) =>
                            column.col === "missing"
                                ? renderColumn(column, columnIndex, 'vertical', true)
                                : null
                        )}
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <ContainerDisposition>
                            {columns.map((column, columnIndex) =>
                                column.col !== "missing"
                                    ? renderColumn(column, columnIndex, 'horizontal', false)
                                    : null
                            )}

                            {
                                invalidDestination && (
                                    <MoveNewRowBox />
                                )
                            }
                        </ContainerDisposition>
                    </Grid>
                </Grid>
            </DragDropContext>
        </div>
    )
}

export default Survey

function blockSurveyStyle(isMissingColumn, statusEsc) {
    return {
        display: 'flex',
        flexWrap: isMissingColumn ? 'nowrap' : "wrap",
        padding: '12px',
        flexDirection: isMissingColumn ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        border: isMissingColumn ? 'none' : '2px solid #cde8fc',
        backgroundColor: statusEsc ? '#DCDCDC' : "#e3f2fd",
        borderRadius: '8px',
        marginBottom: '20px',
        overflowY: "auto",
        overflowX: "hidden",
        width: "100%",
        maxHeight: "85vh"
    }
}
