const ChartContainerResponsive = (props) => {
    return (
        <div
            style={{
                width: "90%",
                margin: "0 auto",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden"
            }}
        >
            {props.children}
        </div>
    )
}

export default ChartContainerResponsive
