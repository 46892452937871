import Cookies from "js-cookie";

const validate = async () => {
  const endpoint = localStorage.getItem("api");
  const url = endpoint + "validate";

  const instance = localStorage.getItem("instance");

  try {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tkreact_" + instance),
      },
      body: JSON.stringify({
        operacion: "validar_estado",
      }),
    };

    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log(error);
  }
};

export default validate;
