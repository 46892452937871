import confDataTable from "../../services/Tramites/confDataTable"
import dataTable from "../../services/Tramites/data"
import getMarkers from "../../services/Tramites/geocoordinates"
import getPopupData from "../../services/Tramites/popup"
import { formatNumberSeparateMiles } from "../../utils/Number"


const useMarkers = () => {
    const getCoordinates = async (filter) => {

        const response = await getMarkers(filter)

        if (response?.status) {
            const data = response.data

            if (data) {
                return data.map((item) => {
                    return {
                        id: item.id,
                        geocode: [parseFloat(item.lat), parseFloat(item.lng)]
                    }
                })
            }
        }

        return []
    }

    const getPopupMessage = async (marker) => {

        const response = await getPopupData(marker)

        if (response?.status) {
            return response
        }

        return {}
    }

    const getConfTable = async () => {

        const response = await confDataTable()

        if (response?.status) {
            const columns = response.columns

            return columns?.map(elemento => ({
                name: elemento.index,
                label: elemento.name,
                options: {
                    customBodyRender: (data) => {
                        if (elemento.format === "numeric") {
                            return (
                                <div align={elemento.align ?? "center"}>
                                    {formatNumberSeparateMiles(data)}
                                </div>
                            )
                        }

                        return <div align={elemento.align ?? "center"}>{data}</div>
                    }
                }
            }))
        }

        return []
    }

    const getTableData = async (columns, filter = null) => {

        const response = await dataTable(filter)

        if (response?.status && columns) {
            const data = response.data
            return data?.map((diccionario) => {
                return columns?.map((clave) => {
                    return diccionario[clave] ?? null
                })
            })
        }

        return []
    }

    return { getCoordinates, getPopupMessage, getConfTable, getTableData }
}

export default useMarkers
