import { SpeedDialAction } from "@mui/material"
import BasicTable from "../Table/BasicTable"
import BarChartComp from "./BarChart"
import PieChart from "./PieChart"
import { Fragment, useEffect, useState } from "react"
import SpeedDialChart from "../SpeedDial/SpeedDialChart"
import { BarChart, Sort, PieChart as PieChartIcon } from "@mui/icons-material"
import MenuColumn from "./Menu/MenuColumn"
import { manera } from "./Menu/optionsOrder"
import { handleSort } from "./Sort/sortColumnFilter"
import ChartContainer from "./ChartContainer"
import ChartContainerResponsive from "./ChartContainerResponsive"

const BarColumnChart = ({
    data,
    orden = [],
    columsOptions = [],
    columnas = [],
    title = "grafico",
    viewTable = true,
    defaultType = "column"
}) => {

    const [chartMode, setChartMode] = useState(defaultType)
    const [open, setOpen] = useState(false)
    const [chartInfo, setChartInfo] = useState(null)
    const [tableData, setTableData] = useState(null)

    const [filterOptions, setFilterOptions] = useState({
        orden: 'asc',
        column: '',
        items: ''
    })

    const changeChartMode = (mode) => {
        if (mode !== chartMode) {
            setChartMode(mode)
        }
    }

    const handleToggle = () => {
        setOpen(!open)
    }

    const actions = [
        { icon: <BarChart />, name: 'Barras', onClick: () => changeChartMode("column") },
        { icon: <PieChartIcon />, name: 'Circular', onClick: () => changeChartMode("pie") }
    ]

    useEffect(() => {
        const resultado = data?.map((objeto) => orden.map((prop) => objeto[prop]))
        setTableData(resultado ?? [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        let info = []

        if (chartMode === "column") {
            handleSort(data, filterOptions).forEach(element => {
                info.push(element)
            })
        } else {
            info = [...data]
        }

        setChartInfo([...info])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartMode, filterOptions, data])

    return (
        <Fragment>
            <ChartContainerResponsive>

                {
                    chartMode === "column" && (
                        <div>
                            <MenuColumn
                                open={open}
                                handleToggle={handleToggle}
                                manera={manera}
                                columsOptions={columsOptions}
                                filterOptions={filterOptions}
                                setFilterOptions={setFilterOptions}
                            />
                            <BarChartComp
                                data={chartInfo ?? []}
                                title={title}
                            />
                        </div>
                    )
                }

                {
                    chartMode === "pie" && (
                        <PieChart
                            data={chartInfo ?? []}
                            title={title}
                        />
                    )
                }

                <div style={{
                    position: "absolute",
                    left: "100%"
                }}>
                    <SpeedDialChart>
                        {(chartMode === "column") && (
                            <SpeedDialAction
                                key="order_by"
                                icon={<Sort />}
                                tooltipTitle={"Ordenar"}
                                onClick={handleToggle}
                            />
                        )}

                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.onClick}
                            />
                        ))}
                    </SpeedDialChart>
                </div>
            </ChartContainerResponsive>
            <br />

            {
                viewTable && (
                    <ChartContainer>
                        <BasicTable
                            columns={columnas}
                            data={tableData ?? []}
                            order={['orden', 'asc']}
                            nameTable={title}
                        />
                    </ChartContainer>
                )
            }
        </Fragment>
    )
}

export default BarColumnChart
