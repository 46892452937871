import defaultConfig from '../Config/config'
import Highcharts from 'highcharts'

export const getOptions = (data, amountData, percent, categories, title, type) => {

    let series = null
    const filteredIndexes = data?.map((_, index) => index)
        .filter(index => data[index] === 0 && amountData[index] === 0)

    const filteredData = data?.filter((_, index) => !filteredIndexes.includes(index))
    const filteredAmount = amountData?.filter((_, index) => !filteredIndexes.includes(index))

    if (type === "column") {
        series = [
            {
                name: "Valor",
                data: filteredData,
                colorByPoint: true,
                showInLegend: true
            },
            {
                name: "Cantidad",
                type: 'spline',
                dashStyle: 'Dot',
                color: "black",
                data: filteredAmount
            }
        ]
    }

    if (type === "pie") {

        const dataPie = categories
            .map((mes, index) => ({
                name: mes,
                y: percent[index]
            }))
            .filter(item => item.y?.toFixed(2) !== '0.00')

        series = [
            {
                name: "Porcentaje",
                data: dataPie,
                colorByPoint: true,
                showInLegend: true
            }]
    }

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: type
        },
        title: {
            text: title
        },
        tooltip: {
            formatter: function () {

                const decimals = (this.y % 1 !== 0) ? 2 : 0
                const value = type === "column" ? this.y : this.percentage
                const formattedNumber = Highcharts.numberFormat(value, decimals, ',', '.')

                const key = `<span style="font-size: 10px">${this.key}</span><br/>`
                const icon = `<span style="color:${this.color}">\u25CF</span> `
                return `${key + icon + this.series.name}: <b>${formattedNumber}</b><br/>`
            }
        },
        yAxis: {
            title: {
                text: null
            }
        },
        xAxis: {
            categories: categories
        },
        series: series
    }

    return Object.assign({}, options, defaultConfig)
}
