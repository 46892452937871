import React, { Fragment } from 'react'
import 'react-pivottable/pivottable.css'
import Loading from '../../../components/Loading/Loading'
import FilterContract from '../FilterContract'
import usePivotTableLogic from './hook/usePivotTableLogic'
import usePivotTableFormLogic from './hook/usePivotTableFormLogic'
import PivotTableWrapper from '../../DynamicTable/PivotTableWrapper'

const Pivottable = () => {

    console.error = console.warn

    const {
        data,
        loading,
        fieldOptions,
        options,
        table,
        state,
        filter,
        handleChange,
        saveConfTable,
        deleteTable,
        changeState
    } = usePivotTableLogic()

    if (!data || loading) {
        return <Loading />
    }

    return (
        <Fragment>

            <FilterContract filter={filter} />

            <PivotTableWrapper
                data={data}
                fieldOptions={fieldOptions}
                options={options}
                table={table}
                state={state}
                handleChange={handleChange}
                saveConfTable={saveConfTable}
                deleteTable={deleteTable}
                changeState={changeState}
                usePivotTableFormLogic={usePivotTableFormLogic}
            />
        </Fragment>
    )
}

export default Pivottable
