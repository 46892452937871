import { useNavigate, useParams, } from "react-router-dom";
import useMensajeForm from "../../../hooks/Mensajes/useMensajeForm";

import Loading from "../../../components/Loading/Loading";
import MessagesForm from "./messagesForm";
import { useEffect, useState } from "react";

const MessagesUpdate = () => {

    const { id } = useParams()
    const history = useNavigate()
    const { updateMensaje, getMessageById } = useMensajeForm()

    const [initialValues, setInitialValues] = useState(null)

    const onSubmit = async (fields) => {

        fields.name = fields.name.trim()
        fields.code = fields.code.trim()
        fields.content = fields.content.trim()

        setInitialValues({
            id: fields.id,
            name: fields.name,
            code: fields.code,
            content: fields.content,
            active: fields.active
        })

        updateMensaje(fields)
    }

    useEffect(() => {
        const fields = [
            'id',
            'name',
            'code',
            'content',
            'active'
        ]

        if (id !== undefined && id !== null) {
            initializeUpdate(fields);
        } else {
            history("/")
        }

        function initializeUpdate(fields) {
            const data = getMessageById(id);
            const values = {};

            data.then((dato) => {
                if (dato === null) {
                    history("/")
                }

                else if (dato) {
                    fields.forEach(field => {
                        if (dato[field] !== undefined || dato[field] !== null) {
                            values[field] = dato[field]
                        }
                    });
                }
                setInitialValues(values);
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (initialValues === null) {
        return <Loading />
    }

    return (
        <MessagesForm initialValues={initialValues} onSubmit={onSubmit} />
    )
}

export default MessagesUpdate
