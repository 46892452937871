import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import defaultConfig from './Config/config'
import ChartContainerResponsive from './ChartContainerResponsive'

require("highcharts/modules/accessibility")(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const getOptions = (data, title) => {

    const filteredData = data?.filter(item => {
        const roundedValue = parseFloat(item.y?.toFixed(2) ?? null)
        return roundedValue !== 0 && item.y !== null
    })

    const options = {
        chart: {
            type: 'pie',
            plotBorderWidth: null,
            plotShadow: false
        },
        title: {
            text: title
        },
        tooltip: {
            formatter: function () {
                const formattedNumber = Highcharts.numberFormat(this.y, this.y % 1 !== 0 ? 2 : 0, ',', '.');
                const formattedPercentage = Highcharts.numberFormat(this.percentage, this.percentage % 1 !== 0 ? 2 : 0);
                return `<span style="color:${this.color}">\u25CF</span> <b>${formattedNumber}</b> (${formattedPercentage}%)<br/>`
            }
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.2f}%'
                }
            }
        },
        series: [
            {
                name: title,
                colorByPoint: true,
                showInLegend: true,
                data: filteredData
            }
        ]
    }

    return Object.assign({}, options, defaultConfig)
}

const PieChart = ({ data, title = "grafico", ...props }) => {
    return (
        <ChartContainerResponsive>
            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions(data, title)}
            />
            {props.children}
        </ChartContainerResponsive>
    )
}

export default PieChart
