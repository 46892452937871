
export const verifyCoords = (lat, lng) => {
    if (typeof lat !== 'number' || typeof lng !== 'number') {
        return false
    }

    const latValida = lat >= -90 && lat <= 90
    const lngValida = lng >= -180 && lng <= 180

    return latValida && lngValida;
}

export const localPosition = () => {

    let positionlocal = null

    navigator.geolocation.getCurrentPosition(
        (position) => {
            positionlocal = [position.coords.latitude, position.coords.longitude]
        },
        (_error) => null,
        { enableHighAccuracy: true }
    )

    return positionlocal
}
