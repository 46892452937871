import { useRef, useState } from "react"
import * as htmlToImage from 'html-to-image'

const useQrCodeLogic = () => {
    const qrCodeRef = useRef(null)
    const [hex, setHex] = useState("#3765af")
    const [backgroundHex, setBackgroundHex] = useState("#FFF")
    const [icon, setIcon] = useState(null)
    const [size, setSize] = useState(600)
    const [precision, setPrecision] = useState("L")
    const [message, setMessage] = useState("Escanea el código QR")
    const [margin, setMargin] = useState(true)

    const downloadQRCode = () => {
        const qrCodeContainer = qrCodeRef.current
        if (qrCodeContainer) {
            htmlToImage.toPng(qrCodeContainer)
                .then(function (dataUrl) {
                    const downloadLink = document.createElement('a')
                    downloadLink.href = dataUrl
                    downloadLink.download = 'qr_code.png'
                    downloadLink.click()
                })
                .catch(function (error) {
                    console.error('Error al convertir el código QR a imagen:', error)
                })
        }
    }

    const handleIconClick = (url, title) => {
        if (title === 'upload') {
            document.getElementById('fileInput').click()
        } else if (title === 'Sin Icono') {
            setIcon(null)
        } else {
            setIcon(url)
        }
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0]
        if (selectedFile) {
            const reader = new FileReader()
            reader.onload = (eventIcon) => {
                setIcon(eventIcon.target.result)
            }
            reader.readAsDataURL(selectedFile)
        }
    }

    const handleCheckboxChange = (event) => {
        setMargin(event.target.checked)
    }

    return {
        qrCodeRef,
        hex,
        setHex,
        backgroundHex,
        setBackgroundHex,
        icon,
        size,
        setSize,
        precision,
        setPrecision,
        message,
        setMessage,
        margin,
        downloadQRCode,
        handleIconClick,
        handleFileChange,
        handleCheckboxChange
    }

}

export default useQrCodeLogic
