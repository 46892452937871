import decipher from "./Utils/JWT"

const createNewArea = async (data) => {

    const datadecode = localStorage.getItem('data')
    const { id, token, endpoint, jwt } = decipher(datadecode)

    const url = `${endpoint}/area`

    const info = {
        id: id,
        token: token
    }

    const combinado = Object.assign({}, data, info)

    try {
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + jwt
            },
            body: JSON.stringify(combinado)
        }

        const response = await fetch(url, config)
        const json = await response.json()
        return json

    } catch (error) {
        console.log(error)
    }
}

export default createNewArea
