export function confRectDot(areasCopy) {
    if (areasCopy.length === 3) {
        const coordtop = areasCopy[1].coords
        const coordbottom = areasCopy[2].coords

        if (areasCopy[0].coords?.length === 0) {
            areasCopy[0].coords.push(coordtop[0])
            areasCopy[0].coords.push(coordtop[1])
            areasCopy[0].coords.push(coordbottom[0])
            areasCopy[0].coords.push(coordbottom[1])
        } else if (areasCopy[0].coords?.length === 2) {
            areasCopy[0].coords.push(coordbottom[0])
            areasCopy[0].coords.push(coordbottom[1])
        }
    }
}

export function confCircleDot(areasCopy, getRadius) {
    if (areasCopy.length === 3) {
        const coordone = areasCopy[1].coords
        const coordthree = areasCopy[2].coords

        const radius = getRadius(coordone, coordthree)

        if (areasCopy[0].coords?.length === 0) {
            areasCopy[0].coords.push(coordone[0])
            areasCopy[0].coords.push(coordone[1])
        }

        areasCopy[0].coords.push(radius)
    }
}

export function deleteCircleDots(areasCopy) {
    if (areasCopy.length > 1) {
        areasCopy.pop()

        if (areasCopy[0]?.coords.length === 3) {
            areasCopy[0].coords?.pop()
        }

        else if (areasCopy[0]?.coords.length === 2) {
            areasCopy[0].coords?.splice(0, 2)
        }
    }
}

export function deleteRectDots(areasCopy) {
    if (areasCopy.length > 1) {
        areasCopy.pop()

        const len = areasCopy[0]?.coords.length

        if (len === 4 || len === 2) {
            areasCopy[0].coords?.splice(len - 2, 2)
        }
    }
}

export function deletePolyDots(areasCopy) {
    if (areasCopy.length > 1) {
        areasCopy.pop()

        if (areasCopy[0]?.coords) {
            areasCopy[0].coords?.pop()
            areasCopy[0].coords?.pop()
        }
    }
}
