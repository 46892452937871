import { TableCell, TableFooter, TableRow } from '@mui/material'
import { formatNumberSeparateMiles } from '../../../utils/Number'
import { decimalFormat } from '../Format/format'

export const footer = ({ data, total }) => {
    const totalCantidad = data.reduce((
        act, [_, , cantidad]) => act + parseInt(cantidad), 0)

    const totalPorcentaje = data.reduce((
        act, [_, , , promedio]) => act + parseFloat(promedio.split(" ")[0]), 0)

    const output = formatNumberSeparateMiles(total)

    return (
        <TableFooter >
            <TableRow >
                <TableCell align='left'>Total</TableCell>
                <TableCell align='right'>{output}</TableCell>
                <TableCell align='right'>{formatNumberSeparateMiles(totalCantidad)}</TableCell>
                <TableCell align='right'>{decimalFormat(totalPorcentaje)} %</TableCell>
            </TableRow>
        </TableFooter>
    )
}