import { Button, Grid } from "@mui/material"

const CardActions = ({ children }) => {
    return (

        <Grid item xs={2} md={2}>
            {children}
        </Grid>

    )
}

export const CardButton = ({ estado, metodo }) => {

    const style = { backgroundColor: estado ? "#CB3234" : "#008F39" }

    return (
        <Button variant="contained" style={style}
            onClick={(() => metodo())}
        >
            {estado ? "Inactivar" : "Activar"}
        </Button>
    )
}

CardActions.Button = CardButton

export default CardActions