import { errorDialog, successDialog } from '../../components/Alert/Alert';
import adicionarMensaje from '../../services/Mensajes/adicionarMensaje';
import editarMensaje from '../../services/Mensajes/editarMensaje';
import getMensaje from '../../services/Mensajes/getMensaje';

const useMensajeForm = () => {

    const correcto = (mensaje = "El mensaje ha sido creado correctamente") => {
        successDialog(mensaje)
    }

    const error = (mensaje = "Hemos tenido un problema para crear el mensaje") => {
        errorDialog(mensaje)
    }

    const getMessageById = async (id) => {
        try {
            const response = await getMensaje(id);

            if (response.status === true) {
                if (response.data) {
                    const info = response.data
                    return info
                } else {
                    return null
                }
            }

            return null
        } catch (error) {
            return null
        }
    }

    const createMensaje = async (values) => {

        const valuesCreate = Object.assign({}, values)
        let messageid = null

        await adicionarMensaje(valuesCreate).then((response) => {
            if (response.message !== undefined
                && response.message === "El mensaje se creo exitosamente") {
                correcto("El mensaje se creó exitosamente")

                if (response.data.id !== undefined && response.data.id !== null) {
                    messageid = response.data.id
                } else {
                    error("Tenemos problemas para redirigirte")
                }
            } else {
                error()
            }
        }).catch((err => {
            error()
        }))

        return messageid
    }

    const updateMensaje = (values) => {

        const valuesCreate = Object.assign({}, values)

        editarMensaje(valuesCreate).then((response) => {
            if (response.status !== undefined && response.status === true) {
                correcto("El mensaje ha sido actualizado correctamente")
            } else {
                error('Hemos tenido un problema para actualizar el mensaje')
            }
        }).catch((err => {
            error('Hemos tenido un problema para actualizar el mensaje')
        }))
    }

    return { createMensaje, updateMensaje, getMessageById }
}

export default useMensajeForm
