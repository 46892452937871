import { Settings } from "@mui/icons-material"
import { SpeedDial } from "@mui/material"
import Draggable from "react-draggable"

const SpeedDialChart = (props) => {
    return (
        <Draggable>
            <SpeedDial
                ariaLabel="chart mode"
                sx={{ bottom: 16, right: 16 }}
                style={{ position: "absolute" }}
                icon={<Settings />}
            >
                {props.children}
            </SpeedDial>
        </Draggable >
    )
}

export default SpeedDialChart
