export const columsOptions = [{
    value: "name",
    label: "Nombre"
}, {
    value: "y",
    label: "Valor"
}, {
    value: "process",
    label: "Cantidad"
}]
