import { Delete, Edit } from "@mui/icons-material";
import { headerConf } from "../../components/Table/TableMethod";
import { Tooltip } from "@mui/material";

export function botones(editar, eliminar, colDeleteId) {
  return (_value, tableMeta, _updateValue) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "right",
          fontSize: "24px",
        }}
      >
        <Tooltip title="Editar">
          <Edit
            onClick={() =>
              editar({
                ip: tableMeta.rowData[0],
                id: tableMeta.rowData[1],
                name: tableMeta.rowData[2],
                created: tableMeta.rowData[3],
                usuario: tableMeta.rowData[4],
                expiration_date: tableMeta.rowData[6],
              })
            }
          />
        </Tooltip>
        <Tooltip title="Eliminar">
          <Delete onClick={() => eliminar(tableMeta.rowData[colDeleteId])} />
        </Tooltip>
      </div>
    );
  };
}

export function actionsColumn(editar, eliminar, colDeleteId) {
  return {
    name: "",
    label: "",
    options: {
      viewColumns: false,
      filter: false,
      sort: false,
      customBodyRender: botones(editar, eliminar, colDeleteId),
    },
  };
}

export function TableConfig(
  atributos,
  statusTable,
  editar,
  eliminar,
  colDeleteId = 1
) {
  let encabezadoTabla = headerConf(atributos, statusTable);
  const boton = actionsColumn(editar, eliminar, colDeleteId);
  encabezadoTabla.push(boton);
  return encabezadoTabla;
}
