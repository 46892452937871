import { createTheme } from "@mui/material/styles"

const theme = createTheme({
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    marginBottom: "0px",
                }
            }
        },
        MUIDataTable: {
            styleOverrides: {
                paper: {
                    borderRadius: '8px',
                },
                tableRoot: {
                    fontFamily: 'Arial, sans-serif',
                }
            }
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                root: {
                    fontFamily: 'Arial, sans-serif',
                    color: "#485261",
                    fontSize: 15,
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    width: '100%'
                },
            },
        },
        MUIDataTableBodyCell: {
            styleOverrides: {
                root: {
                    marginTop: "0px",
                    marginBottom: "0px",
                    padding: "0px .5em 0px .5em",
                    fontFamily: 'Arial, sans-serif',
                    color: "#485261",
                    fontSize: 12,
                    textAlign: "left"
                }
            }
        }
    },
})

export default theme