import { ColorModeContext, useMode } from "../../components/Sidebar/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";

import { deepmerge } from "@mui/utils";
import {
  experimental_extendTheme as extendMuiTheme,
  shouldSkipGeneratingVar as muiShouldSkipGeneratingVar,
} from "@mui/material/styles";
import colors from "@mui/joy/colors";
import {
  extendTheme as extendJoyTheme,
  CssVarsProvider,
  shouldSkipGeneratingVar as joyShouldSkipGeneratingVar,
} from "@mui/joy/styles";

import Redirect from "../../hooks/redirect";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import NotFound from "../../components/404/404";
import Contracts from "./States/Contracts";
import { SidebarContractProvider } from "./NavBar/SidebarContractProvider";
import useContractsFilter from "../../hooks/Contracts/useContractsFilter";
import Pivottable from "./DynamicTable/PivotTable";
import Campos from "./Campos/Campos";
import Topbar from "../../components/Sidebar/TopBar";
import Area from "./Area/Area";
import Contractor from "./Contractor/Contractor";

const { unstable_sxConfig: muiSxConfig, ...muiTheme } = extendMuiTheme({
  cssVarPrefix: "joy",
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: colors.blue[500],
        },
        grey: colors.grey,
        error: {
          main: colors.red[500],
        },
        info: {
          main: colors.purple[500],
        },
        success: {
          main: colors.green[500],
        },
        warning: {
          main: colors.yellow[200],
        },
        common: {
          white: "#FFF",
          black: "#09090D",
        },
        divider: colors.grey[200],
        text: {
          primary: colors.grey[800],
          secondary: colors.grey[600],
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: colors.blue[600],
        },
        grey: colors.grey,
        error: {
          main: colors.red[600],
        },
        info: {
          main: colors.purple[600],
        },
        success: {
          main: colors.green[600],
        },
        warning: {
          main: colors.yellow[300],
        },
        common: {
          white: "#FFF",
          black: "#09090D",
        },
        divider: colors.grey[800],
        text: {
          primary: colors.grey[100],
          secondary: colors.grey[300],
        },
      },
    },
  },
});

const { unstable_sxConfig: joySxConfig, ...joyTheme } = extendJoyTheme();
const mergedTheme = deepmerge(muiTheme, joyTheme);

mergedTheme.unstable_sxConfig = {
  ...muiSxConfig,
  ...joySxConfig,
};

const AppContext = createContext({
  filtro: [],
  open: false,
  filterValues: {},
  fields: [],
  showSection: {},
  saveFilter: () => {},
  saveFilterValues: () => {},
  handleOpen: () => {},
  handleClose: () => {},
});

function getSelectedIds(data) {
  let selectedIds = [];

  const statesObj = data?.find((obj) => obj.id === "states");

  if (statesObj) {
    for (const item of statesObj.items) {
      if (item.selected === "T") {
        selectedIds.push(item.id);
      }
    }
  }

  return selectedIds;
}

const LayoutContracts = () => {
  const [theme, colorMode] = useMode();
  const [open, setOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [filterSelect, setFilterSelect] = useState({});
  const [fields, setFields] = useState([]);
  const [reload, setReaload] = useState(false);
  const [showSection, setShowSection] = useState(false);

  const { showConf, dataFilter } = useContractsFilter();

  useEffect(async () => {
    const values = [...dataFilter];
    const statesSelect = getSelectedIds(values);

    setShowSection(showConf);

    setFilterValues({
      ...filterValues,
      states: statesSelect,
    });

    setFilterSelect({
      ...filterValues,
      states: statesSelect,
    });

    setFields(values);
  }, [showConf, dataFilter]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setReaload(!reload);
  };

  const saveFilter = (filter) => {
    setFilterSelect(filter);
    setFilterValues(filter);
  };

  const saveFilterValues = (filter) => {
    setFilterSelect(filter);
  };

  useEffect(() => {
    setFilterSelect(filterValues);
  }, [reload]);

  return (
    <AppContext.Provider
      value={useMemo(
        () => ({
          open,
          filterValues,
          fields,
          filterSelect,
          showSection,
          handleClose,
          saveFilter,
          saveFilterValues,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fields, open, filterValues, filterSelect, showSection]
      )}
    >
      <CssVarsProvider
        theme={mergedTheme}
        shouldSkipGeneratingVar={(keys) =>
          muiShouldSkipGeneratingVar(keys) || joyShouldSkipGeneratingVar(keys)
        }
      >
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SidebarContractProvider filter={handleOpen}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  marginRight: 20,
                  marginLeft: 20,
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <main>
                  <Topbar />
                  <Routes>
                    <Route path="/" element={<Contracts />} />
                    <Route path="/dynamictable" element={<Pivottable />} />
                    <Route path="/fields" element={<Campos />} />
                    <Route path="/areas" element={<Area />} />
                    <Route path="/contractors" element={<Contractor />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </main>
              </div>
            </SidebarContractProvider>
          </ThemeProvider>
        </ColorModeContext.Provider>
        <Redirect />
      </CssVarsProvider>
    </AppContext.Provider>
  );
};

export default LayoutContracts;

export function useAppContext() {
  return useContext(AppContext);
}
