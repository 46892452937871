import VisorForm from "./VisorForm"
import useVisorForm from "../../../hooks/Visor/useVisorForm"

const CreateArea = (props) => {

    const { URLIMAGE, reload } = props
    const { createArea } = useVisorForm()

    const initialValues = {
        coordinates: '',
        name: '',
        url: '',
        target: 'W',
        shape: 'circle'
    }

    const onSubmit = async (fields, { _setStatus, _setSubmitting }) => {
        await createArea(fields).then(result => {
            const resultArray = [
                result.data?.id,
                result.data?.name,
                result.data?.coordinates.split(","),
                result.data?.shape,
                result.data?.url,
                result.data?.target
            ]
            reload(resultArray, result.data?.id)
        }).catch((err => {
            console.log(err)
        }))
    }

    return (
        <VisorForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            urlImage={URLIMAGE}
        />
    )
}

export default CreateArea
