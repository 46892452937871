import { BarChart, LocalFireDepartment, Sort } from '@mui/icons-material'
import PieChartIcon from '@mui/icons-material/PieChart'
import PieChart from '../PieChart'
import BarChartBasic from '../BarChartBasic'
import BasicTable from '../../Table/BasicTable'
import { customSort } from '../Sort/sort'
import { SpeedDialAction, TableCell, TableFooter, TableRow } from '@mui/material'
import { formatNumberSeparateMiles } from '../../../utils/Number'
import { Fragment, useEffect, useState } from 'react'
import MenuColumn from '../Menu/MenuColumn'
import { manera } from '../Menu/optionsOrder'
import { handleSort } from '../Sort/sortColumnFilter'
import SpeedDialChart from '../../SpeedDial/SpeedDialChart'
import { columns } from './tableConf'
import { columsOptions } from './filterConf'
import ChartContainerResponsive from '../ChartContainerResponsive'

export default function SMM({ data, title }) {

    const [chartMode, setChartMode] = useState("pie")
    const dataTable = []
    const [open, setOpen] = useState(false)

    const [chartInfo, setChartInfo] = useState(null)

    const [filterOptions, setFilterOptions] = useState({
        orden: 'asc',
        column: '',
        items: ''
    })

    data.forEach(element => {
        const percent = Number(element.percent.toFixed(2))
        dataTable.push([element.name, element.y, percent + " %"])
    })

    const footer = ({ data }) => {
        const total = data?.reduce((acc, [_, value]) => acc + value, 0)

        if (total) {
            return (
                <TableFooter >
                    <TableRow >
                        <TableCell>Total</TableCell>
                        <TableCell align='right'>{formatNumberSeparateMiles(total)}</TableCell>
                        <TableCell align='right'>100%</TableCell>
                    </TableRow>
                </TableFooter>
            )
        }
    }

    const changeChartMode = (mode) => {
        if (mode !== chartMode) {
            setChartMode(mode)
        }
    }

    const handleToggle = () => {
        setOpen(!open)
    }

    const actions = [
        { icon: <BarChart />, name: 'Barras', onClick: () => changeChartMode("column") },
        { icon: <PieChartIcon />, name: 'Circular', onClick: () => changeChartMode("pie") },
        { icon: <LocalFireDepartment />, name: 'Heatmap', onClick: () => changeChartMode("heatmap") }
    ]

    useEffect(() => {
        let info = []

        if (chartMode === "column") {
            handleSort(data, filterOptions).forEach(element => {
                info.push(element)
            })
        } else {
            info = [...data]
        }

        setChartInfo([...info])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartMode, filterOptions])

    return (
        <Fragment>
            <ChartContainerResponsive>
                {
                    chartMode === "column" && (
                        <div>
                            <MenuColumn
                                open={open}
                                handleToggle={handleToggle}
                                manera={manera}
                                columsOptions={columsOptions}
                                filterOptions={filterOptions}
                                setFilterOptions={setFilterOptions}
                            />

                            <BarChartBasic
                                data={chartInfo}
                                title={title} />
                        </div>
                    )
                }

                {
                    chartMode === "pie" && (
                        <PieChart data={data} title={title} />
                    )
                }

                {
                    chartMode === "heatmap" && (
                        <span>Opción aún no disponible</span>
                    )
                }

                <div style={{
                    position: "absolute",
                    left: "100%"
                }}>
                    <SpeedDialChart>
                        {chartMode === "column" && (
                            <SpeedDialAction
                                key="order_by"
                                icon={<Sort />}
                                tooltipTitle={"Ordenar"}
                                onClick={handleToggle}
                            />
                        )}

                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.onClick}
                            />
                        ))}
                    </SpeedDialChart>
                </div>
            </ChartContainerResponsive>

            {
                (chartMode === "pie" || chartMode === "column") && (
                    <ChartContainerResponsive>
                        <BasicTable
                            columns={columns}
                            data={dataTable}
                            Footer={footer}
                            customSort={customSort}
                            nameTable={title}
                        />
                    </ChartContainerResponsive>
                )
            }
        </Fragment>
    )
}
