import React from "react"
import { Field } from "formik"
import "../style/styles.css"
import AsteriskRequired from "./Required/RequiredField"

function Float(props) {
    const {
        label,
        name,
        min,
        max,
        decimalPlaces,
        required,
        max_length,
        pattern,
        message_pattern,
        ...rest
    } = props

    const validateNumber = (value) => {
        let errorMessage = ""

        if (value) {
            const parsedValue = parseFloat(value)

            if (isNaN(parsedValue)) {
                errorMessage = `El campo ${name} debe ser un número válido`
            } else if (min && parsedValue < min) {
                errorMessage = `El valor mínimo permitido para ${name} es ${min}`
            } else {
                if (max && parsedValue > max) {
                    errorMessage = `El valor máximo permitido para ${name} es ${max}`
                }
            }
        }

        return errorMessage
    }

    const validateDecimal = (value) => {
        if (decimalPlaces !== undefined && decimalPlaces <= 0) {
            const hasDecimal = /\./.test(value)
            return hasDecimal ? `No se permiten decimales en ${name}` : ""
        }

        if (decimalPlaces !== undefined) {
            const regex = new RegExp(`^\\d+(\\.\\d{1,${decimalPlaces}})?$`)
            return regex.test(value)
                ? ""
                : `El campo ${name} debe tener ${decimalPlaces} decimales como máximo`
        }

        return ""
    }

    const validateLength = (value) => {
        const stringValue = value ? String(value) : ''

        if (max_length && stringValue.length > max_length) {
            return `El atributo ${label} no debe tener más de ${max_length} caracteres numéricos`
        }

        return ''
    }

    const validatePattern = (value) => {

        const stringValue = value ? String(value) : ''

        if (pattern) {
            const regex = new RegExp(pattern)
            if (!regex.test(stringValue)) {
                return message_pattern || `El atributo ${label} no cumple el patrón requerido`
            }
        }

        return ""
    }

    const validateInput = (value) => {
        const numberError = validateNumber(value)
        const decimalError = validateDecimal(value)
        const lengthError = validateLength(value)
        const patternError = validatePattern(value)

        return numberError || decimalError || lengthError || patternError
    }

    return (
        <div>
            <label htmlFor={name}>
                {label}
                {required && (<AsteriskRequired />)}
            </label>
            <Field
                className="campos"
                style={{ backgroundColor: "white" }}
                id={name}
                name={name}
                type="number"
                validate={validateInput}
                {...rest}
            />
            <br />
        </div>
    )
}

export default Float
