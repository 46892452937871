export function customSort(data, colIndex, order) {
    if (colIndex === 0) {
        return data.sort((a, b) => {
            if (order === "desc") {
                return b.data[colIndex].localeCompare(a.data[colIndex])
            } else {
                return a.data[colIndex].localeCompare(b.data[colIndex])
            }
        })
    } else if (colIndex === 2) {
        return data.sort((a, b) => {

            const bAux = parseFloat(b.data[colIndex].split(" ")[0])
            const aAux = parseFloat(a.data[colIndex].split(" ")[0])

            if (order === "desc") {
                return bAux - aAux
            } else {
                return aAux - bAux
            }
        })

    } else {
        return data.sort((a, b) => {
            if (order === "desc") {
                return b.data[colIndex] - a.data[colIndex]
            } else {
                return a.data[colIndex] - b.data[colIndex]
            }
        })
    }
}