import alertify from "alertifyjs"
import useDynamicTable from "../../../../hooks/Tramites/dynamicTable.js/dynamicTable"
import usePivotTableFormState from "../../../DynamicTable/hook/usePivotTableFormState"

const usePivotTableFormLogic = (closeModal, saveTableConf) => {

    const { saveTable } = useDynamicTable()
    const {
        selectedOptions,
        handleSelectChange,
        verifyFormData
    } = usePivotTableFormState()

    const onSubmit = async (valueFields) => {
        const name = selectedOptions.name
        const response = await saveTable(name, valueFields)

        if (response) {
            alertify.success('La tabla se ha creado correctamente')
            closeModal()
            saveTableConf(response)
        } else {
            alertify.error("La tabla no se ha podido crear")
        }
    }

    return {
        selectedOptions,
        handleSelectChange,
        verifyFormData,
        onSubmit
    }
}

export default usePivotTableFormLogic
