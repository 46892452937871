import Cookies from "js-cookie";

const inactivar = async () => {
  const route = "instancia";
  const endpoint =
    localStorage.getItem("api_" + route) || localStorage.getItem("api");
  const instance = localStorage.getItem("instance");
  const url = endpoint + "validate";

  let body = {
    operacion: "inactivar",
  };

  try {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tkreact_" + instance),
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log(error);
  }
};

export default inactivar;
