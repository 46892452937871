import { useEffect, useState } from "react"
import { useAppContext } from "../layout"
import useTabulateField from "../../../hooks/Contracts/useTabulateField"
import { checkStartEnd, checkStates, transformData } from "../DynamicTable/hook/utils/validateFilter"
import { errorDialog } from "../../../components/Alert/Alert"

function filterFieldsById(data) {
    const filteredFields = []

    data.forEach(item => {
        const parsedId = parseInt(item.id)
        if (!isNaN(parsedId) && parsedId > 0 && (item.type === 'smm' || item.type === 'smu')) {
            const filteredItem = {
                value: parsedId,
                name: item.name
            }
            filteredFields.push(filteredItem)
        }
    })

    return filteredFields
}

function filterStoreTransform(inputObj) {
    const outputObj = {
        "states": inputObj.states.map(state => parseInt(state, 10)),
        "fields": []
    }

    for (const key in inputObj) {
        if (key !== "states") {
            const value = inputObj[key]
            if (Array.isArray(value)) {
                const fieldObj = {
                    "field_id": parseInt(key, 10),
                    "items": value.map(item => parseInt(item, 10))
                }
                outputObj.fields.push(fieldObj)
            } else {
                const fieldObj = {
                    "field_id": parseInt(key, 10),
                    ...value
                }
                outputObj.fields.push(fieldObj)
            }
        }
    }

    return outputObj
}

const useCamposLogic = () => {
    const store = useAppContext()
    const [fieldSelect, setFieldSelect] = useState("")
    const [fieldData, setFieldData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [fieldOptions, setFieldOptions] = useState([])

    const {
        getTabulate
    } = useTabulateField()

    const asignTabulate = async (field_id, filterValues = null) => {

        let storeValues
        if (store?.filterValues) {
            storeValues = filterStoreTransform(store?.filterValues)
        }

        const tabulate = await getTabulate(field_id, filterValues || storeValues)

        if (tabulate) {
            setFieldData(tabulate)
        }
    }

    const handleChange = async (event) => {

        setLoading(true)
        const field_id = event.target.value

        await asignTabulate(field_id)

        setFieldSelect(field_id)
        setLoading(false)
    }

    const filter = async (values) => {
        setLoading(true)
        const filtroValues = values || store?.filterValues

        if (filtroValues) {
            const statuscheckstartend = checkStartEnd(filtroValues)

            if (!checkStates(filtroValues)) {
                errorDialog("Debe seleccionar por lo menos un estado")
            } else if (statuscheckstartend !== 'true') {
                errorDialog(statuscheckstartend)
            } else {
                const dataFilter = transformData(filtroValues)
                await asignTabulate(fieldSelect, dataFilter?.filter)

                if (store.open) {
                    store.handleClose()
                }
            }
        }
        setLoading(false)
    }

    function getNameFromId(id) {
        const item = fieldOptions?.find(option => option.value === id)
        return item ? item.name : ''
    }

    useEffect(() => {
        const filterValues = store?.fields

        if (filterValues) {
            const options = filterFieldsById(filterValues)

            if (options.length) {
                const firstValue = options[0].value
                asignTabulate(firstValue)
                setFieldSelect(firstValue)
            }

            setFieldOptions(options ?? [])
        }

    }, [store?.fields])

    return {
        fieldSelect,
        fieldData,
        fieldOptions,
        loading,
        handleChange,
        filter,
        getNameFromId
    }
}

export default useCamposLogic
