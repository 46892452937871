import alertify from "alertifyjs"
import moment from "moment"

const validateFormatDate = (date, field_name = "") => {
    const fecha = new Date(date)
    const current = new Date()
    const status = moment(fecha, "YYYY/MM/DD", true).isValid() && fecha.getFullYear() >= 1900 && fecha <= current

    if (status === false) {
        alertify.error('Error en el campo ' + field_name)
    }

    return status
}

export function validaDate(fechaInicial, fechaFinal) {
    let estado = false
    const fieldsLabel = {
        initialDate: "fecha inicial",
        finalDate: "fecha final"
    }

    if (fechaInicial !== null && fechaFinal !== null) {
        if (validateFormatDate(fechaInicial, fieldsLabel.initialDate) === true && validateFormatDate(fechaFinal, fieldsLabel.finalDate) === true) {
            estado = !(fechaInicial > fechaFinal || fechaFinal > new Date())

            if (estado === false) {
                alertify.error('La fecha de inicio no puede ser mayor a la final')
            }
        }
    } else if (fechaInicial === null && fechaFinal === null) {
        estado = true
    }
    else if (fechaInicial !== null) {
        estado = validateFormatDate(fechaInicial, fieldsLabel.initialDate)
    } else if (fechaFinal !== null) {
        estado = validateFormatDate(fechaFinal, fieldsLabel.finalDate)
    }

    return estado
}


export function validaDateInitial(fechaInicial, fechaFinal) {
    let estado = false
    const fieldsLabel = {
        initialDate: "fecha inicial",
        finalDate: "fecha final"
    }

    if (fechaInicial !== null && fechaFinal !== null) {
        if (validateFormatDate(fechaInicial, fieldsLabel.initialDate) && validateFormatDate(fechaFinal, fieldsLabel.finalDate)) {
            estado = !(fechaInicial > fechaFinal || fechaFinal > new Date())
        }
    } else if (fechaInicial === null && fechaFinal === null) {
        estado = true
    }
    else if (fechaInicial !== null) {
        estado = validateFormatDate(fechaInicial, fieldsLabel.initialDate)
    } else if (fechaFinal !== null) {
        estado = validateFormatDate(fechaFinal, fieldsLabel.finalDate)
    }

    return estado
}
