import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const Redirect = (_props) => {

    const [searchParams, setSearchParams] = useSearchParams()

    const dataAlmacenamiento = () => {
        for (const [key, value] of searchParams) {
            localStorage.setItem(key, value)
        }
    }

    const removeQueryParams = (parameters) => {
        for (const i of parameters) {
            if (i !== 'route' && i !== 'id' && i !== "route" && i !== "md") {
                const param = searchParams.get(i)

                if (param) {
                    searchParams.delete(i)
                    setSearchParams(searchParams)
                }
            }
        }
    }

    useEffect(() => {
        dataAlmacenamiento()

        let parameters = []

        for (const [key] of searchParams) {
            parameters.push(key)
        }

        if (parameters.length > 0) {
            removeQueryParams(parameters)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div />
    )
}

export default Redirect
