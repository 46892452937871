import { errorDialog, successDialog } from '../../components/Alert/Alert';
import createNewArea from '../../services/Visor/createArea';

const useVisorForm = () => {

    const correcto = (mensaje = "La mensaje ha sido creada correctamente") => {
        successDialog(mensaje)
    }

    const error = (mensaje = "Hemos tenido un problema para crear la area.") => {
        errorDialog(mensaje)
    }

    const createArea = async (values) => {

        const valuesCreate = Object.assign({}, values)
        let result = null

        await createNewArea(valuesCreate).then((response) => {
            if (response.status) {
                correcto("Área almacenada correctamente")
                result = response
            } else {
                error()
            }
        }).catch((err => {
            error()
        }))

        return result
    }

    return { createArea }

}

export default useVisorForm