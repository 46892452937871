import { decimalFormat, decimalFormatInteger, nameCustom } from '../Format/format'
import { sortFloat, sortInteger, sortMonths } from '../../../utils/Sort'

export const columns = [
    {
        name: "Nombre",
        options: {
            customBodyRender: nameCustom
        }
    },
    {
        name: "Valor",
        options: {
            customBodyRender: decimalFormat
        }
    },
    {
        name: "Cantidad",
        options: {
            customBodyRender: decimalFormatInteger
        }
    },
    "%"
]

export function customSort(data, colIndex, order) {
    if (colIndex === 0) {
        return sortMonths(data, colIndex, order)
    } else if (colIndex === 3) {
        return sortFloat(data, colIndex, order)
    } else {
        return sortInteger(data, colIndex, order)
    }
}
