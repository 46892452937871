import * as yup from "yup"

const blankSpace = /^\s*\S[\s\S]*$/

yup.addMethod(yup.string, 'poly', function (message) {
    return this
        .test({
            name: 'poly',
            exclusive: true,
            message: message || 'La forma definida no es válida ',
            test: async function (value) {
                const shape = this.parent.shape
                const values = value?.split(',')

                if ((shape === 'rect ' && !value) || !values) {
                    return false
                }

                switch (shape) {
                    case 'circle': {
                        return values?.length === 3 && values.every(val => !isNaN(val))
                    }
                    case 'rect': {
                        return values?.length === 4 && values.every(val => !isNaN(val))
                    }
                    case 'poly': {
                        return values?.length >= 6 && values.every(val => !isNaN(val))
                    }
                    default:
                        return false
                }
            }
        })
})

export const visorSchema = yup.object().shape({
    coordinates: yup.string().trim()
        .required("Las coordenadas son requeridas")
        .matches(blankSpace, "Las coordenadas son requeridas")
        .poly(),
    name: yup.string()
        .required("El nombre es requerido").trim()
        .min(2, "El nombre debe tener al menos dos caracteres")
        .matches(blankSpace, "El nombre es requerido"),
    url: yup.string().trim()
        .required("La url es requerida")
        .matches(blankSpace, "La url es requerida"),
    target: yup.string()
        .required("El estado es requerido")
        .oneOf(['W', 'P']),
    shape: yup.string("La forma es requerida").trim()
        .oneOf(["rect", "circle", "poly"], "La forma especificada no es soportada")
})