import React, { Fragment } from 'react'
import useAttributesLogic from './useAttributesLogic'
import Loading from '../../../components/Loading/Loading'
import ChartControl from '../../../components/Chart/ControlChart'
import Filtro from '../filter'
import { Container } from '@mui/joy'
import SelectValue from '../../../components/Select/Select'
import { useAppContext } from '../layout'
import { attributes, years } from '../utils/state'

const AttributesSection = () => {
    const {
        attribute,
        handleChange,
        year,
        handleChangeYear,
        data,
        loading,
        filtrar
    } = useAttributesLogic()

    const store = useAppContext()

    const getTitle = () => {
        const search = attributes(store).find((option) => option.value === attribute)
        return search ? search.name : 'Grafica'
    }

    if (loading || !store.filtro || !store.selected) {
        return <Loading />
    }

    return (
        <Fragment>
            <Filtro filtrar={filtrar} />
            <Container>
                <SelectValue
                    label='Atributos'
                    options={attributes(store)}
                    value={attribute}
                    onChange={handleChange}
                />
                {attribute.split('*')[1] === 'float' ? (
                    <SelectValue
                        label='Año'
                        options={years(store)}
                        value={year}
                        onChange={handleChangeYear}
                    />
                ) : (
                    <div />
                )}
            </Container>
            <br />
            <ChartControl
                control={data.type}
                title={getTitle()}
                data={data.data ?? []}
                total={data.total_values}
            />
        </Fragment>
    )
}

export default AttributesSection
