import moment from "moment"

export function isNumber(value) {
    const type = typeof value === "number"
    return !Number.isNaN(value) && type
}

export function transformData(data) {
    const transformedStates = data?.states?.map(Number)

    const transformedFields = Object.entries(data)
        .filter(([key]) => key !== 'states')
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return { field_id: Number(key), items: value }
            } else {
                const { start, end, ...rest } = value

                const transformedItem = { field_id: Number(key), ...rest }

                if (start || end) {
                    transformedItem.start = start
                    transformedItem.end = end
                }

                return transformedItem
            }
        })

    const transformedData = {
        filter: {
            states: transformedStates,
            fields: transformedFields
        }
    }

    return transformedData ?? {}
}

export function isValidDate(dateString) {
    if (dateString && dateString !== "Invalid Date") {
        const formatoEsperado = 'YYYY-MM-DD'
        const fecha = moment(dateString, formatoEsperado, true)
        const esValida = fecha.isValid() && fecha.year() > 1900 && fecha.format(formatoEsperado) === dateString
        return esValida ?? false
    }

    return false
}

export function checkStates(data) {
    const statesField = Object.entries(data)
        .find(([key]) => key === 'states')

    if (statesField) {
        const [, value] = statesField
        return value.length > 0
    }

    return false
}

function validateDataRange(startDate, endDate) {
    const formatoEsperado = 'YYYY-MM-DD'
    const start = moment(startDate, formatoEsperado, true)
    const end = moment(endDate, formatoEsperado, true)

    return start.isValid() && end.isValid() && start.isSameOrBefore(end)
}

const isValidRangeDate = (startDate, endDate) => {
    if (startDate && endDate) {
        return validateDataRange(startDate, endDate)
    } else if (startDate || endDate) {
        return isValidDate(startDate) || isValidDate(endDate)
    } else {
        return false
    }
}

const isValidRangeNumeric = (start, end) => {
    if (start && end) {
        return start <= end
    }

    return true
}

export function checkStartEnd(data) {

    let messageError = ''

    const transformedFields = Object.entries(data)
        .filter(([key]) => key !== 'states')
        .map(([_key, value]) => {
            if (Array.isArray(value)) {
                return true
            } else {
                const { start, end } = value

                if (!start && !end) {
                    return true
                } else if (isNumber(start) || isNumber(end)) {
                    const statusNumeric = isValidRangeNumeric(start, end)

                    if (!statusNumeric) {
                        messageError = "El rango numérico ingresado es inválido"
                    }

                    return statusNumeric
                } else {
                    const status = isValidRangeDate(start, end)

                    if (!status) {
                        messageError = "El rango de fechas ingresado es inválido"
                    }

                    return status
                }
            }
        })

    const isValid = transformedFields.every((value) => value)
    return isValid ? 'true' : messageError
}
