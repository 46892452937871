import { Delete, Edit } from "@mui/icons-material";
import { Stack } from "@mui/joy";
import { headerConf } from "../../components/Table/TableMethod"

function botonesMensajes(editar, eliminar, colDeleteId) {
    return (value, tableMeta, updateValue) => {
        return (
            <Stack direction="row" spacing={1}>
                <Edit onClick={() => editar({
                    fldmensaje_uuid: tableMeta.rowData[0],
                    fldmensaje_nombre: tableMeta.rowData[1],
                    fldmensaje_codigo: tableMeta.rowData[2],
                    fldmensaje_contenido: tableMeta.rowData[3],
                    fldmensaje_activo: tableMeta.rowData[6]
                })} />

                <Delete onClick={() => eliminar(tableMeta.rowData[colDeleteId])} />
            </Stack>
        )
    }
}

function actionsColumnMensajes(editar, eliminar, colDeleteId) {
    return {
        name: "",
        label: "",
        options: {
            viewColumns: false,
            filter: false,
            sort: false,
            customBodyRender: botonesMensajes(editar, eliminar, colDeleteId)
        }
    }
}

export function TableConfigMensaje(atributos, statusTable, editar, eliminar, colDeleteId = 1) {
    let encabezadoTabla = headerConf(atributos, statusTable)
    const boton = actionsColumnMensajes(editar, eliminar, colDeleteId)
    encabezadoTabla.push(boton)
    return encabezadoTabla
}