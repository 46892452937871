import { useEffect, useState } from "react"
import useAnswers from "../../../hooks/Tramites/answers.js/answers"
import { useAppContext } from "../layout"
import { getFilterValues } from "../utils/state"
import { useNavigate } from "react-router-dom"

const useAnswersLogic = () => {

    const { getAnswersData } = useAnswers()
    const store = useAppContext()
    const history = useNavigate()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        tableAmountDay: null,
        tableInfo: null,
        linechart: null
    })

    const filter = (async () => {
        if (store.selected) {
            setLoading(true)

            const result = getFilterValues(store)

            if (result) {
                if (store.open) {
                    store.handleClose()
                }

                const response = await getAnswersData(result)

                if (response) {
                    setData({
                        ...data,
                        tableAmountDay: response.tableAmountDay,
                        tableInfo: response.tableInfo,
                        linechart: response.linechart
                    })
                }
            }

            setLoading(false)
        }
    })

    useEffect(() => {
        if (store?.type?.answers?.value === "F") {
            history('/')
        } else {
            if (store.selected && store?.type?.answers?.value === "T") {
                filter()
            }
        }
    }, [store.selected])

    return {
        loading,
        data,
        filter
    }
}

export default useAnswersLogic
