import React from 'react'
import { Formik, Form } from 'formik'
import { Alert, Grid } from '@mui/material'
import * as yup from 'yup'
import { componentMap } from './ComponentMap'
import styled from "styled-components";
import { isValidDateString } from '../../utils/Date'
import { generateUniqueCode } from '../../utils/keyUnique'

const ContainerQR = styled.div`
  height: auto;
  margin: 0 auto;
  width: 60%;
  border: 1px solid #CDCDCD;
  border-radius: 8px;
  padding: 1em;
  @media (max-width: 1000px) {
    width: 100%;
    height: auto;
    border: none;
  };
  @media (max-width: 325px) {
    overflow-x: auto;
    overflow-y: auto;
    width: calc(100% + ((324px - 100vw) * 1));
    padding: "1em";
  };
`

const QRForm = ({ campos, submitButton, handleSubmit, definedValues = null }) => {

    const valueMap = {
        string: (campo) => ({ [campo.alias]: campo.default || '' }),
        smu: (campo) => {
            const defaultValue = campo.items.find(item => item.id === campo.default)
            return { [campo.alias]: defaultValue ? defaultValue.id : '' }
        },
        date: (campo) => {
            const defaultDate = campo.default || ''
            const isValidDate = isValidDateString(defaultDate)
            return { [campo.alias]: isValidDate ? defaultDate : '' }
        },
        float: (campo) => ({ [campo.alias]: campo.default || '' }),
        text: (campo) => ({ [campo.alias]: campo.default || '' })
    }

    const initialFormValues = campos?.reduce((values, campo) => {
        if (definedValues && (campo.alias in definedValues)) {
            const customValue = definedValues[campo.alias]
            return { ...values, [campo.alias]: customValue }
        }

        const valueInitializer = valueMap[campo.tipo]
        return valueInitializer ? { ...values, ...valueInitializer(campo) } : values
    }, {})

    const validationSchema = yup.object().shape(
        campos.reduce((schema, campo) => {
            const { alias, validacion } = campo

            if (validacion?.toUpperCase() === "R") {
                return { ...schema, [alias]: yup.string().trim().required(`El atributo ${alias} es requerido`).nullable() }
            }

            return schema
        }, {})
    )

    const orderedCampos = campos?.sort((a, b) => {
        const orderA = parseInt(a.orden)
        const orderB = parseInt(b.orden)

        return orderA - orderB
    })

    const existingOrders = new Set()

    orderedCampos?.forEach((campo, index) => {
        const currentOrder = parseInt(campo.orden)

        if (isNaN(currentOrder) || existingOrders.has(currentOrder)) {
            campo.orden = (index + 1).toString()
        }

        existingOrders.add(parseInt(campo.orden))
    })

    const fieldsToValidate = campos?.map(campo => campo.alias)

    return (
        <ContainerQR>
            <Formik
                initialValues={initialFormValues}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >

                {({ errors, touched, _isSubmitting, _values, _formik }) => {
                    const shouldDisplayError = fieldsToValidate.some(field => touched[field] && errors[field])

                    return (
                        <div>
                            <Form>
                                {orderedCampos.map((campo) => {
                                    const Component = componentMap[campo.tipo]
                                    return Component ?
                                        <Component key={"qrfield" + campo.orden} {...campo} />
                                        : null
                                })}

                                {shouldDisplayError && (
                                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                                        {fieldsToValidate.map((field) => touched[field] && errors[field]).map((error, _index) => (
                                            error && <Alert
                                                key={"error" + generateUniqueCode()}
                                                style={{ marginBottom: "5px" }}
                                                severity="error">{error}</Alert>
                                        ))}
                                    </Grid>
                                )}
                                {submitButton}
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </ContainerQR>
    )
}

export default QRForm
