import React from "react"
import { Field } from "formik"
import { Stack } from "@mui/joy"

function CheckBox(props) {
    const { label, name, ...rest } = props

    return (
        <Stack direction="row" spacing={2}>
            <label htmlFor={name}>{label}</label>
            <Field
                type="checkbox"
                name={name}
                id={name}
                {...rest} />
            <br />
        </Stack>
    )
}

export default CheckBox
