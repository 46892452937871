import { Card, CardContent, CardHeader, Checkbox, IconButton, Stack, Tooltip } from "@mui/material"
import { Fragment } from "react"
import BoxQR from "../BoxQR"
import QRContainer from "../QRContainer"
import StyledQRCode from "../StyledQRCode"
import SaveIcon from '@mui/icons-material/Save'
import { TwitterPicker } from 'react-color'
import { Collapse } from "antd"
import iconsQR from "../../utils/iconOptions"
import Select from 'react-select'
import JoditEditor from "jodit-react"
import colorsCircle from "../../../../utils/CirclePickerColor"
import { buttons, precisionOptions, removeButtons, sizeOptions } from "../../utils/qrOptions"
import useQrCodeLogic from "./QrCodeLogic"

const QrCode = ({ value }) => {

    const {
        qrCodeRef,
        hex,
        setHex,
        backgroundHex,
        setBackgroundHex,
        icon,
        size,
        setSize,
        precision,
        setPrecision,
        message,
        setMessage,
        margin,
        downloadQRCode,
        handleIconClick,
        handleFileChange,
        handleCheckboxChange
    } = useQrCodeLogic()

    const items = [
        {
            key: 1,
            label: 'QR',
            children: <div>
                <TwitterPicker
                    colors={colorsCircle}
                    width="100%"
                    color={hex}
                    onChange={(color) => {
                        setHex(color.hex)
                    }}
                />

                <br />

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label for="margin" >Margen</label>
                    <Checkbox
                        id="margin"
                        checked={margin}
                        onChange={handleCheckboxChange}
                    />
                </div>
            </div>
        },
        {
            key: 6,
            label: "Fondo",
            children: <TwitterPicker
                width="100%"
                colors={colorsCircle}
                color={backgroundHex}
                onChange={(color) => {
                    setBackgroundHex(color.hex)
                }}
            />
        },
        {
            key: 2,
            label: "Icono",
            children: <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(5, 1fr)", gap: "5px"
            }}>

                <input
                    id="fileInput"
                    type="file"
                    accept=".png"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                />
                {
                    iconsQR.map((key) => (
                        <div
                            key={key.title}
                            onClick={() => handleIconClick(key.url, key.title)}
                            style={{
                                border: key.url === icon ? "6px solid red" : "4px solid #3765af",
                                margin: "3px", borderRadius: "4px",
                                alignContent: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <img src={key.url}
                                title={key.title} />
                        </div>
                    ))
                }
            </div>
        },
        {
            key: 3,
            label: "Tamaño",
            children: <div>
                <Select
                    defaultValue={sizeOptions[2]}
                    options={sizeOptions}
                    onChange={(sizeQR) => { setSize(sizeQR.value) }}
                />
            </div>
        },
        {
            key: 4,
            label: "Precisión",
            children: <div>
                <Select
                    defaultValue={precisionOptions[0]}
                    options={precisionOptions}
                    onChange={(precisionQR) => { setPrecision(precisionQR.value) }}
                />
            </div>
        },
        {
            key: 5,
            label: "Mensaje",
            children: <div>
                <JoditEditor
                    value={message}
                    showPoweredBy={false}
                    onBlur={(valueMessage) => { setMessage(valueMessage) }}
                    config={{
                        language: "es",
                        debugLanguage: false,
                        i18n: 'es',
                        height: "20em",
                        buttons: buttons,
                        removeButtons: removeButtons
                    }}
                />
            </div>
        }
    ]

    return (
        <Fragment>
            <Stack direction="row" spacing={2} style={{ justifyContent: "center", paddingTop: 15 }}>
                <BoxQR>
                    <Card>
                        <CardHeader
                            action={
                                <IconButton aria-label="download" onClick={downloadQRCode}>
                                    <Tooltip title="Descargar QR"><SaveIcon /></Tooltip>
                                </IconButton>
                            }
                        />

                        <CardContent style={{ maxHeight: "90vh", overflow: "auto" }}>
                            <QRContainer backgroundColor={backgroundHex} ref={qrCodeRef}>
                                <StyledQRCode
                                    value={value}
                                    level={precision}
                                    size={size}
                                    bgColor={"#fff"}
                                    fgColor={hex}
                                    includeMargin={margin}
                                    imageSettings={{
                                        src: icon,
                                        height: 65,
                                        width: 65,
                                        excavate: true
                                    }}
                                />

                                <div style={{
                                    marginTop: "3px",
                                    padding: "15px",
                                    width: "100%",
                                    textAlign: "center",
                                    borderRadius: "24px",
                                    display: "inline-block"
                                }}>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: message }}
                                    />
                                </div>
                            </QRContainer>
                        </CardContent>
                    </Card>
                </BoxQR>

                <div style={{ justifyContent: 'center' }}>
                    <Collapse accordion items={items} defaultActiveKey={['1']} style={{ width: "30em" }} />
                </div>
            </Stack>
        </Fragment>
    )
}

export default QrCode
