const compareValues = (a, b, key, order) => {
    const valueA = parseFloat(a[key])
    const valueB = parseFloat(b[key])

    if (isNaN(valueA) || isNaN(valueB)) {
        if (order === 'asc') {
            return String(a[key]).localeCompare(String(b[key]))
        } else {
            return String(b[key]).localeCompare(String(a[key]))
        }
    }

    if (order === 'asc') {
        if (valueA < valueB) return -1
        if (valueA > valueB) return 1
    } else {
        if (valueA > valueB) return -1
        if (valueA < valueB) return 1
    }
    return 0
}

export const handleSort = (data, filterOptions) => {
    const key = filterOptions.column
    const order = filterOptions.orden
    const items = filterOptions.items

    const sortedData = data.map(obj => Object.assign({}, obj)).sort((a, b) =>
        compareValues(a, b, key, order)
    )

    if (items && items <= sortedData.length) {
        return sortedData.slice(0, items)
    }

    return sortedData
}

export const handleSortNonZero = (data, filterOptions) => {
    const options = { ...filterOptions }

    if (!options.items) {
        options.items = '1'
    }

    return handleSort(data, options)
}
