import { CardOverflow } from "@mui/joy"
import { Card, Stack, Typography } from "@mui/material"
import { useLottie } from "lottie-react"
import animationData from './animation_lldqgrjp.json'

const options = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    style: {
        height: "60%",
        width: "60%"
    },
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
}

const Description = ({ message = "Selecciona un atributo, por favor." }) => {

    const { View } = useLottie(options)

    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        border: "solid 2px #CDCDCD",
        boxShadow: "5px 5px 5px #DCDCDC"
    }}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
            style={{ marginTop: "2em", textAlign: "center", padding: "1.5em" }}
        >
            <Card variant="outlined" sx={{
                width: '60%',
                height: "auto",
                margin: "1em"
            }}>
                <CardOverflow>
                    <Typography
                        level="body6"
                        sx={{
                            color: "#868686",
                            mt: 2,
                            mb: 2,
                            fontFamily: "Arial"
                        }}>
                        {message}
                    </Typography>
                </CardOverflow>
            </Card>
            {View}
        </Stack>
    </div>
}

export default Description
