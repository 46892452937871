const decimalFormat = value => <span>{value?.toFixed(2) ?? 0}</span>
const mainTitle = value => <div align="left">{value}</div>

export const columnsPie = [
    {
        name: "Estado",
        options: {
            customBodyRender: mainTitle
        }
    },
    "Cantidad",
    {
        name: "%",
        options: {
            customBodyRender: decimalFormat
        }
    },
    {
        name: "orden",
        options: {
            display: false,
            viewColumns: false,
            download: false
        }
    }
]

export const columnChart = [
    {
        name: "Estado",
        options: {
            customBodyRender: mainTitle
        }
    },
    "Días"
]
