import { useEffect, useState } from "react";
import getOptionsFilter from "../../services/Tramites/filterFields"

function modifyAttributes(obj) {
    for (let key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
            modifyAttributes(obj[key])
        } else {
            if (key === "id") {
                obj['value'] = obj[key]
                delete obj[key]
            }
        }
    }

    return obj ?? []
}


function FilterValues() {

    const [data, setData] = useState(null)
    const [matriz, setMatriz] = useState(null)
    const [attributes, setAttributes] = useState(null)
    const [years, setYears] = useState(null)
    const [type, setType] = useState("")
    const [metricsInfo, setmetricsInfo] = useState(null)
    const [answersInfo, setAnswersInfo] = useState(null)

    const estado = (states) => {
        const activos = []
        const opciones = []

        for (const state of states) {
            const attributo = { value: state.id, label: state.name }
            opciones.push(attributo)

            if (state.active === "T" && state.final === "F") {
                activos.push(attributo)
            }
        }

        const parametro = { id: "states", name: "Estados", items: opciones }
        const attr = { field_id: "states", items: activos }

        return { parametro, attr }
    }

    useEffect(() => {
        getOptionsFilter().then(result => {

            setType(result.data?.type ?? null)

            const filter = []
            const atributos = []

            if (result.data.states !== undefined) {
                const { parametro, attr } = estado(result.data.states)

                filter.push(parametro)
                atributos.push(attr)
            }

            if (result.data.fields !== undefined) {
                for (const filtro of result.data.fields) {
                    const { id, name } = filtro
                    const opciones = []

                    for (const item of filtro.items) {
                        const value = { value: item.id, label: item.name }
                        opciones.push(value)
                    }

                    const parametro = { id: id, name: name, items: opciones }
                    const attr = { field_id: id, items: [] }
                    filter.push(parametro)
                    atributos.push(attr)

                }

                setData(JSON.stringify(filter))
                setMatriz(atributos)

            } else {
                setData([]);
            }

            if (result.data.attributes !== undefined) {
                const attributes = result.data.attributes
                setAttributes(attributes)
            }

            if (result.data.years) {
                const years = result.data.years
                setYears(years)
            }

            if (result.data.metricsInfo) {
                const metrics = modifyAttributes(result.data.metricsInfo)
                setmetricsInfo(metrics)
            }

            if (result?.data?.answers) {
                const answers = result.data?.answers
                setAnswersInfo(answers)
            }

        }).catch(error => {
            console.log(error);
        })
    }, [])

    return {
        filtro: JSON.parse(data),
        selected: matriz,
        attributes: attributes,
        years: years,
        type: type,
        metricsInfo: metricsInfo,
        answersInfo: answersInfo
    }
}

export default FilterValues
