import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import EsLabels from "./EsLabels"
import * as XLSX from 'xlsx'
import { TableCell, TableFooter, TableRow } from "@mui/material"

const BasicTable = ({
    columns = [],
    data,
    order = ["", "desc"],
    Footer = null,
    customSort = null,
    total = 0,
    nameTable = "file"
}) => {

    let customTableBodyFooterRender;

    if (typeof Footer === "function") {
        customTableBodyFooterRender = () => {
            return <Footer data={data} total={total} />;
        };
    } else if (Array.isArray(Footer)) {
        customTableBodyFooterRender = () => {
            return (
                <TableFooter>
                    <TableRow>
                        {Footer.map((element, index) => (
                            <TableCell align="right" key={index}> {element} </TableCell>
                        ))}
                    </TableRow>
                </TableFooter>
            )
        }
    } else {
        customTableBodyFooterRender = null
    }

    const options = {
        expandableRowsOnClick: false,
        responsive: "standard",
        tableBodyHeight: "estandar",
        tableBodyMaxHeight: "estandar",
        searchPlaceholder: "Buscar",
        filterType: "dropdown",
        search: true,
        download: true,
        filter: false,
        print: false,
        pagination: true,
        selectableRows: "none",
        viewColumns: true,
        textLabels: EsLabels,
        sortOrder: {
            name: order[0],
            direction: order[1]
        },
        downloadOptions: {
            filename: "tabla_personalizada"
        },
        customTableBodyFooterRender: customTableBodyFooterRender,
        customSort: customSort ? customSort : null,
        onDownload: (_buildHead, _buildBody, cols, info) => {
            const filteredColumns = cols?.map((column, index) => !column.download && index).filter(Boolean)

            const header = cols
                .filter((_column, index) => !filteredColumns?.includes(index))
                .map((column) => column.name)

            const nameFilter = nameTable ?? "file"

            const body = info?.map((row) =>
                row.data.filter((_, index) => !filteredColumns?.includes(index))
            )

            const workbook = XLSX.utils.book_new()
            const worksheet = XLSX.utils.aoa_to_sheet([header, ...body])
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
            XLSX.writeFile(workbook, nameFilter + '.xlsx')
            return false
        }
    }

    const style = {
        font: 'Arial, sans-serif'
    }

    const theme = createTheme({
        components: {
            MuiTable: {
                styleOverrides: {
                    root: {
                        marginBottom: "0px"
                    }
                }
            },
            MUIDataTable: {
                styleOverrides: {
                    paper: {
                        borderRadius: '8px'
                    },
                    tableRoot: {
                        fontFamily: style.font
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    footer: {
                        borderRadius: '8px',
                        margin: "0 1em 0 auto",
                        fontFamily: style.font,
                        fontSize: 15,
                        fontWeight: 'bold'
                    }
                }
            },
            MUIDataTableHeadCell: {
                styleOverrides: {
                    root: {
                        fontFamily: style.font,
                        color: "#485261",
                        fontSize: 15
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        width: '100%'
                    }
                }
            },
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        marginTop: "0px",
                        marginBottom: "0px",
                        padding: "3px .5em 3px .5em",
                        fontFamily: style.font,
                        color: "#485261",
                        fontSize: 15,
                        textAlign: "right"
                    }
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        marginTop: "0px",
                        padding: "3px 1em 3px 1em"
                    }
                }
            }
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <MUIDataTable
                data={data}
                columns={columns}
                options={options}
            />
        </ThemeProvider>
    )
}

export default BasicTable
