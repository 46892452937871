import alertify from "alertifyjs"
import { validaDate } from "../../../modules/date"
import { getFieldsSelected } from "../../../modules/chartData"
import { formatDate } from "../../../utils/Date"
import moment from "moment"

export const getFilterState = (store) => {

    const states = []
    const filtro = store.selected

    filtro.forEach(field => {
        if (field['field_id'] === 'states') {

            const items = field['items']

            items.forEach(item => {
                states.push(item.value)
            })
        }
    })

    return states
}

export const attributes = (store) => {
    if (store.attributes) {
        return store.attributes.map((element) => ({
            key: element.id,
            value: `${element.id}*${element.type}`,
            name: `${element.name}`
        }))
    } else {
        return []
    }
}

export const years = (store) => {
    const newArray = store.years.map((element) => ({ name: element }))
    newArray.unshift({ name: 'Ninguno', value: '', key: 'all' })
    return newArray
}

export const getFilterValues = (store) => {
    let statusFilter = false
    const statusDateValidate = validaDate(store.startDate, store.endDate)
    let result = null

    statusFilter = statusDateValidate

    if (store.selected) {

        const states = getFilterState(store)

        if (statusDateValidate && !states.length) {
            alertify.error("Se debe seleccionar al menos un estado")
            statusFilter = false
        }

        if (statusFilter) {
            const fields = getFieldsSelected(store.selected)

            const start_date = store.startDate ? moment(store.startDate?.toString()).format("YYYY-MM-DD") : null
            const end_date = store.endDate ? moment(store.endDate?.toString()).format("YYYY-MM-DD") : null
            const estados = states
            const campos = fields

            result = {
                start_date: start_date,
                end_date: end_date,
                states: estados,
                fields: campos
            }
        }
    }

    return result
}
