import uploadIcon from '../assets/icons/upload-image.png'
import speakIcon from '../assets/icons/speak.png'
import contactIcon from '../assets/icons/contact.png'
import chatIcon from '../assets/icons/chat.png'
import writeIcon from '../assets/icons/write.png'
import notepadIcon from '../assets/icons/notepad.png'
import emailIcon from "../assets/icons/email.png"
import letterIcon from "../assets/icons/letter.png"
import envelopeIcon from '../assets/icons/envelope.png'
import quitIcon from '../assets/icons/quit.png'

const iconsQR = [
    {
        title: "upload",
        url: uploadIcon
    },
    {
        title: "Sin Icono",
        url: quitIcon
    },
    {
        title: "Speak",
        url: speakIcon
    },
    {
        title: "Contact",
        url: contactIcon
    },
    {
        title: "Chat",
        url: chatIcon
    },
    {
        title: "Write",
        url: writeIcon
    },
    {
        title: "Notepad",
        url: notepadIcon
    },
    {
        title: "Email",
        url: emailIcon
    },
    {
        title: "Letter",
        url: letterIcon
    },
    {
        title: "Envelope",
        url: envelopeIcon
    }
]

export default iconsQR
