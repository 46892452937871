export const columsOptions = [
    {
        value: "name",
        label: "Nombre"
    },
    {
        value: "y",
        label: "Cantidad"
    }
]
