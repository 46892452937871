import Cookies from "js-cookie";

const getFieldsDynamic = async () => {
  const route = "contracts";
  const endpoint = localStorage.getItem("api_" + route);
  const url = `${endpoint}config?dynamicTable=true`;
  const instance = localStorage.getItem("instance");

  try {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tkreact_" + instance),
      },
    };

    const response = await fetch(url, config);
    return await response.json();
  } catch (error) {
    return null;
  }
};

export default getFieldsDynamic;
