import { centerCustom } from "../../../components/Chart/Format/format";
import getAnswers from "../../../services/Tramites/answers/getAnswer"

const TRANSLATIONS = {
    "days": "Días",
    "count": "Cantidad"
}

function capitalizeFirstLetter(text) {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

const transformDataStyle = (header) => {

    const transformHeader = header?.map((item) => {
        return {
            name: TRANSLATIONS[item] || capitalizeFirstLetter(item),
            options: {
                customBodyRender: centerCustom
            }
        }
    })

    return transformHeader ?? undefined
}

function areAllElementsNull(array) {
    return array.every((element) => element === null)
}

const useAnswers = () => {

    const convertDataToLinechart = (values) => {
        const data = values?.map(item => {
            return {
                name: item.days,
                y: item.count
            }
        })

        const categories = values?.map((item) => `${item.days}`)
        return { data: [{ name: 'Días', data }], categories }
    }

    const getAnswersData = async (filter = { states: [] }) => {

        const response = await getAnswers(filter)

        function getHeaderTable(data) {
            const attributes = []

            data?.forEach(obj => {
                Object.keys(obj).forEach(attr => {
                    if (!attributes.includes(attr)) {
                        attributes.push(attr)
                    }
                })
            })

            return attributes
        }

        if (response?.status) {
            const amountDay = response.data?.values
            const total = response.data?.total ?? null
            const average = response.data?.average ?? null
            const min = response.data?.min ?? null
            const max = response.data?.max ?? null

            const tableAD = []
            let header

            if (amountDay) {
                header = getHeaderTable(amountDay)

                amountDay.forEach((obj) => {
                    const row = []

                    header?.forEach((attr) => {
                        row.push(obj[attr])
                    })

                    tableAD.push(row)
                })
            }

            const infoData = [[total, average, min, max]]

            return {
                tableAmountDay: {
                    header: transformDataStyle(header),
                    body: tableAD
                },
                tableInfo: {
                    header: transformDataStyle(["Total", "Promedio", "Mínimo", "Máximo"]),
                    body: areAllElementsNull(infoData[0]) ? [] : infoData
                },
                linechart: convertDataToLinechart(amountDay)
            }
        }

        return null
    }

    return { getAnswersData }
}

export default useAnswers
