import { useEffect, useRef, useState } from "react"
import BackgroundVisor from "./BackgroundVisor"
import ImageMapper from "react-img-mapper"
import { useFormikContext } from "formik"
import { SpeedDial, SpeedDialAction } from "@mui/material"
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import Draggable from "react-draggable"
import SettingsIcon from '@mui/icons-material/Settings'
import { Remove } from "@mui/icons-material"
import optionsMode from "./visorModeOptions"
import { confCircleDot, confRectDot, deleteCircleDots, deletePolyDots, deleteRectDots } from "./visorUtils"

const SIZEPOINT = 6
const LINEWIDTH = 6
const COLORPOINT = "TRANSPARENT"

const Visor = (props) => {

    const { setFieldValue } = useFormikContext()
    const [reset, setReset] = useState(true)
    const [mode, setMode] = useState("poly")
    const [isHovered, setIsHovered] = useState(false)
    const imageMapperRef = useRef(null)
    const containerRef = useRef(null)
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

    const { name, coordenates, urlImage, shape } = props

    const URL = urlImage

    const handleContentChange = (value) => {
        setFieldValue(name, value)
        setFieldValue("shape", mode)
    }

    let basePolygonArea = [{
        name: "main",
        shape: mode,
        coords: [],
        preFillColor: "rgba(16, 16,16, 0.4)",
        fillColor: "rgba(16, 16, 16, 0.5)",
        lineWidth: 0.5
    }]

    const modeActive = {
        sx: {
            bgcolor: '#69F0AE',
            '&:hover': {
                bgcolor: '#00C853'
            }
        }
    }

    const [adminLayout, setAdminLayout] = useState({
        name: "adminLayout",
        areas: basePolygonArea
    })

    const handleImageLoad = (_event) => {
        const img = new Image();
        img.src = URL

        img.onload = () => {
            setImageSize({
                ...imageSize,
                width: img.width ?? 0,
                height: img.height ?? 0
            })
        }
    }

    const getRadius = (punto1, punto2) => {
        const distance = Math.sqrt(Math.pow((punto2[0] - punto1[0]), 2) + Math.pow((punto2[1] - punto1[1]), 2))
        return distance ?? 0
    }

    const getSecondDot = (cx, cy, radius) => {

        const theta = 45
        const x = cx + radius * Math.cos(theta * Math.PI / 180)
        const y = cy + radius * Math.sin(theta * Math.PI / 180)

        return [x, y]
    }

    const setModeVisor = (figure) => {
        resetHandler()

        basePolygonArea[0].shape = figure
        setMode(figure)
    }

    function generateUniqueId() {
        return Math.random().toString(36).substring(2) + Date.now().toString(36);
    }

    const makeDot = (evt) => {

        const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY }
        const areasCopy = [...adminLayout.areas]

        function appendDot() {
            areasCopy.push({
                name: generateUniqueId(),
                shape: "circle",
                coords: [coords.x, coords.y, SIZEPOINT],
                preFillColor: COLORPOINT,
                lineWidth: LINEWIDTH
            })
        }

        if (mode === "circle") {
            if (areasCopy.length <= 2) {
                appendDot()
                confCircleDot(areasCopy, getRadius)
            }
        }

        else if (mode === "poly") {
            appendDot()
            areasCopy[0].coords.push(coords.x)
            areasCopy[0].coords.push(coords.y)
        }

        else {
            if (mode === "rect" && areasCopy.length <= 2) {
                appendDot()
                confRectDot(areasCopy)
            }
        }

        const adminLayoutCopy = { ...adminLayout, areas: areasCopy }
        const values = adminLayoutCopy['areas'][0]['coords']
        const coordinates = values.join(",")
        handleContentChange(coordinates)

        setAdminLayout(adminLayoutCopy)
    }

    const deleteLastDot = (_evt) => {

        const areasCopy = [...adminLayout.areas]

        if (areasCopy.length > 0) {
            switch (mode) {
                case "poly":
                    deletePolyDots(areasCopy)
                    break;
                case "rect":
                    deleteRectDots(areasCopy)
                    break;
                case "circle":
                    deleteCircleDots(areasCopy)
                    break
                default:
                    break
            }

            const adminLayoutCopy = { ...adminLayout, areas: areasCopy }

            const values = adminLayoutCopy['areas'] ?? [0]['coords']
            const coordinates = values.join(",")
            handleContentChange(coordinates)
            setAdminLayout(adminLayoutCopy)
        }
    }

    const resetHandler = () => {
        const resetArea = [...basePolygonArea]

        const adminLayoutCopy = { ...adminLayout, areas: resetArea }
        setAdminLayout(adminLayoutCopy)
        handleContentChange("")

        setReset(!reset)
    }

    const makeDotInitial = (puntos, figure) => {

        resetHandler()
        let areasCopy = [...adminLayout.areas]
        areasCopy[0].shape = figure

        const newArray = []

        for (const element of areasCopy) {
            if ((element.shape === 'poly' || element.shape === "rect")
                || (element.shape === "circle" && element.name === "main")
            ) {
                newArray.push(element)
            }
        }

        if (newArray[0]) {

            newArray[0].coords = []
            areasCopy = newArray

            if (figure === 'poly' || figure === "rect") {
                puntos.forEach(element => {
                    const coords = { x: element.x, y: element.y }

                    createDot(coords.x, coords.y)

                    areasCopy[0].coords.push(coords.x)
                    areasCopy[0].coords.push(coords.y)
                })
            } else {
                if (figure === "circle") {
                    const secondDot = getSecondDot(puntos[0], puntos[1], puntos[2])
                    createDot(puntos[0], puntos[1])
                    createDot(secondDot[0], secondDot[1])

                    puntos.forEach((dot) => {
                        areasCopy[0].coords.push(dot)
                    })
                }
            }

            const adminLayoutCopy = { ...adminLayout, areas: areasCopy }

            const values = adminLayoutCopy['areas'][0]['coords']
            const coordinates = values.join(",")
            handleContentChange(coordinates)
            setAdminLayout(adminLayoutCopy)
        }

        function createDot(dotone, dottwo) {
            areasCopy.push({
                name: generateUniqueId(),
                shape: "circle",
                coords: [dotone, dottwo, SIZEPOINT],
                preFillColor: COLORPOINT,
                lineWidth: LINEWIDTH
            })
        }
    }

    const handleClick = (coords, figure) => {
        const coordenadas = []
        setMode(figure)

        if (figure === 'poly' || figure === "rect") {
            for (let i = 0; i < coords.length; i += 2) {
                coordenadas.push({ x: coords[i], y: coords[i + 1] })
            }
            makeDotInitial(coordenadas, figure)
        } else {
            if (figure === "circle") {
                makeDotInitial(coords, figure)
            }
        }
    }

    const updateCoordinates = (targetName, newCoords) => {

        const areas = [...adminLayout.areas]

        return areas.map((area) => {
            if (area.name === targetName) {
                return {
                    ...area,
                    coords: newCoords
                };
            }
            return area
        })
    }

    const handleDragStop = (e, idPoint) => {
        const { clientX, clientY } = e
        const containerRect = e.target.offsetParent.getBoundingClientRect()
        const x = clientX - containerRect.left
        const y = clientY - containerRect.top

        const updatedAreas = updateCoordinates(idPoint, [x, y, SIZEPOINT])

        const coordinatesArray = []

        if (mode === 'rect' || mode === "poly") {
            for (const element of updatedAreas) {
                const area = element;
                if (area.name !== "main") {
                    const coords = area.coords.slice(0, 2)
                    coordinatesArray.push(...coords)
                }
            }
        }

        else {
            if (mode === "circle" && updatedAreas.length === 3) {
                const dotone = updatedAreas[1]['coords']
                const dottwo = updatedAreas[2]['coords']

                if (dotone && dottwo) {
                    const radius = getRadius(dotone, dottwo)
                    const center = dotone.slice(0, 2)

                    coordinatesArray.push(...center)
                    coordinatesArray.push(radius)
                }
            }
        }

        updatedAreas[0]['coords'] = coordinatesArray
        const adminLayoutCopy = { ...adminLayout, areas: updatedAreas }

        const coordinates = coordinatesArray.join(",")
        handleContentChange(coordinates)
        setAdminLayout(adminLayoutCopy)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Delete') {

            if (mode === "rect" || mode === "circle") {
                resetHandler()
            } else {
                if (mode === "poly") {
                    deleteLastDot()
                }
            }
        }
    }

    useEffect(() => {
        setReset(true)
        containerRef.current?.focus()
    }, [reset])

    useEffect(() => {
        if (coordenates !== '' && coordenates.length) {
            handleClick(coordenates, shape)
        } else {
            resetHandler()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coordenates])

    return (
        <div ref={containerRef} tabIndex={1} onKeyDown={handleKeyDown}>
            <BackgroundVisor>
                <Draggable>
                    <SpeedDial
                        ariaLabel="visor-options"
                        sx={{ bottom: 16, right: 16 }}
                        style={{ position: "absolute" }}
                        icon={<SettingsIcon />}
                    >
                        <SpeedDialAction
                            key="limpiar"
                            icon={<CleaningServicesOutlinedIcon />}
                            tooltipTitle="Limpiar coordenadas"
                            onClick={() => resetHandler()}
                        />

                        <SpeedDialAction
                            key="deletedot"
                            icon={<Remove />}
                            tooltipTitle="Eliminar el último punto"
                            onClick={() => deleteLastDot()}
                        />

                        {optionsMode.map(({ key, icon, tooltip, modeVisor }) => (
                            <SpeedDialAction
                                key={key}
                                icon={icon}
                                tooltipTitle={tooltip}
                                onClick={() => setModeVisor(modeVisor)}
                                FabProps={modeVisor === mode ? modeActive : {}}
                            />
                        ))}
                    </SpeedDial>
                </Draggable>

                <div style={{ position: "relative", margin: "0 auto" }}>
                    {adminLayout.areas.slice(1).map((area, _index) => (
                        <Draggable
                            key={area.name}
                            defaultPosition={{ x: area.coords[0] - SIZEPOINT, y: area.coords[1] - SIZEPOINT }}
                            onStop={(e, _data) => handleDragStop(e, area.name)}
                            bounds={{ left: 0, top: 0 }}
                        >
                            <span
                                style={{
                                    position: 'absolute',
                                    backgroundColor: 'red',
                                    width: '12px',
                                    height: '12px',
                                    borderRadius: '50%',
                                    zIndex: 100,
                                    cursor: "pointer",
                                    ...(isHovered && {
                                        backgroundColor: '#00C853'
                                    })
                                }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            />
                        </Draggable>

                    ))}

                    <ImageMapper
                        src={URL}
                        containerRef={imageMapperRef}
                        map={adminLayout}
                        onImageClick={(evt) => makeDot(evt)}
                        onClick={(_area, _, evt) => makeDot(evt)}
                        lineWidth={3}
                        strokeColor={"rgba(255, 255, 255, 0.1)"}
                        toggleHighlighted={true}
                        onLoad={handleImageLoad}
                    />
                </div>
            </BackgroundVisor>
        </div >
    )
}

export default Visor
