import { Fragment, useRef } from "react"
import MuiTable from "../../../components/Table/MuiTable"
import MapLeaf from "../../../components/Map.js/map"
import Filtro from "../filter"
import Loading from "../../../components/Loading/Loading"
import useMapTramitesLogic from "./useMapTramitesLogic"

const MapTramitesSection = () => {
    const {
        center,
        markers,
        popupData,
        columns,
        data,
        coordUbicated,
        loading,
        options,
        tableRef,
        filtrarCoordenadas,
        handlePopupClick,
        recenter
    } = useMapTramitesLogic()

    const mapRef = useRef(null)

    if (!markers || loading) {
        return <Loading />
    }

    return (
        <Fragment>
            <Filtro filtrar={filtrarCoordenadas} />

            <MapLeaf
                center={center}
                markers={markers.length ? markers : null}
                mapRef={mapRef}
                handlePopupClick={handlePopupClick}
                coordUbicated={coordUbicated}
                popupData={popupData}
                recenter={recenter}
            />

            <MuiTable
                title=""
                header={columns || []}
                data={data || []}
                options={options}
                reference={tableRef}
            />
        </Fragment>
    )
}

export default MapTramitesSection
