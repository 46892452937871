import Swal from 'sweetalert2'
import alertify from "alertifyjs"

export function confirmDialog(title, text, confirmText = "Aceptar", cancelText = "Cancelar") {
    return Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showClass: {
            backdrop: 'swal2-noanimation',
            popup: '',
            icon: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#008F39',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
        focusCancel: true,
        customClass: ".sweet-alert button"
    })
}

export function successDialog(text) {
    alertify.success(text)
}

export function errorDialog(text) {
    alertify.error(text)
}

export function warningDialog(text) {
    alertify.warning(text)
}
