import { Fragment } from "react"
import FilterContract from "../FilterContract"
import BasicTable from "../../../components/Table/BasicTable"
import Loading from "../../../components/Loading/Loading"
import useContractorLogic from "./useContractorLogic"
import NotData from "../../../components/Chart/NoData/NotData"
import { convertMoneyFormat } from "../../../components/Chart/Format/format"
import HorizontalBar from "../../../components/Chart/HorizontalBar/HorizontalBar"
import { columsContratorOptions } from "./columnContractOption"

function isEmptyDict(obj) {
    if (obj) {
        return Object.keys(obj).length === 0
    }

    return true
}

const Contractor = () => {
    const {
        statesData,
        loading,
        filter
    } = useContractorLogic()

    const customTooltip = {
        formatter: function () {
            const { color, point: { category, percent_contracts, value, percent_value } } = this
            const performatted_contracts = convertMoneyFormat(percent_contracts)
            const performatted_value = convertMoneyFormat(percent_value)
            const contracts_value = convertMoneyFormat(value)

            return `
                <span style="color:${color}">\u25CF</span>
                <span style="font-size: 10px">${category}</span><br/>
                <span style="color:${color}">Contratos:</span> <b>${this.y} (${performatted_contracts}%)</b>
                <br/>
                <span style="color:${color}">Valor:</span> <b>${contracts_value} (${performatted_value}%)</b>
            `
        }
    }

    if (!statesData || loading) {
        return <Loading />
    }

    return (
        <Fragment>
            <FilterContract filter={filter} />

            {
                (isEmptyDict(statesData?.table)) && (
                    <NotData />
                )
            }

            {
                !isEmptyDict(statesData?.table) && (
                    <div>
                        <HorizontalBar
                            title="Contratistas"
                            data={statesData?.chart}
                            tooltip={customTooltip}
                            columsOptions={columsContratorOptions}
                        />

                        <BasicTable
                            columns={statesData.table.header}
                            data={statesData.table.body}
                            Footer={statesData.table.footer}
                        />
                    </div>
                )
            }

        </Fragment>
    )
}

export default Contractor
