export default async function QueryServiceJWT(props) {
  const endpoint = localStorage.getItem("baseuri");
  const url = endpoint + "commons/code";

  const headers = {
    "Content-Type": "application/json",
    instance: props.instance,
  };

  const body = {
    codigo: props.codigo,
  };

  const js = JSON.stringify(body);

  try {
    const config = { method: "POST", headers: headers, body: js };
    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
