import { Fragment } from "react"
import Loading from "../../../components/Loading/Loading"
import useAnswersLogic from "./AnswerLogic"
import { useAppContext } from "../layout"
import Filtro from "../filter"
import BasicTable from "../../../components/Table/BasicTable"
import LineChartBasic from "../../../components/Chart/LineChartBasic"

const AnswersSection = () => {

    const {
        loading,
        data,
        filter
    } = useAnswersLogic()

    const store = useAppContext()

    const customTooltip = {
        formatter: function () {
            const pointMarker = `<span style="color:${this.series.color}">\u25CF</span>`
            const xValue = `<b>${this.x}</b>`
            const seriesName = this.series.name
            const yValue = `<b>${this.y}</b>`

            return `${pointMarker} Trámites: ${yValue}<br/>${seriesName}: ${xValue}`
        }
    }

    if (loading) {
        return <Loading />
    }

    return (
        <Fragment>

            {
                store.selected && (
                    <Filtro filtrar={() => filter()} />
                )
            }

            <LineChartBasic
                data={data.linechart}
                title={"Salidas " + (store?.type?.name ?? '')}
                tooltip={customTooltip}
                yAxisTitle="Cantidad"
            />

            <BasicTable
                columns={data.tableAmountDay?.header}
                data={data.tableAmountDay?.body}
            />

            <br />

            <BasicTable
                columns={data.tableInfo?.header}
                data={data.tableInfo?.body}
            />

        </Fragment>
    )
}

export default AnswersSection
