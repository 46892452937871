import { getAuthEndpoint, getEndpoint, getToken } from './credentials'

const eliminarMensaje = async (id) => {

    const endpoint = `${getEndpoint()}message/${id}`
    const me = getAuthEndpoint()

    try {
        const config = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken(),
                "Permission": me
            }
        }

        const response = await fetch(endpoint, config)
        const json = await response.json()
        return json ?? undefined
    } catch (error) {
        console.log(error)
        return null
    }
}

export default eliminarMensaje
