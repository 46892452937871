const header = [
  {
    name: "Ip",
  },
  {
    name: "Id_regla",
    display: false,
  },
  {
    name: "Nombre",
  },
  {
    name: "Fecha",
  },
  {
    name: "Usuario",
  },

  {
    name: "Id",
    display: false,
  },
  {
    name: "Fecha de expiración",
  },
];

export default header;
