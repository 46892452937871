import { Box } from "@mui/joy"

const BackgroundVisor = ({ children }) => {
    return (
        <Box style={{
            border: "1px solid",
            padding: "2px",
            borderColor: "#F4F4F4",
            backgroundImage: `url(https://www.pngfind.com/pngs/m/333-3330324_imagenes-en-png-con-fondo-transparente-johns-hopkins.png)`,
            background: {
                backgroundSize: 'cover',
                backgroundRepeat: 'repeat',
                backgroundColor: '#fff'
            },
            width: "auto",
            display: 'flex',
            position: "relative",
            overflow: 'scroll',
            cursor: 'crosshair',
            minHeight: "auto",
            maxHeight: "42em"
        }}>
            {children}
        </Box>
    )
}

export default BackgroundVisor
