import { useState, Fragment, useRef, useEffect } from "react";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Stack, Alert } from "@mui/material";
import Modal from "react-responsive-modal";
import DynamicStepsForm from "./dynamicStepsForm";
import ButtonDelete from "../../components/Button/ButtonDelete";
import SelectValue from "../../components/Select/Select";
import styled from "styled-components";
import PivotTableUI from "react-pivottable/PivotTableUI";
import TableRenderers from "react-pivottable/TableRenderers";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ErrorArchivo from "../../components/404/ErrorArchivo";
import animationData from "../../components/404/Nodata.json";

const ContainerTable = styled.div`
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  padding-right: 20px;
  padding-left: 0px;
  width: 100%;
  height: 80vh;
`;

const PivotTableWrapper = ({
  data,
  fieldOptions,
  options,
  table,
  state,
  handleChange,
  saveConfTable,
  deleteTable,
  changeState,
  usePivotTableFormLogic,
}) => {
  const PlotlyRenderers = createPlotlyRenderers(Plot);
  console.error = console.warn;

  const [open, setOpen] = useState(false);
  const [showdownload, setshowdownload] = useState(false);
  const tableRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const eliminarPrimerasFilas = (sheets) => {
    const numFilasEliminar = 3;

    for (const sheetName in sheets) {
      if (sheets.hasOwnProperty(sheetName)) {
        const sheet = sheets[sheetName];

        const merges = sheet["!merges"] || [];
        const dataValidations = sheet["!dataValidations"] || [];

        const range = XLSX.utils.decode_range(sheet["!ref"]);

        range.s.r += numFilasEliminar;
        range.e.r = Math.max(range.s.r, range.e.r);

        sheet["!merges"] = merges;
        sheet["!dataValidations"] = dataValidations;

        const newSheet = {
          ...sheet,
          "!ref": XLSX.utils.encode_range(range),
        };

        sheets[sheetName] = newSheet;
      }
    }
  };

  const convertToXLSX = () => {
    const tableReference = document.getElementById("miTabla");
    const workbook = XLSX.utils.table_to_book(tableReference);

    const sheets = workbook.Sheets;

    eliminarPrimerasFilas(sheets);
    const newWorkbook = {
      SheetNames: workbook.SheetNames,
      Sheets: sheets,
    };

    const xlsxBuffer = XLSX.write(newWorkbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([xlsxBuffer], { type: "application/octet-stream" });
    saveAs(blob, "tabla dinámica.xlsx");
  };

  useEffect(() => {
    if (
      options?.length &&
      (state?.rendererName === "Table" || state?.rendererName === undefined)
    ) {
      setshowdownload(true);
    } else {
      setshowdownload(false);
    }
  }, [state, options]);

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        closeOnOverlayClick={false}
        center
      >
        <Grid container spacing={2}>
          <DynamicStepsForm
            numerics={fieldOptions?.numerics}
            fields={fieldOptions?.fields}
            closeModal={handleClose}
            saveTableConf={saveConfTable}
            usePivotTableFormLogic={usePivotTableFormLogic}
          />
        </Grid>
      </Modal>

      <Grid container>
        <Grid item xs={12} md={12} style={{ paddingBottom: "10px" }}>
          <Button
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleOpen}
            style={{ marginRight: "10px", marginBottom: "10px" }}
          >
            Crear tabla dinámica
          </Button>

          {tableRef && showdownload && (
            <Button
              variant="outlined"
              size="small"
              startIcon={<CloudDownloadIcon />}
              onClick={convertToXLSX}
              style={{ marginBottom: "10px" }}
            >
              Descargar tabla dinámica
            </Button>
          )}
        </Grid>

        <Grid item xs={12} style={{ paddingBottom: "20px" }}>
          <Stack direction="row" spacing={2} style={{ alignItems: "center" }}>
            <SelectValue
              label="Tablas"
              options={options}
              value={table}
              onChange={handleChange}
            />

            {state?.id && (
              <ButtonDelete
                onClick={deleteTable}
                title="Eliminar tabla dinámica"
              />
            )}
          </Stack>
        </Grid>

        <br />

        {data.length > 0 ? (
          <Grid item xs={12}>
            <ContainerTable>
              {state && (
                <div>
                  <div ref={tableRef} id="miTabla">
                    <PivotTableUI
                      data={data}
                      renderers={Object.assign(
                        {},
                        TableRenderers,
                        PlotlyRenderers
                      )}
                      onChange={(s) => {
                        changeState(s);
                      }}
                      {...state}
                    />
                  </div>
                </div>
              )}
            </ContainerTable>
          </Grid>
        ) : (
          <div align="center">
            <ErrorArchivo animationData={animationData} />
            <Alert
              variant="filled"
              align="center"
              sx={{ width: 300 }}
              severity="error"
            >
              No se encontró información
            </Alert>
          </div>
        )}
      </Grid>
    </Fragment>
  );
};

export default PivotTableWrapper;
