import decipher from "./Utils/JWT"

const deleteArea = async (iddelete) => {

    const datadecode = localStorage.getItem('data')
    const { id, token, endpoint, jwt } = decipher(datadecode)
    const problems = []

    for (const element of iddelete) {
        const url = `${endpoint}/area/${element}/${id}/${token}`

        try {
            const config = {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + jwt
                },
            }

            const response = await fetch(url, config)
            const json = await response.json()
            if (!json.status) {
                problems.push(json)
            }
        } catch (error) {
            console.log(error)
        }
    }

    if (problems.length > 0) {
        return {
            status: false,
            message: "Se ha presentado un error al eliminar algunas de las áreas"
        }
    } else {
        return {
            status: true,
            message: "Las áreas seleccionadas se han eliminado correctamente"
        }
    }
}

export default deleteArea