import { getAuthEndpoint, getEndpoint, getToken } from './credentials'

const getMensaje = async (id) => {
    if (id !== undefined || id !== null) {

        const endpoint = `${getEndpoint()}message/${id}`
        const me = getAuthEndpoint()

        try {
            const config = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + getToken(),
                    "Permission": me
                }
            }

            const response = await fetch(endpoint, config)
            const json = await response.json()
            return json ?? undefined
        } catch (error) {
            throw Error('Se ha producido un error durante el fetch')
        }
    } else {
        throw Error('El id es requerido')
    }
}

export default getMensaje
