import { Alert, Grid} from "@mui/material"

export const CardDescriptionContent = ({ children }) => {
    return (
        <Grid item xs={10} md={10}>
            {children}
        </Grid>
    )
}

const CardButtonDescription = ({ mensajes, value }) => {
    return (
        <Alert severity="info">{value ? mensajes.activa : mensajes.inactiva}</Alert>
    )
}

CardDescriptionContent.Description = CardButtonDescription

export default CardDescriptionContent