import { useEffect, useState } from "react"
import { Menu, Sidebar, MenuItem, useProSidebar } from "react-pro-sidebar"

import { useLocation, useNavigate } from "react-router-dom"
import { useTheme, Box, Typography, IconButton } from "@mui/material"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import FactCheckIcon from '@mui/icons-material/FactCheck'
import BusinessIcon from '@mui/icons-material/Business'
import HailIcon from '@mui/icons-material/Hail'
import {
    FilterList,
    PivotTableChart
} from "@mui/icons-material"
import { useSidebarContext } from "./SidebarContractProvider"
import { tokens } from "../../../components/Sidebar/theme"
import { useAppContext } from "../layout"

const EQUIVALENCES = {
    "": "Estados",
    "dynamictable": "Tabla dinámica",
    "areas": "Área",
    "fields": "Campos",
    "contractors": "Contratistas"
}

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return (
        <MenuItem
            active={selected === title}
            style={
                selected === title
                    ? { color: colors.redAccent[500] }
                    : { color: colors.grey[100] }}
            onClick={() => {
                if (title !== 'Filtrar') {
                    setSelected(title)
                }

                to()
            }}
            icon={icon}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    )
}

const SidebarContract = ({ filter }) => {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const route = queryParams.get('route')

    const currentUrl = window.location.href
    const urlLink = new URL(currentUrl)

    const section = (urlLink.pathname).slice(1) ?? 'Contractos'

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [selected, setSelected] = useState(EQUIVALENCES[section] || '')
    const { sidebarRTL, sidebarImage } = useSidebarContext()
    const { collapseSidebar, collapsed } = useProSidebar()
    const navigate = useNavigate()
    const store = useAppContext()

    const bgTransparent = "transparent !important"

    const redirigir = (url) => {
        return navigate(url)
    }

    const itemsToShow = [
        {
            title: "Filtrar",
            to: () => filter(),
            icon: <FilterList titleAccess="Filtrar" />,
            show: true
        },
        {
            title: "Estados",
            to: () => redirigir("/?route=" + route),
            icon: <HomeOutlinedIcon titleAccess="Estados" />,
            show: true
        },
        {
            title: "Área",
            to: () => redirigir("/areas?route=" + route),
            icon: <BusinessIcon titleAccess="Área" />,
            show: true
        },
        {
            title: "Contratistas",
            to: () => redirigir("/contractors?route=" + route),
            icon: <HailIcon titleAccess="Contratistas" />,
            show: store.showSection?.contractors === 'T'
        },
        {
            title: "Tabla dinámica",
            to: () => redirigir("/dynamictable?route=" + route),
            icon: <PivotTableChart titleAccess="Tabla dinámica" />,
            show: true
        },
        {
            title: "Campos",
            to: () => redirigir("/fields?route=" + route),
            icon: <FactCheckIcon titleAccess="Campos" />,
            show: true
        }
    ]

    const itemsNav = itemsToShow?.filter((item) => item.show).map((item) => (
        <Item
            key={item.title}
            title={item.title}
            to={item.to}
            icon={item.icon}
            selected={selected}
            setSelected={setSelected}
        />
    ))

    useEffect(() => {
        if (!collapsed) {
            collapseSidebar()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box
            sx={{
                position: "sticky",
                display: "flex",
                height: "100vh",
                top: 0,
                bottom: 0,
                zIndex: 800,
                "& .sidebar": {
                    border: "none"
                },
                "& .menu-icon": {
                    backgroundColor: bgTransparent
                },
                "& .menu-item": {
                    backgroundColor: bgTransparent
                },
                "& .menu-anchor": {
                    color: "inherit !important",
                    backgroundColor: bgTransparent
                },
                "& .menu-item:hover": {
                    color: `${colors.blueAccent[500]} !important`,
                    backgroundColor: bgTransparent
                },
                "& .menu-item.active": {
                    color: `${colors.greenAccent[500]} !important`,
                    backgroundColor: bgTransparent
                }
            }}
        >
            <Sidebar
                breakPoint="md"
                rtl={sidebarRTL}
                backgroundColor={colors.primary[400]}
                image={sidebarImage}
            >
                <Menu iconshape="square">
                    <MenuItem
                        icon={
                            collapsed && (
                                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
                            )
                        }
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100]
                        }}
                    >
                        {!collapsed && (
                            <Box
                                display="fixed"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography variant="h3" color={colors.grey[100]}>
                                </Typography>
                                <IconButton
                                    onClick={
                                        () => collapseSidebar()
                                    }
                                >
                                    <CloseOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    <Box paddingLeft={collapsed ? undefined : "10%"}>{itemsNav}</Box>
                </Menu>
            </Sidebar>
        </Box>
    )
}

export default SidebarContract
