import React from "react"
import { Field } from "formik"
import './estilos.css'
import AsteriskRequired from "./Required/RequiredField"

function Textarea(props) {
    const { label, name, required, ...rest } = props

    return (
        <div className="form-control">
            <label htmlFor={name}>
                {label}
                {required && (<AsteriskRequired />)}
            </label>
            <Field
                className="my-input"
                as="textarea"
                id={name}
                name={name}
                {...rest}
                rows={10}
            />
            <br />
        </div>
    )
}

export default Textarea
