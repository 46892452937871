import React, { useRef, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import routes from "./routes/Routes";
import QueryServiceJWT from "./QueryServiceJWT";

function confirmQueryParameters(parameters) {
  for (const parametro of parameters) {
    if (!localStorage.getItem(parametro)) {
      return false;
    }
  }

  return true;
}

function localStorageQuery(parameters, urlparams) {
  for (const parametro of parameters) {
    if (urlparams.get(parametro)) {
      if (parametro === "api") {
        localStorage.setItem(
          "api_" + urlparams.get("route"),
          urlparams.get(parametro)
        );
      } else {
        localStorage.setItem(parametro, urlparams.get(parametro));
      }
    }
  }
}

const parametros = [
  "api",
  "application",
  "endpoint",
  "host",
  "instance",
  "ip",
  "route",
  "id",
  "back",
  "data",
  "md",
  "codigo",
  "baseuri",
];
const params = new URLSearchParams(window.location.search);

localStorageQuery(parametros, params);
let link = params.get("route") || localStorage.getItem("route");

const Routing = () => {
  const [matchedRoute, setMatchedRoute] = useState({
    component: null,
    additionalRoutes: [],
  });
  const tokenCookie = useRef("");
  const config = routes ?? {};

  function confirmCookiesParameters() {
    const instance = localStorage.getItem("instance");
    const tkreact = "tkreact_" + instance;
    const token = localStorage.getItem(tkreact);
    return token ?? tokenCookie.current;
  }

  const shouldMatchQueryParams = (queryParams) => {
    return !queryParams || confirmQueryParameters(queryParams);
  };

  const shouldMatchRequiresCookies = (requiresCookies) => {
    return !requiresCookies || confirmCookiesParameters();
  };

  const isMatchingRoute = (key) => {
    const { queryParams, requiresCookies } = config[key];
    return (
      link === key &&
      shouldMatchQueryParams(queryParams) &&
      shouldMatchRequiresCookies(requiresCookies)
    );
  };

  const findMatchingRoute = async () => {
    const instance = localStorage.getItem("instance");
    const codigo = localStorage.getItem("codigo");
    const tkreact = "tkreact_" + instance;

    try {
      if (params.get("route") !== "qr_react") {
        const result = await QueryServiceJWT({
          codigo: codigo,
          instance: instance,
        });

        if (result.jwt !== undefined) {
          localStorage.setItem(tkreact, result.jwt);
        }
      }
      for (const key in config) {
        if (config.hasOwnProperty(key) && isMatchingRoute(key)) {
          return config[key];
        }
      }
    } catch (error) {
      console.error("Error al obtener JWT:", error);
    }

    return config.default;
  };

  useEffect(() => {
    const getMatchedRoute = async () => {
      const route = await findMatchingRoute();
      setMatchedRoute(route);
    };

    getMatchedRoute();
  }, []);

  const { component, additionalRoutes } = matchedRoute;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={component} />
        {additionalRoutes?.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  document.title = "Sistema de Gestión Integral - Almera";

  return <Routing />;
}

export default App;
