import * as React from 'react';
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MoreIcon from '@mui/icons-material/MoreVert'
import { AddTask, ArrowBack } from '@mui/icons-material'
import Tooltip from '@mui/joy/Tooltip'
import ArchitectureOutlinedIcon from '@mui/icons-material/ArchitectureOutlined'

export default function NavbarVisor(props) {

    const { newArea, comeback, randomAreas, totalAreas } = props

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Regresar</MenuItem>
            <MenuItem onClick={handleMenuClose}>Nueva área</MenuItem>
        </Menu>
    )

    const mobileMenuId = 'primary-search-account-menu-mobile'
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton
                    size="large"
                    color="inherit"
                    onClick={() => newArea()}
                >
                    <AddTask />
                </IconButton>
                <p>Nueva área</p>
            </MenuItem>
        </Menu>
    )

    return (
        <Box sx={{ flexGrow: 1, marginBottom: "5em" }}>
            <AppBar position="fixed" style={{ backgroundColor: "white" }}>
                <Toolbar>
                    <Tooltip
                        title="Regresar"
                        variant='solid'
                        size='md'
                        placement="bottom-end"
                    >
                        <IconButton
                            size="large"
                            edge="start"
                            sx={{ mr: 2 }}
                            onClick={() => comeback()}
                        >
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title="Nueva área"
                        variant='solid'
                        size='md'
                        placement="bottom-end"
                    >
                        <IconButton
                            size="large"
                            onClick={() => newArea()}
                        >
                            <AddTask />
                        </IconButton>
                    </Tooltip>

                    {
                        totalAreas ? null : <Tooltip
                            title="Áreas automáticas"
                            variant='solid'
                            size='md'
                            placement="bottom-end"
                        >
                            <IconButton
                                size="large"
                                onClick={() => randomAreas()}
                            >
                                <ArchitectureOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    }

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </Box>
    )
}