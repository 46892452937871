import { Modal } from "antd"

const ModalGenericFilter = ({ children, open, handleClose, filtrar }) => {

    return (
        <Modal
            title="Filtros"
            open={open}
            width={700}
            zIndex={900}
            bodyStyle={{
                maxHeight: "70vh",
                overflowY: "auto",
                overflowX: "auto",
                paddingRight: "20px",
                position: "relative"
            }}
            okText="Filtrar"
            cancelText="Cancelar"
            onOk={filtrar}
            onCancel={handleClose}
            transitionName=""
            maskTransitionName=""
            okButtonProps={{ style: { background: '#008f39', color: '#fff', width: "8em" } }}
        >
            {children}
        </Modal>
    )
}

export default ModalGenericFilter
