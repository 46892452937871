import { formatNumberSeparateMiles } from "../../../utils/Number";

export const convertMoneyFormat = (value) => {
  const roundedValue = value?.toFixed(2);
  const integerPart = parseInt(roundedValue);
  const decimalPart = parseInt((roundedValue - integerPart) * 100);
  const displayValue = decimalPart !== 0 ? roundedValue : integerPart;

  const output = formatNumberSeparateMiles(displayValue);
  return output ?? "0";
};

export const moneyFormat = (value) => {
  const output = convertMoneyFormat(value);
  if (output !== null && output !== "NaN") {
    return <span>${output}</span>;
  } else {
    return <span>$0</span>;
  }
};

export const decimalFormat = (value) => {
  const output = convertMoneyFormat(value);
  return <span>{output}</span>;
};

export const decimalFormatInteger = (value) => {
  const output = formatNumberSeparateMiles(value);
  return <span>{output}</span>;
};

export const percentValue = (value) => {
  if (value !== null && value !== "NaN") {
    return <span>{value}%</span>;
  } else {
    return <span> 0%</span>;
  }
};

export const percentDecimalValue = (value) => {
  const output = formatNumberSeparateMiles(value);
  return <span>{output}%</span>;
};

export const nameCustom = (data) => {
  return <div align="left">{data}</div>;
};

export const centerCustom = (data) => {
  return <div align="center">{data}</div>;
};
