import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"
import Draggable from "react-draggable"

const DraggableAction = ({ actions }) => {

    return (
        <Draggable>
            <SpeedDial ariaLabel="SpeedDial basic example" sx={{
                position: 'absolute',
                bottom: 16,
                right: 16
            }} icon={<SpeedDialIcon />}>
                {actions.map(action => <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.onClick}
                    disabled={action.disabled}
                />)}
            </SpeedDial>
        </Draggable>
    )
}

export default DraggableAction
