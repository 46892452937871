import Cookies from "js-cookie";

const getDynamicTableData = async (tableId, filter) => {
  const route = "contracts";
  const endpoint = localStorage.getItem("api_" + route);
  const url = endpoint + "dynamicTable";
  const instance = localStorage.getItem("instance");

  try {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tkreact_" + instance),
      },
      body: JSON.stringify({
        dynamicTableId: tableId,
        filter: filter,
      }),
    };

    const response = await fetch(url, config);
    return await response.json();
  } catch (error) {
    return null;
  }
};

export default getDynamicTableData;
