import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material"
import { Chip } from "@mui/material";


const header = [
    {
        name: "id",
        display: false
    },
    {
        name: "nombre",
        style: {
            textAlign: "justify",
        }
    },
    {
        name: "codigo",
        label: "código"
    },
    {
        name: "activo",
        label: "estado",
        bodyRender: (data, _dataIndex, _rowIndex) => {

            const estado = data === "Activa" ? true : false

            return (
                <Chip
                    variant="outlined"

                    size="md"
                    style={estado
                        ? { backgroundColor: "#00c853", color: "#ffffff", border: "none" }
                        : { backgroundColor: "#f44336", color: "#ffffff", border: "none" }
                    }
                    label={data}
                    icon={
                        estado ?
                            <CheckCircleOutline style={{ color: "white" }} />
                            : <ErrorOutline style={{ color: "white" }} />
                    }
                />
            )
        }
    }
]

export default header
