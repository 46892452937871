import React, { useEffect, useState } from "react";

import inactivar from "../services/Instancias/inactivar";
import activar from "../services/Instancias/activar";

import { Alert, Card, CardContent, Grid } from "@mui/material";

import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.min.css";

import { FullCard } from "../components/Card/Card";
import AlertPage from "./alertPage";

import date_verification from "../hooks/date_verification";

import moment from "moment";
import "moment/locale/es";
import FullCardBody from "../components/Card/CardBody";
import CardActions from "../components/Card/CardActionButton";
import CardDescriptionContent from "../components/Card/CardActionDescription";
import useValidarEntorno from "../hooks/useValidarEntorno";
import Loading from "../components/Loading/Loading";
moment.locale("es");

const Validar = () => {
  const [value, setValue] = useState(false);
  const { validacion, status, menssageStatus } = useValidarEntorno();
  const [date, setDate] = useState(null);

  const mensajes = {
    activa: ` Opción para inactivar la instancia de pruebas ${localStorage.getItem(
      "instance"
    )}pruebas, solo los usuarios sistema podrán ingresar`,
    inactiva: `Opción para activar la instancia de pruebas ${localStorage.getItem(
      "instance"
    )}pruebas`,
  };

  const alertMessageDateError = () => {
    alertify.error("Fecha no valida");
  };

  const alertMessageConfirmations = () => {
    alertify
      .confirm(
        "¿Desea cambiar el estado de la base de datos de pruebas?",
        "Los usuarios no podrán iniciar sesión hasta que se reactive nuevamente",
        function () {
          if (value === true) {
            inactivar(date).then(() => {
              setValue(false);
              alertify.success("Inactivada Exitosamente");
            });
          } else {
            activateInstance();
          }
        },
        function () {}
      )
      .set("labels", { ok: "Aceptar", cancel: "Cancelar" })
      .set({ transitionOff: true });

    /**
     * activa la instancia de la base de datos
     */
    function activateInstance() {
      if (date !== null) {
        if (date_verification(date) === true) {
          const dateFinal = moment(new Date(date["$d"])).format("YYYY-MM-DD");

          activar(dateFinal)
            .then(() => {
              setValue(true);

              let fecha = moment(new Date(date["$d"])).format("MMM Do");
              setDate(null);

              alertify.success(
                "Activada Exitosamente <br>Se ha programado para activarse el: " +
                  fecha
              );
            })
            .catch((error) => {
              console.error("función enRechazo invocada: ", error);
            });
        } else {
          alertMessageDateError();
        }
      } else {
        activar(null)
          .then(() => {
            setValue(true);
            alertify.success("Activada Exitosamente");
          })
          .catch((error) => {
            console.error("función enRechazo invocada: ", error);
          });
      }
    }
  };

  const chooseDate = (day) => {
    setDate(day);
  };

  useEffect(() => {
    if (validacion === true && status) {
      if (menssageStatus === "activa") {
        setValue(true);
      } else {
        setValue(false);
      }
    }
  }, [menssageStatus, status, validacion]);

  if (validacion === null || status === null) {
    return <Loading />;
  } else {
    return (
      <Grid container spacing={0}>
        {validacion ? (
          <div style={{ marginRight: 5, marginLeft: 5 }}>
            {status ? (
              <>
                <FullCard>
                  <FullCard.Header
                    title="Estado Base De Datos"
                    instance={localStorage.getItem("instance") + "pruebas"}
                  ></FullCard.Header>
                  <FullCard.Estado estado={value}></FullCard.Estado>
                </FullCard>

                <Card sx={{ marginTop: 1, border: 0.5 }}>
                  <CardContent style={{ marginRight: 10, marginLeft: 10 }}>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginTop: 2, marginBottom: 2 }}
                    >
                      <CardActions>
                        <CardActions.Button
                          estado={value}
                          metodo={alertMessageConfirmations}
                        ></CardActions.Button>
                      </CardActions>

                      <CardDescriptionContent>
                        <CardDescriptionContent.Description
                          mensajes={mensajes}
                          value={value}
                        />
                      </CardDescriptionContent>
                    </Grid>

                    {value ? (
                      <></>
                    ) : (
                      <FullCardBody>
                        <FullCardBody.Title
                          title="Fecha vigencia"
                          tooltip="Fecha hasta la cuál debe estar activa la instancia de pruebas."
                        ></FullCardBody.Title>
                        <br></br>

                        <Grid container spacing={1}>
                          <Grid item xs={5}>
                            <FullCardBody.Calendar
                              getDatePicker={chooseDate}
                            ></FullCardBody.Calendar>
                          </Grid>
                          <Grid item xs={7}>
                            <Alert severity="info">
                              Fecha hasta la cuál debe estar activa la instancia
                              de pruebas.
                            </Alert>
                          </Grid>
                        </Grid>
                      </FullCardBody>
                    )}
                  </CardContent>
                </Card>
              </>
            ) : (
              <AlertPage></AlertPage>
            )}
          </div>
        ) : (
          <AlertPage></AlertPage>
        )}
      </Grid>
    );
  }
};

export default Validar;
