
export function sortMonths(data, colIndex, order) {
    const monthOrder = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    return data.sort((a, b) => {
        const monthA = monthOrder.indexOf(a.data[colIndex]);
        const monthB = monthOrder.indexOf(b.data[colIndex]);
        if (order === "asc") {
            return monthA - monthB;
        } else {
            return monthB - monthA;
        }
    })
}

export function sortFloat(data, colIndex, order) {
    return data.sort((a, b) => {

        const bAux = parseFloat(b.data[colIndex].split(" ")[0])
        const aAux = parseFloat(a.data[colIndex].split(" ")[0])

        if (order === "desc") {
            return bAux - aAux
        } else {
            return aAux - bAux
        }
    })
}

export function sortInteger(data, colIndex, order) {
    return data.sort((a, b) => {
        if (order === "desc") {
            return b.data[colIndex] - a.data[colIndex]
        } else {
            return a.data[colIndex] - b.data[colIndex]
        }
    })
}
