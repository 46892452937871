import React, { useState } from 'react'
import moment from 'moment'
import 'dayjs/locale/es'

import { TextField } from '@mui/material';
import { LocalizationProvider, esES, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DatePickerComp = ({ getDatePicker }) => {
    const [value, setValue] = useState(new Date(""))
    const [open, setOpen] = useState(false)

    const current = moment(new Date()).add(1, "d")
    const maxDate = moment(new Date()).add(30, "d")

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es"
            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <DatePicker
                minDate={current}
                maxDate={maxDate}

                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}

                inputFormat="YYYY/MM/DD"
                label="Selecciona una fecha"
                value={value}
                onChange={(newValue) => {
                    setValue(newValue)
                    getDatePicker(newValue)
                }}
                renderInput={(params) => <TextField {...params} onClick={(_e) => setOpen(true)} sx={{ gridColumn: 12 }} />}
            />
        </LocalizationProvider>
    )
}

export default DatePickerComp
