import { Tooltip } from "@mui/material"
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

const LabelForm = (props) => {

    const { name, required } = props

    return (
        <label style={{ alignItems: "center", margin: "2px" }}>
            {name}
            {
                required && (
                    <Tooltip
                        title="Campo obligatorio"
                        placement="top"
                    >
                        <ErrorOutlineRoundedIcon style={{
                            color: 'red',
                            fontSize: '1.2em',
                            margin: "2px"
                        }} />
                    </Tooltip>
                )
            }
        </label>
    )
}

export default LabelForm
