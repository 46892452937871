import { nameCustom } from "../../../components/Chart/Format/format"
import getMetric from "../../../services/Tramites/metrics/metrics"

const MONTHS = {
    "1": "Ene",
    "2": "Feb",
    "3": "Mar",
    "4": "Abr",
    "5": "May",
    "6": "Jun",
    "7": "Jul",
    "8": "Ago",
    "9": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dic"
}

const TRANSLATIONS = {
    "item": "Item",
    "count": "Cantidad",
    "avg_days": "Promedio (días)",
    "percent_compliance": "Porcentaje cumplimiento",
    "sum": "Suma"
}

const generateChartDataLine = (seriesData) => {
    const allCategories = seriesData.reduce((scales, collection) => {
        return scales.concat(collection.data.map(item => item.name))
    }, [])

    const categories = [...new Set(allCategories)]

    const series = seriesData.map(item => {
        const data = categories.map(category => {
            return item.data.find(dato => dato.name === category)
        })

        return {
            name: item.name,
            data: data
        }
    })

    return {
        categories: categories,
        data: series
    }
}

const useMetrics = () => {

    function getYearsOpt(array) {
        const transformedArray = []

        array.forEach(item => {
            const transformedItem = {
                name: item,
                value: item
            }

            transformedArray.push(transformedItem)
        })

        return transformedArray
    }

    const transformDataForLineChart = (graph) => {
        const transformedData = []

        if (graph) {
            Object.keys(graph)?.forEach(year => {
                const data = []
                const months = graph[year]

                Object.keys(months).forEach(month => {
                    const { resultado, cantidad, total_cumplen, total_incumplen } = months[month];
                    const formattedData = {
                        name: month,
                        y: resultado,
                        cantidad: cantidad,
                        total_cumplen: total_cumplen,
                        total_incumplen: total_incumplen
                    }

                    data.push(formattedData)
                })

                transformedData.push({
                    name: year,
                    data: data
                })
            })
        }

        const dataFinal = transformedData.map(item => {
            const transformedMonths = item.data.map(month => {
                const monthName = MONTHS[month.name]
                return { ...month, name: monthName }
            })
            return { ...item, data: transformedMonths }
        })

        return dataFinal ?? []
    }

    const transformedDataForHeatMap = (data) => {
        if (!data) {
            return null;
        }

        const years = Object.keys(data)
        const yearValues = getYearsOpt(years ?? [])

        const yearsHeatMap = years.map((year) => {
            const highchartsData = []
            const yAxisData = []
            const xAxisData = []

            data[year].forEach((obj) => {
                const item = obj.item

                if (!yAxisData.includes(item)) {
                    yAxisData.push(item)
                }

                Object.keys(obj).forEach((key) => {
                    if (key !== 'item' && obj[key] !== '' && !xAxisData.includes(key)) {
                        xAxisData.push(key)
                    }
                })
            })

            const sortedXAxisData = xAxisData.sort((a, b) => a - b)

            data[year].forEach((obj) => {
                const item = obj.item
                const xValue = yAxisData.indexOf(item)

                Object.entries(obj).forEach(([key, value]) => {
                    if (key !== 'item' && value !== '') {
                        const column = xValue
                        const row = sortedXAxisData.indexOf(key)
                        const dataValue = parseFloat(value)

                        highchartsData.push([row, column, dataValue])
                    }
                })
            })

            return {
                name: year,
                xAxis: [...sortedXAxisData],
                yAxis: [...yAxisData],
                data: [...highchartsData]
            }
        })

        const modifiedData = yearsHeatMap.map(obj => {
            const modifiedXAxis = obj.xAxis.map(x => MONTHS[x])
            return { ...obj, xAxis: modifiedXAxis }
        })

        return {
            yearValues, yearsHeatMap: modifiedData
        }
    }

    function getHeaderTable(data) {
        const attributes = []

        data.forEach(obj => {
            Object.keys(obj).forEach(attr => {
                if (!attributes.includes(attr)) {
                    attributes.push(attr)
                }
            })
        })

        return attributes
    }

    function dataBarChart(data) {

        const transformedData = []

        data.forEach(obj => {
            const transformedObj = {
                name: obj.item,
                y: obj.avg_days,
                count: obj.count,
                percent_compliance: obj.percent_compliance,
                sum: obj.sum ?? null
            }

            transformedData.push(transformedObj)
        })

        return transformedData
    }

    function formatFloatValues(array) {
        const formattedArray = array?.map(innerArray => {
            return innerArray.map(item => {
                if (typeof item === 'number' && !Number.isInteger(item)) {
                    return parseFloat(item.toFixed(2))
                }
                return item
            })
        })
        return formattedArray ?? []
    }

    const transformDataForGeneral = (data) => {

        if (data) {
            const header = getHeaderTable(data)
            const barchart = dataBarChart(data)
            const table = []

            data.forEach((obj) => {
                const row = []

                header?.forEach((attr) => {
                    row.push(obj[attr])
                })

                table.push(row)
            })

            const transformedHeader = header?.map((item, _index) => {
                if (item === "item") {
                    return {
                        name: "Item",
                        options: {
                            customBodyRender: nameCustom
                        }
                    }
                } else if (item === "percent_compliance") {
                    return {
                        name: "Porcentaje cumplimiento",
                        options: {
                            customBodyRender: (value) => value ? `${value}%` : null
                        }
                    }
                }
                else {
                    return TRANSLATIONS[item] || item
                }
            })
            const tableData = formatFloatValues(table)

            return {
                header: transformedHeader,
                body: tableData,
                chart: barchart
            }
        }

        return null
    }

    function formatNumberToTwoDecimals(number) {
        if (typeof number !== 'number') {
            throw new Error('El argumento debe ser un número.')
        }

        return parseFloat(number.toFixed(2))
    }

    const getHeatMapData = async (metric) => {

        const response = await getMetric(metric)

        if (response?.status) {
            const data = response.data?.temperature
            const graph = response.data?.graph
            const table = response.data?.general?.rows

            const footerTable = [
                "",
                formatNumberToTwoDecimals(response.data?.general?.total ?? 0),
                formatNumberToTwoDecimals(response.data?.general?.avg_days_total ?? 0),
                ""
            ]

            const lineData = transformDataForLineChart(graph)
            const transformLine = generateChartDataLine(lineData)

            const { yearValues, yearsHeatMap } = transformedDataForHeatMap(data ?? [])
            const dataTableChart = transformDataForGeneral(table)

            if (dataTableChart) {
                dataTableChart.footer = footerTable
            }

            const selectyear = yearValues?.length ? Math.max(...yearValues.map(item => parseInt(item.value))) : null

            const res = {
                years: yearValues,
                selectyear: selectyear ?? '',
                maps: yearsHeatMap,
                lineChart: transformLine,
                general: dataTableChart
            }

            const allNull = Object.values(res).every(val => val === null || val.length === 0 || val === '')
            return allNull ? null : res
        }

        return null
    }

    return { getHeatMapData }
}

export default useMetrics
