import Textarea from "./Textarea"
import Input from './Input'
import RFT from "./RFT"
import Checkbox from "./Checkbox"
import Select from "./Select"
import Visor from "./visor/Visor"
import DynamicOrder from "./dynamicOrder"
import DatePickerField from "./DatePickerField"
import Float from "./Float"
import SelectField from "./SelectSearch"

function FormikControl(props) {
    const { control, ...rest } = props

    const componentMap = {
        input: <Input {...rest} />,
        textarea: <Textarea {...rest} />,
        RTE: <RFT {...rest} />,
        checkbox: <Checkbox {...rest} />,
        select: <Select {...rest} />,
        selectsearch: <SelectField {...rest} />,
        visor: <Visor {...rest} />,
        dynamicOrder: <DynamicOrder {...rest} />,
        radio: null,
        date: <DatePickerField {...rest} />,
        float: <Float {...rest} />,
        default: null
    }

    return componentMap[control] || componentMap.default
}

export default FormikControl
