import useVisorForm from "../../../hooks/Visor/useVisorForm";
import VisorForm from "./VisorForm";

const UpdateArea = (props) => {
  const { URLIMAGE, selectedArea, reload } = props;
  const { createArea } = useVisorForm();

  const initialValues = {
    areaId: selectedArea[0],
    name: selectedArea[1],
    coordinates: selectedArea[2]?.join(","),
    shape: selectedArea[3],
    url: selectedArea[4],
    target: selectedArea[5],
  };

  const onSubmit = async (fields, { _setStatus, _setSubmitting }) => {
    await createArea(fields)
      .then((result) => {
        const resultArray = [
          result.data?.id,
          result.data?.name,
          result.data?.coordinates.split(","),
          result.data?.shape,
          result.data?.url,
          result.data?.target,
        ];

        reload(resultArray, result.data?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <VisorForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      urlImage={URLIMAGE}
    />
  );
};

export default UpdateArea;
