import { Fragment, useEffect, useState } from "react"
import { Stack, TextField } from "@mui/material"
import { useAppContext } from "./layout"
import Loading from "../../components/Loading/Loading"
import DatePickerContract from "../../components/DatePicker/DatePickerContract"
import SelectSMM from "../../components/Select/SelectSMM"
import { NumericFormat } from "react-number-format"
import { checkStartEnd, checkStates } from "./DynamicTable/hook/utils/validateFilter"
import ModalGenericFilter from "../../components/Modal/ModalGenericFilter"

function convertFloat(value) {
    if (value) {
        const floatValue = parseFloat(value.replace(/,/g, ''))
        return floatValue ?? undefined
    }

    return null
}

const FilterContract = ({ filter }) => {

    const store = useAppContext()
    const [filterValues, setFilterValues] = useState({})

    const handleInputChange = (fieldId, fieldKey, value) => {
        setFilterValues((prevFilterValues) => ({
            ...prevFilterValues,
            [fieldId]: {
                ...prevFilterValues[fieldId],
                [fieldKey]: value
            }
        }))
    }

    const handleSelectChange = (fieldId, selectedOptions) => {
        setFilterValues((prevFilterValues) => ({
            ...prevFilterValues,
            [fieldId]: selectedOptions.map((option) => option.value)
        }))
    }

    const renderDateFields = (field) => {

        return (
            <div key={field.id} style={{ marginBottom: '10px' }}>
                <div style={{ marginTop: "6px" }}>
                    {field.name}
                </div>
                <Stack direction="row" spacing={2}>
                    <DatePickerContract
                        label="Desde"
                        onChange={(date) =>
                            handleInputChange(field.id, 'start', date)
                        }
                        defaultValue={filterValues[field.id]?.start || null}
                    />

                    <DatePickerContract
                        label="Hasta"
                        onChange={(date) =>
                            handleInputChange(field.id, 'end', date)
                        }
                        defaultValue={filterValues[field.id]?.end || null}
                    />
                </Stack>
            </div>
        )
    }

    const renderFloatFields = (field) => {
        return (
            <div key={field.id} style={{ marginBottom: '15px' }}>
                <div style={{ marginTop: "6px" }}>
                    {field.name}
                </div>

                <Stack direction="row" spacing={2}>
                    <NumericFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        allowNegative={false}
                        value={filterValues[field.id]?.start || ''}
                        onChange={(e) => handleInputChange(field.id, 'start', convertFloat(e.target.value))}
                        placeholder="Valor inicial"
                        className="numberformat"
                        fullWidth
                        autoComplete="off"
                        customInput={TextField}
                    />

                    <NumericFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        allowNegative={false}
                        value={filterValues[field.id]?.end || ''}
                        onChange={(e) => handleInputChange(field.id, 'end', convertFloat(e.target.value))}
                        placeholder="Valor final"
                        className="numberformat"
                        fullWidth
                        autoComplete="off"
                        customInput={TextField}
                    />
                </Stack>
            </div>
        )
    }

    const renderSelectField = (field) => {
        const selectedOptions = field?.items?.filter((item) =>
            filterValues[field.id]?.includes(item.id)
        )

        return (
            <div key={field.id} style={{ marginBottom: '10px' }}>
                <div style={{ marginTop: "6px" }}>
                    {field.name}
                    {field.name === "Estados" && <span style={{ color: "red", fontSize: "16px" }} >*</span>}
                </div>

                <SelectSMM
                    options={field.items.map((item) => ({
                        value: item.id,
                        label: item.name
                    }))}
                    value={selectedOptions.map((option) => ({
                        value: option.id,
                        label: option.name
                    }))}
                    onChange={(selectopts) => handleSelectChange(field.id, selectopts)}
                    placeholder="Seleccione"
                />
            </div>
        )
    }

    const renderField = (field) => {
        switch (field.type) {
            case 'date':
                return renderDateFields(field)
            case 'float':
                return renderFloatFields(field)
            case 'smu':
            case 'smm':
                return renderSelectField(field)
            default:
                return null
        }
    }

    const handleFilter = (data) => {
        const promise = new Promise((resolve) => {

            const statusStartEnd = checkStartEnd(data)
            const statusStates = checkStates(data)

            if (statusStates && statusStartEnd === "true") {
                store?.saveFilter(data)
            } else {
                store?.saveFilterValues(data)
            }

            resolve()
        })

        promise.then(() => {
            filter(data)
        })
    }

    useEffect(() => {
        setFilterValues(store?.filterSelect)
    }, [])

    if (!store) {
        return <Loading />
    }

    return (
        <Fragment>
            <ModalGenericFilter
                open={store?.open}
                handleClose={() => store?.handleClose()}
                filtrar={() => handleFilter(filterValues)}
            >
                {store?.fields?.map((field) => renderField(field))}
            </ModalGenericFilter>
        </Fragment>
    )
}

export default FilterContract
