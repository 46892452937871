import colorChart from "../../../utils/chartColor"

const defaultConfig = {
    lang: {
        downloadPDF: "Descargar PDF",
        viewFullscreen: "Ver toda la pantalla",
        downloadSVG: "Descargar SVG",
        downloadPNG: "Descargar PNG",
        thousandsSep: ".",
        decimalPoint: ','
    },
    colors: colorChart,
    exporting: {
        accessibility: {
            enabled: true
        },
        enabled: true,
        buttons: {
            contextButton: {
                menuItems: ["downloadPNG", "downloadSVG"],
                enabled: true
            }
        },
        filename: "Indicador",
        sourceWidth: 1280,
        sourceHeight: 800,
        scale: 1,
        url: "https://sgi.almeraim.com/sgi/lib/php/exportar_img.php",
        urlx: "/sgi/lib/php/exportar_img.php"
    },
    navigation: {
        buttonOptions: {
            align: 'right',
            verticalAlign: 'top',
            y: 0
        }
    },
    credits: {
        enabled: false
    },
    responsive: {
        rules: [{
            condition: {
                maxWidth: 300
            },
            chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                subtitle: {
                    text: null
                },
                credits: {
                    enabled: false
                }
            }
        }]
    }
}

export default defaultConfig
