
const events = [
    {
        title: 'Evento 1',
        start: '2023-01-01T10:00:00',
        end: '2023-01-01T11:00:00'
    },
    {
        title: 'Evento 2',
        start: '2023-01-02T14:30:00',
        end: '2023-01-02T16:00:00'
    },
    {
        title: 'Evento 3',
        start: '2023-02-10T08:00:00',
        end: '2023-02-10T09:00:00'
    },
    {
        title: 'Evento 4',
        start: '2023-02-15T13:00:00',
        end: '2023-02-15T14:30:00'
    },
    {
        title: 'Evento 5',
        start: '2023-03-05T11:30:00',
        end: '2023-03-05T12:30:00'
    },
    {
        title: 'Evento 15',
        start: '2023-12-31T18:00:00',
        end: '2023-12-31T19:00:00'
    },
    {
        title: 'Evento 1',
        start: '2023-06-05T09:00:00',
        end: '2023-06-05T10:30:00'
    },
    {
        title: 'Evento 2',
        start: '2023-06-10T15:00:00',
        end: '2023-06-10T16:30:00'
    },
    {
        title: 'Evento 3',
        start: '2023-06-15T11:00:00',
        end: '2023-06-15T12:00:00'
    },
    {
        title: 'Evento 4',
        start: '2023-06-20T10:00:00',
        end: '2023-06-20T11:30:00'
    },
    {
        title: 'Evento 5',
        start: '2023-06-25T14:00:00',
        end: '2023-06-25T15:30:00'
    },
    {
        title: 'Evento 15',
        start: '2023-06-30T16:00:00',
        end: '2023-06-30T17:00:00'
    },
    {
        title: 'Evento 1',
        start: '2023-07-19T08:00:00',
        end: '2023-07-19T09:00:00'
    },
    {
        title: 'Evento 2',
        start: '2023-07-19T09:30:00',
        end: '2023-07-19T10:30:00'
    },
    {
        title: 'Evento 3',
        start: '2023-07-19T11:00:00',
        end: '2023-07-19T12:00:00'
    },
    {
        title: 'Evento 4',
        start: '2023-07-19T12:30:00',
        end: '2023-07-19T13:30:00'
    },
    {
        title: 'Evento 5',
        start: '2023-07-19T14:00:00',
        end: '2023-07-19T15:00:00'
    },
    {
        title: 'Evento 6',
        start: '2023-07-19T15:30:00',
        end: '2023-07-19T16:30:00'
    },
    {
        title: 'Evento 7',
        start: '2023-07-19T17:00:00',
        end: '2023-07-19T18:00:00'
    },
    {
        title: 'Evento 8',
        start: '2023-07-19T18:30:00',
        end: '2023-07-19T19:30:00'
    },
    {
        title: 'Evento 9',
        start: '2023-07-19T20:00:00',
        end: '2023-07-19T21:00:00'
    },
    {
        title: 'Evento 10',
        start: '2023-07-19T21:30:00',
        end: '2023-07-19T22:30:00'
    }
]

export default events
