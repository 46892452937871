import Main from "../pages/main";
import NotFound from "../components/404/404";
import Layout from "../pages/Tramites/layout";

import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import Visor from "../pages/Visor/visor";
import MessageContext from "../pages/messages/MessageContext";
import MessagesCreate from "../pages/formularios/messages/messagesCreate";
import MessagesUpdate from "../pages/formularios/messages/messagesUpdate";
import RulesContext from "../pages/Rules/RulesContext";
import QR from "../pages/QR/QR";
import Survey from "../pages/Survey/Survey";
import LayoutContracts from "../pages/Contracts/layout";
import BasicExpansibleTable from "../components/Table/Expansible/BasicExpansibleTable";

const parametrosSecurityGroup = [
  "api_adm_securitygroup",
  "application",
  "host",
  "instance",
  "ip",
  "route",
];
const parametrosProcess = ["id", "host", "api_process"];
const parametersMessage = ["api_message", "route"];
const parametersVisor = ["data"];

const routes = {
  instancia: {
    component: <Main />,
    requiresCookies: false,
  },
  adm_securitygroup: {
    component: (
      <ErrorBoundary>
        <RulesContext />
      </ErrorBoundary>
    ),
    queryParams: parametrosSecurityGroup,
    requiresCookies: true,
  },
  process: {
    component: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    queryParams: parametrosProcess,
    requiresCookies: true,
  },
  contracts: {
    component: (
      <ErrorBoundary>
        <LayoutContracts />
      </ErrorBoundary>
    ),
    queryParams: ["route"],
    requiresCookies: true,
  },
  message: {
    component: <MessageContext />,
    queryParams: parametersMessage,
    additionalRoutes: [
      { path: "/create", element: <MessagesCreate /> },
      { path: "/update/:id", element: <MessagesUpdate /> },
    ],
    requiresCookies: true,
  },
  visor: {
    component: <Visor />,
    queryParams: parametersVisor,
    requiresCookies: false,
  },
  qr_react: {
    component: <QR />,
    requiresCookies: false,
  },
  layout: {
    component: <Survey />,
    queryParams: ["route", "api_layout", "md", "id"],
    requiresCookies: true,
  },
  expansible: {
    component: <BasicExpansibleTable />,
    queryParams: [],
    requiresCookies: false,
  },
  default: {
    component: <NotFound />,
    requiresCookies: false,
  },
};

export default routes;
