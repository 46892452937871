import React from 'react';
import MUIDataTable from "mui-datatables"

import EsLabels from './EsLabels'
import { theme } from './TableTheme'
import { ThemeProvider } from '@mui/material/styles'
import { handleDownloadXLSX } from '../../utils/XLSX'

const MuiTable = ({
    title = "Generic Table",
    header,
    data,
    options,
    reference = null,
    responsive = null
}) => {

    if (options) {
        options.textLabels = EsLabels
        options.responsive = responsive || 'standard'
        options.onDownload = handleDownloadXLSX
    }

    return (
        <ThemeProvider theme={theme} >
            <MUIDataTable
                title={title}
                data={data}
                columns={header}
                options={options}
                ref={reference}
            />
        </ThemeProvider >
    )
}

export default MuiTable
