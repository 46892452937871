import React, { useState } from "react";
import "dayjs/locale/es";
import { Field } from "formik";
import { LocalizationProvider, DatePicker, esES } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./estilos.css";
import { TextField } from "@mui/material";
import { addDays } from "date-fns";
import AsteriskRequired from "./Required/RequiredField";
import moment from "moment";

function DateField(props) {
  const { label, name, min, max, required, ...rest } = props;

  const [open, setOpen] = useState(false);

  const currentDate = new Date();
  const minDate = min !== undefined ? addDays(currentDate, min) : undefined;
  const maxDate = max !== undefined ? addDays(currentDate, max) : undefined;

  const validateDate = (value) => {
    let errorMessage = "";

    if (value !== "" && value) {
      const status = moment(value, "YYYY-MM-DD", true).isValid();

      if (!status) {
        errorMessage = `El atributo ${label} debe tener un formato de fecha válido`;
      } else {
        const selectedDate = new Date(value);
        const selectedDateString = selectedDate.toISOString().slice(0, 10);

        if (
          minDate &&
          selectedDateString < minDate.toISOString().slice(0, 10)
        ) {
          errorMessage = `El atributo  ${label} debe ser posterior o igual a ${minDate
            .toISOString()
            .slice(0, 10)}`;
        } else if (
          maxDate &&
          selectedDateString > maxDate.toISOString().slice(0, 10)
        ) {
          errorMessage = `El atributo ${label} debe ser anterior o igual a ${maxDate
            .toISOString()
            .slice(0, 10)}`;
        } else {
          errorMessage = "";
        }
      }
    }

    return errorMessage;
  };

  return (
    <div className="form-control">
      <label htmlFor={name}>
        {label}
        {required && <AsteriskRequired />}
      </label>
      <Field name={name} validate={validateDate}>
        {({ field }) => (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es"
            localeText={
              esES.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              inputFormat="YYYY-MM-DD"
              value={field.value || null}
              isClearable
              onChange={(date) => {
                field.onChange({
                  target: {
                    value: date ? date.format("YYYY-MM-DD") : "",
                    name,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  {...rest}
                  onClick={() => setOpen(true)}
                />
              )}
              minDate={minDate}
              maxDate={maxDate}
            />
          </LocalizationProvider>
        )}
      </Field>
      <br />
    </div>
  );
}

export default DateField;
