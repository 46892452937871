import deleteDynamicTable from "../../../services/Contracts/dynamicTable/deleteDynamicTable"
import getDynamicTableData from "../../../services/Contracts/dynamicTable/getDynamicTableData"
import getDynamicTableConf from "../../../services/Contracts/dynamicTable/getDynamicTables"
import getFieldsDynamic from "../../../services/Contracts/dynamicTable/getFieldsDynamic"
import saveDynamicTable from "../../../services/Contracts/dynamicTable/saveDynamicTable"

const useDynamicTableContract = () => {
    const getDynamicData = async (tableId, filter) => {

        const response = await getDynamicTableData(tableId, filter)
        if (response?.status) {
            const data = response.data

            return data?.map(diccionario => {
                const diccionarioActualizado = {};
                for (const atributo in diccionario) {
                    if (diccionario.hasOwnProperty(atributo)) {
                        diccionarioActualizado[atributo] = !diccionario[atributo] ? '' : diccionario[atributo]
                    }
                }
                return diccionarioActualizado
            })
        }

        return []
    }

    function mapObjectFields(objeto) {
        const headers = getFieldValues(objeto.fields, 'headers')
        const columns = getFieldValues(objeto.fields, 'columns')
        const numerics = getFieldValues(objeto.fields, 'numerics')

        return {
            key: objeto.id,
            name: objeto.name,
            value: objeto.id,
            headers: headers,
            columns: columns,
            numerics: numerics
        }
    }

    function getFieldValues(fields, fieldKey) {
        const fieldValues = fields?.[fieldKey];
        if (fieldValues) {
            return Array.from(new Set(fieldValues.map(column => column.name)))
        }
        return []
    }

    const getDynamicConf = async () => {

        const response = await getDynamicTableConf()

        if (response?.status) {
            const data = response.data;
            return data ? data.map(mapObjectFields) : []
        }

        return []
    }

    const getFields = async () => {

        const response = await getFieldsDynamic()
        const numericFields = []
        const nonNumericFields = []
        let result = null

        if (response?.status) {
            const data = response.data?.fields

            data.forEach((item) => {
                if (item.type === "float") {
                    numericFields.push({ label: item.name, value: item.id })
                } else {
                    nonNumericFields.push({ label: item.name, value: item.id })
                }
            })

            result = {
                fields: nonNumericFields,
                numerics: numericFields
            }
        }

        return result
    }

    const saveTable = async (name, fields) => {

        const transformedArray = Object.entries(fields).reduce((result, [key, value]) => {
            const typeMapping = {
                cols: "C",
                rows: "H",
                numericas: "N"
            };
            const type = typeMapping[key] || ""
            const transformedValues = value.map(item => ({
                classId: item.value,
                type,
                order: item.order
            }));
            return result.concat(transformedValues);
        }, [])

        const response = await saveDynamicTable(name, transformedArray)
        let data = null

        if (response?.status) {
            data = response.data?.id
        }

        return data
    }

    const deleteDynamic = async (deleteId) => {
        const response = await deleteDynamicTable(deleteId)
        return response?.status
    }

    return { getDynamicData, getDynamicConf, getFields, saveTable, deleteDynamic }
}

export default useDynamicTableContract
