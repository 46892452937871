const colorChart = [
    "#509ee3",
    "#e4d354",
    "#98d9d9",
    "#7172ad",
    "#ef8c8c",
    "#88bf4d",
    "#f9d45c",
    "#91e8e1",
    "#70a4d6",
    "#f7a35c",
    "#2b908f",
    "#f45b5b",
    "#91e8e1",
    "#8085e9",
    "#2caffe",
    "#f15c80",
    "#00e272",
    "#ffc107",
    "#544fc5",
    "#f2a86f",
    "#fe6a35",
    "#6b8abc",
    "#4caf50",
    "#2ee0ca",
    "#fa4b42",
    "#feb56a"
]

export default colorChart
