import { SpeedDialAction } from '@mui/material'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import BasicTable from '../../Table/BasicTable'
import { Fragment, useEffect, useState } from 'react'
import { BarChart, PieChart, Sort } from '@mui/icons-material'
import MenuColumn from '../Menu/MenuColumn'
import { handleSort } from '../Sort/sortColumnFilter'
import { manera } from '../Menu/optionsOrder'
import { getOptions } from './confOption'
import { footer } from './footer'
import { columns, customSort } from './tableConf'
import SpeedDialChart from '../../SpeedDial/SpeedDialChart'
import { columsOptions } from './filterConf'
import ChartContainerResponsive from '../ChartContainerResponsive'

require("highcharts/modules/accessibility")(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const BarSplineChart = ({ data, total = null, title = "grafico" }) => {

    const dataTable = []

    const [chartMode, setChartMode] = useState("column")
    const [open, setOpen] = useState(false)

    const [filterOptions, setFilterOptions] = useState({
        orden: 'asc',
        column: '',
        items: null
    })

    const [chartInfo, setChartInfo] = useState({
        valores: [],
        encabezado: [],
        spline: [],
        porcentajes: []
    })

    data.forEach(element => {
        const percent = Number(element.percent.toFixed(2))
        const amount = isNaN(parseFloat(element.y)) ? 0 : parseFloat(element.y)
        dataTable.push([element.name, amount, element.process, percent + " %"])
    })

    const changeChartMode = (mode) => {
        if (mode !== chartMode) {
            setChartMode(mode)
        }
    }

    const handleToggle = () => {
        setOpen(!open)
    }

    const actions = [
        { icon: <BarChart />, name: 'Bar', onClick: () => changeChartMode("column") },
        { icon: <PieChart />, name: 'Pie', onClick: () => changeChartMode("pie") }
    ]

    useEffect(() => {

        const valores = []
        const encabezado = []
        const spline = []
        const porcentajes = []

        if (chartMode === "column") {
            handleSort(data, filterOptions).forEach(element => {
                valores.push(parseFloat(element.y))
                encabezado.push(element.name)
                spline.push(parseInt(element.process))
                porcentajes.push(parseFloat(element.percent))
            })
        } else {
            if (chartMode === "pie") {
                data.forEach(element => {
                    valores.push(parseFloat(element.y))
                    encabezado.push(element.name)
                    spline.push(parseInt(element.process))
                    porcentajes.push(parseFloat(element.percent))
                })
            }
        }

        setChartInfo({
            ...chartInfo,
            valores: valores,
            encabezado: encabezado,
            spline: spline,
            porcentajes: porcentajes
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOptions, chartMode])

    return (
        <Fragment>
            <MenuColumn
                open={open}
                handleToggle={handleToggle}
                manera={manera}
                columsOptions={columsOptions}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
            />

            <ChartContainerResponsive>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={getOptions(chartInfo.valores, chartInfo.spline, chartInfo.porcentajes, chartInfo.encabezado, title, chartMode)}
                />

                <div style={{
                    position: "absolute",
                    left: "100%"
                }}>

                    <SpeedDialChart>
                        {chartMode === "column" && (
                            <SpeedDialAction
                                key="order_by"
                                icon={<Sort />}
                                tooltipTitle={"Ordenar"}
                                onClick={handleToggle}
                            />
                        )}

                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.onClick}
                            />
                        ))}
                    </SpeedDialChart>
                </div>
            </ChartContainerResponsive>

            <br />
            <ChartContainerResponsive >
                <BasicTable
                    columns={columns}
                    data={dataTable}
                    Footer={footer}
                    customSort={customSort}
                    total={total}
                    nameTable={title}
                />
            </ChartContainerResponsive>
        </Fragment>
    )
}

export default BarSplineChart
