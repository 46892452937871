import styled from 'styled-components'

const QRContainer = styled.div`
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  padding: 2em 2em 2em 2em;
  margin-top: 20px;
  background-color: ${(props) => props.backgroundColor || 'white'};
  border: 2px solid transparent;
  border-radius: 24px;
  @media (max-width: 325px) {
    overflow-x: auto;
    overflow-y: auto;
    width: calc(100% + ((300px - 100vw) * 1));
  };
`;

export default QRContainer
