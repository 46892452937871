export const promedioFilter = [{
    label: "Estado",
    value: "name"
},
{
    label: "Dìas",
    value: "y"
}]

export const procesosFilter = [{
    label: "Estado",
    value: "name"
},
{
    label: "Total",
    value: "y"
},
{
    label: "Porcentaje",
    value: "percent"
}]
