import React from "react";
import nameApplications from "./variables/application";

import IpsRuleMasivasForm from "../formularios/ipsRuleMasiva";
import IpsForm from "../formularios/ipsRules";
import { TableConfig } from "./actions";
import ModalForm from "../../components/Modal/modal";
import Loading from "../../components/Loading/Loading";

import Redirect from "../../hooks/redirect";
import NotFound from "../../components/404/404";
import header from "./header";
import { ruleButtons } from "./buttonsTable";
import MuiTable from "../../components/Table/MuiTable";
import useIpsTable from "./hooks/useIpsTable";
import ChartContainerResponsive from "../../components/Chart/ChartContainerResponsive";
import DoneOutlineRoundedIcon from "@mui/icons-material/DoneOutlineRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { Chip } from "@mui/material";

const ErrorAnswer = ({ error }) => {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <NotFound />
      <h2>{error}.</h2>
    </div>
  );
};

const EnableIp = ({ ip, status }) => {
  return (
    <div>
      <span style={{ marginRight: "5px", color: "#4B5565" }}>Mi IP: {ip}</span>
      {status ? (
        <Chip
          label="Habilitado"
          icon={<DoneOutlineRoundedIcon />}
          color="success"
        />
      ) : (
        <Chip
          label="Inhabilitado"
          icon={<HighlightOffRoundedIcon />}
          color="error"
        />
      )}
    </div>
  );
};

const IpsValidas = () => {
  const {
    title,
    data,
    datos,
    loading,
    open,
    modalOption,
    searchValue,
    error,
    statusTable,
    handleOpen,
    handleClose,
    handleLoading,
    resetData,
    eliminar,
    editar,
    handleSearch,
    handleChangeTable,
    changeState,
    isEnableIp,
  } = useIpsTable();

  const headerButtons = ruleButtons(handleOpen, title === "airbyte");
  const atributos = header;
  let encabezadoTabla = TableConfig(atributos, statusTable, editar, eliminar);
  const iplocal = localStorage.getItem("ip") ?? "";

  const options = {
    expandableRowsOnClick: true,
    responsive: "vertical",
    searchPlaceholder: "Buscar",
    filterType: "dropdown",
    download: false,
    filter: true,
    print: false,
    selectableRows: "none",
    searchText: searchValue,
    pagination: data?.length > 0,
    onSearchChange: handleSearch,
    onTableChange: handleChangeTable,
    customToolbar: headerButtons,
  };

  const modalOptions = {
    updateip: (
      <IpsForm
        handleClose={handleClose}
        onChange={changeState}
        parametros={datos}
        onLoading={handleLoading}
        setData={resetData}
      />
    ),
    ip: (
      <IpsForm
        handleClose={handleClose}
        onChange={changeState}
        onLoading={handleLoading}
        setData={resetData}
      />
    ),
    ipmasivas: (
      <IpsRuleMasivasForm
        handleClose={handleClose}
        onChange={changeState}
        onLoading={handleLoading}
        resetData={resetData}
      />
    ),
    default: <NotFound />,
  };

  if (error) {
    return <ErrorAnswer error={error} />;
  }

  if (loading === true || data === null) {
    return <Loading />;
  }

  return (
    <ChartContainerResponsive>
      <h1>{nameApplications[title] || title}</h1>

      <ModalForm
        open={open}
        handleClose={handleClose}
        title={"Editar regla"}
        subtitle={
          datos?.usuario && (
            <div>
              <b>Creado por: </b>
              {datos.usuario} - {datos.created}
            </div>
          )
        }
      >
        {modalOptions[modalOption] || modalOptions["default"]}
      </ModalForm>

      <MuiTable
        title={
          <div>
            <h5>Direcciones autorizadas</h5>
            <EnableIp ip={iplocal} status={isEnableIp()} />
          </div>
        }
        header={encabezadoTabla}
        data={data === null ? [] : data}
        options={options}
        responsive="vertical"
      />
      <Redirect />
    </ChartContainerResponsive>
  );
};

export default IpsValidas;
