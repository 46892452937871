export const sizeOptions = [
    { value: 400, label: 'Pequeño' },
    { value: 500, label: 'Mediano' },
    { value: 600, label: 'Grande' },
    { value: 700, label: 'Extragrande' }
]

export const precisionOptions = [
    { value: 'L', label: 'Baja' },
    { value: 'M', label: 'Media' },
    { value: 'Q', label: 'Cuartil' },
    { value: 'H', label: 'Alta' }
]

export const buttons = [
    'fullsize', '|',
    'bold', 'italic', 'underline', 'strikethrough', '|',
    'ul', 'ol',
    'hr'
]

export const removeButtons = [
    'about',
    'find',
    'link',
    'image',
    'undo',
    'redo'
]
