import Cookies from "js-cookie";

const deleteDynamicTable = async (idTable) => {
  const route = "contracts";
  const endpoint = localStorage.getItem("api_" + route);
  const url = `${endpoint}admDynamicTable/${idTable}`;
  const instance = localStorage.getItem("instance");

  try {
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tkreact_" + instance),
      },
    };

    const response = await fetch(url, config);
    return await response.json();
  } catch (error) {
    return null;
  }
};

export default deleteDynamicTable;
