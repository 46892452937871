import React from "react";
import ModalDialog from "@mui/joy/ModalDialog";
import { Modal, Stack } from "@mui/joy";
import { Typography } from "@mui/material";

const ModalForm = ({
  children,
  open,
  handleClose,
  title = "Editar regla",
  subtitle = null,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ alignItems: "center", justifyContent: "center" }}
      sx={{ zIndex: 1200 }}
    >
      <ModalDialog
        aria-labelledby="basic-modal-dialog-title"
        aria-describedby="basic-modal-dialog-description"
        sx={{ maxWidth: 500, minWidth: 400 }}
        style={{ backgroundColor: "white", borderColor: "#CDCDCD" }}
      >
        <Typography id="basic-modal-dialog-title" variant="h5">
          {title}
        </Typography>

        {subtitle && <div style={{ fontSize: "13px" }}> {subtitle} </div>}

        <br />

        <Stack spacing={2}>{children}</Stack>
      </ModalDialog>
    </Modal>
  );
};

export default ModalForm;
