export const BUTTONCONTAINERSTYLE = {
    container: {
        backgroundColor: 'white',
        fontSize: '12px',
        color: 'black',
        height: "auto",
        margin: "0 auto",
        maxWidth: "90%",
        width: "100%"
    },
    body: { margin: '0' },
    containerButtons: {
        marginTop: '2em',
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: "100%",
        overflow: "hidden"
    }
}

export const JSONEDITORSTYLE = {
    body: {
        fontSize: '16px',
        backgroundColor: 'white',
        border: "2px solid black"
    }
}

export const QRSTYLE = {
    container: {
        height: "auto",
        maxWidth: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    box: { padding: "5px", backgroundColor: "white", width: "30em" },
    qr: { height: "auto", maxWidth: "100%", width: "100%" }
}

export const CONTAINERSTEP = {
    container: { marginTop: '1em', padding: '10px' }
}
