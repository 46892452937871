import moment from 'moment'

export const formatDate = (date) => {
    const format = "yyyy-MM-dd"
    let dateObject, value = null

    if (date) {
        dateObject = new Date(date)
        value = date ? moment(dateObject).format(format) : null
    }

    return value ?? null
}

export function isValidDateString(dateString) {
    const date = new Date(dateString)
    return date instanceof Date && !isNaN(date)
}
