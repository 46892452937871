import decipher from "./Utils/JWT"

const customAreas = async () => {

    const datadecode = localStorage.getItem('data')
    const { id, token, endpoint, jwt, type } = decipher(datadecode)

    const url = `${endpoint}/areas`

    const info = {
        id: id,
        token: token,
        type: type
    }

    try {
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + jwt,
            },
            body: JSON.stringify(info)
        }

        const response = await fetch(url, config)
        const json = await response.json()

        return json

    } catch (error) {
        console.log(error)
    }
}

export default customAreas