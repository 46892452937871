import { convertMoneyFormat, moneyFormat, nameCustom, percentValue } from "../../components/Chart/Format/format"
import getTabulateField from "../../services/Contracts/fields/getTabulateField"

const useTabulateField = () => {

    function formatNumber(number) {
        const numberDecimals = +number?.toFixed(2)
        return numberDecimals ?? 0
    }

    const formatDataTable = (data) => {

        const formattedData = {
            header: [
                {
                    name: "Item",
                    options: {
                        customBodyRender: nameCustom
                    }
                },
                {
                    name: "Valor",
                    options: {
                        customBodyRender: moneyFormat
                    }
                },
                {
                    name: "% Valor",
                    options: {
                        customBodyRender: percentValue
                    }
                },
                "Cantidad",
                {
                    name: "%",
                    options: {
                        customBodyRender: percentValue
                    }
                }
            ],
            body: data.map((item) => [
                item.item,
                Number(item.value),
                Number(formatNumber(item.value_percent)),
                Number(item.amount),
                Number(formatNumber(item.percent))]
            )
        }

        return formattedData ?? null
    }

    const convertToChartData = (data) => {
        const chartData = data.map((item) => {
            return {
                name: item.item,
                y: Number(item.amount),
                value_percent: Number(item.value_percent),
                value: Number(item.value),
                percent: Number(item.percent)
            }
        })

        return chartData ?? []
    }

    const getTabulate = async (field_id, filter = null) => {
        const response = await getTabulateField(field_id, filter)
        const result = {
            chart: [],
            table: {}
        }

        if (response?.status) {
            const states = response.data?.items
            const total_amount = response.data?.total_amount
            const total_value = response.data?.total_value
            const total_value_percent = formatNumber(response.data?.total_value_percent)

            if (states) {
                result.chart = convertToChartData(states)
                result.table = formatDataTable(states)
                const totallabel = convertMoneyFormat(total_value)
                result.table.footer = ["", "$" + totallabel, total_value_percent + "%", total_amount, "100%"]
            }
        }

        return result
    }

    return {
        getTabulate
    }
}

export default useTabulateField
