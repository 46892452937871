import { DragIndicator } from "@mui/icons-material"
import FieldComponent from "./FieldComponent"
import { Stack } from "@mui/material"
import styled from "styled-components"

function boxQuestionStyle(snapshot, given) {
    return {
        userSelect: 'none',
        padding: '8px',
        margin: '5px',
        width: snapshot.isDragging ? '25%' : '100%',
        backgroundColor: snapshot.isDragging
            ? '#B39DDB'
            : '#ffffff',
        color: snapshot.isDragging ? 'white' : 'black',
        border: snapshot.isDragging ? '1px solid #5E35B1' : '1px solid #90CAF9',
        borderRadius: '8px',
        flex: 1,
        flexShrink: 0,
        flexGrow: snapshot.isDragging ? 0 : 1,
        ...given.draggableProps.style
    }
}

const DragIndicatorIcon = styled(DragIndicator)`
  color: #1565C0;
`;

const QuestionItem = ({ question, provided, snapshot, isMissingColumn }) => {
    const { name, type, items, classType, likert } = question

    return (
        <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={boxQuestionStyle(snapshot, provided)}
        >
            <Stack direction="row" alignItems="center">
                <div>
                    <DragIndicatorIcon />
                </div>
                <div style={{ overflow: "hidden", fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: name }} />
            </Stack>

            <FieldComponent
                category={type}
                options={items}
                likert={likert}
                classType={classType}
                isMissingColumn={isMissingColumn}
            />
        </div>
    )
}

export default QuestionItem
