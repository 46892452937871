import * as XLSX from 'xlsx'

export const handleDownloadXLSX = (_buildHead, _buildBody, columns, data) => {
    const filteredColumns = columns?.map((column, index) => !column.download && index).filter(Boolean)

    const header = columns
        .filter((_column, index) => !filteredColumns?.includes(index))
        .map((column) => column.name)

    const nameFilter = header[0] ?? "file"

    const body = data?.map((row) =>
        row.data.filter((_, index) => !filteredColumns?.includes(index))
    )

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.aoa_to_sheet([header, ...body])
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
    XLSX.writeFile(workbook, nameFilter + '.xlsx')
    return false
}
