import { useState } from "react"
import useTramites from "../../../hooks/Tramites/tramites"
import { useAppContext } from "../layout"
import { getFilterValues } from "../utils/state"

const useTramitesLogic = () => {

    const [dataChart, setDataChart] = useState(null)
    const [dataPieChart, setDataPieChart] = useState(null)
    const [entorno, setEntorno] = useState(false)
    const [loading, setLoading] = useState(false)

    const { getTramitesInfo } = useTramites()
    const store = useAppContext()

    const filtrar = (async () => {
        if (store.selected) {
            setLoading(true)

            const result = getFilterValues(store)

            if (result) {
                if (store.open) {
                    store.handleClose()
                }

                const response = await getTramitesInfo(result)

                if (response) {
                    setDataChart(response.state_day)
                    setDataPieChart(response.state_total)
                }
            }

            setLoading(false)
        }
    })

    if (store.filtro && store.selected && entorno === false) {
        filtrar()
        setEntorno(true)
    }

    return {
        dataChart,
        dataPieChart,
        loading,
        filtrar
    }
}

export default useTramitesLogic
